import styled from "styled-components";

const StyledWithN8Name = styled.div`
  display: flex;
  div:nth-child(2) {
    color: #f26522;
    margin-left: 5px;
  }
`;

export default StyledWithN8Name;
