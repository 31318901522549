import React, { FC, useEffect, useState } from "react";
import { Button, Col, Divider, Row, Tabs, Tooltip, Upload } from "antd";
import {
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  FileAddOutlined,
  FilePdfOutlined,
  FolderOpenOutlined,
  PlusOutlined,
  RedoOutlined,
  SolutionOutlined,
  TeamOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useOktaAuth } from "@okta/okta-react";
import Modal from "antd/lib/modal/Modal";
import TeamTab from "../TeamTab/TeamTab";
import StyledCanvas from "./StyledCanvas";
import { useAppContext } from "../../Context/context";
import TeamProps from "../../types/team-props";
import {
  cloneStateAction,
  selectTeam,
  deleteTeam,
  loadAppStateAction,
  undoAction,
  redoAction,
  duplicateTeamAction,
  setUserInfoAction,
  saveAction,
} from "../../Context/actions";
import { CanvasProps } from "../../types/canvas-props";
import CurrentState from "../CurrentState/CurrentState";
import FutureState from "../FutureState/FutureState";
import MetricsState from "../MetricsState/MetricsState";
import CreateSessionModal from "../CreateSessionModal/CreateSessionModal";
import WithN8Name from "../CommonStateTitle/WithN8Name";
import CurrentStateName from "../CommonStateTitle/CurrrentStateName";
import MetricsName from "../CommonStateTitle/MetricsName";
import { useBeforeunload } from "../../hooks/useUnload";
import DraggableTabs from "../DraggableTabs/DraggableTabs";

const { TabPane } = Tabs;

const Canvas: FC<CanvasProps> = ({
  toggleCreateTeamVisibility,
  toggleEditTeamResourcesVisibility,
  fullContent,
  setClientImage,
  toggleClientModalVisibility,
  isClientModalClosed,
  isUpdatingClient,
}) => {
  const {
    state: {
      teams,
      selectedTeam,
      roles,
      colors,
      history,
      future,
      client,
      resources,
      teamScenario,
      teamScenarioResource,
      userInfo,
      isSaved,
    },
    dispatch,
  } = useAppContext();

  useBeforeunload((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
  }, isSaved);

  const { authState, oktaAuth } = useOktaAuth();
  const [teamToDelete, setTeamToDelete] = useState<string>();
  const [isTeamConfirmationOpen, setIsTeamConfirmationOpen] =
    useState<boolean>(false);
  const [teamScenarioCount, setTeamScenarioCount] = useState<number>(0);

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      oktaAuth
        .getUser()
        .then((info: { name: string; email: string; phone_number: string }) => {
          dispatch(
            setUserInfoAction(info.name, info.email, info.phone_number || "")
          );
        });
    }
  }, [authState, dispatch, oktaAuth]);

  useEffect(() => {
    if (client) {
      document.title = `#8 CPSP - ${client.name}`;
      setClientImage(client.image);
    }
  }, [client, setClientImage]);

  useEffect(() => {
    const teamScenarios = teamScenario.filter(
      (scenario) => scenario.teamId === selectedTeam
    );
    setTeamScenarioCount(teamScenarios.length);
  }, [teamScenario, selectedTeam, teamScenarioCount]);

  const selectTab = (tabId: string) => {
    dispatch(selectTeam(tabId));
  };

  const getFutureState = () => {
    return (
      <TabPane
        key="2"
        closable={false}
        tab={
          <div data-cy="teamTab2">
            <WithN8Name />
          </div>
        }
      >
        {selectedTeam === "2" && <FutureState />}
      </TabPane>
    );
  };

  const getMetrics = () => {
    return (
      <TabPane
        key="3"
        closable={false}
        tab={
          <div data-cy="teamTab">
            <MetricsName />
          </div>
        }
      >
        {selectedTeam === "3" && <MetricsState />}
      </TabPane>
    );
  };

  const getTextOverflow = (text: string) => {
    if (text.length < 10) return text;
    return `${text.slice(0, 9)}...`;
  };

  const cloneTeamState = (stateId: string, name: string) => {
    dispatch(cloneStateAction(selectedTeam, stateId, name));
  };

  const confirmDeleteTeam = () => {
    if (teamToDelete) {
      setIsTeamConfirmationOpen(false);
      dispatch(deleteTeam(teamToDelete));
    }
  };

  const printSession = async () => {
    const response = await fetch("/api/print-session", {
      method: "POST",
      body: JSON.stringify({
        teams,
        roles,
        colors,
        client,
        userInfo,
        resources,
        teamScenario,
        teamScenarioResource,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const blob = await response.blob();
    const file = new Blob([blob], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", "session.pdf");
    document.body.appendChild(link);
    link.click();
    if (!link.parentNode) return;
    link?.parentNode.removeChild(link);
  };

  const downloadState = () => {
    const blob = new Blob(
      [
        JSON.stringify({
          teams,
          selectedTeam,
          roles,
          colors,
          client,
          resources,
          teamScenario,
          teamScenarioResource,
        }),
      ],
      { type: "application/json" }
    );
    const todaysDate = dayjs().format("YYYY-MM-DD");
    const fileName = `${todaysDate}-CPSP-Session.cpsp.json`;
    if (navigator.userAgent.match("CriOS")) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader && typeof reader.result === "string") {
          window.location.href = reader.result;
        }
      };
      reader.readAsDataURL(blob);
      return;
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    if (!link.parentNode) return;
    link?.parentNode.removeChild(link);
    dispatch(saveAction());
  };

  const uploadState = async (file: Blob) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (
        e &&
        e.target &&
        e.target.result &&
        typeof e.target.result === "string"
      ) {
        const data = JSON.parse(e.target.result);
        dispatch(loadAppStateAction(data));
      }
    };
    fileReader.readAsText(file);
    return false;
  };

  const onEditTeams = (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: "add" | "remove"
  ) => {
    if (action === "remove") {
      setTeamToDelete(targetKey.toString());
      setIsTeamConfirmationOpen(true);
    }
  };

  const OperationsSlot = {
    left: (
      <Button
        onClick={toggleCreateTeamVisibility}
        className="canvasAddTeamBtn"
        data-cy="createTeamBtn"
        size="large"
        title="Create Team"
        icon={<PlusOutlined />}
      />
    ),
  };

  const handleUndo = () => {
    dispatch(undoAction());
  };

  const handleRedo = () => {
    dispatch(redoAction());
  };

  const duplicateTeam = (teamId: string) => {
    dispatch(duplicateTeamAction(teamId));
  };

  const createSession = () => {
    if (!client) {
      toggleClientModalVisibility();
    } else if (
      isSaved ||
      (isSaved && isUpdatingClient) ||
      (!isSaved &&
        // eslint-disable-next-line no-alert
        window.confirm(
          "You will lose all your unsaved changes. Click OK if you want to continue"
        ))
    ) {
      toggleClientModalVisibility();
    }
  };

  return (
    <StyledCanvas isFullContent={fullContent}>
      <div className="actions">
        <Tooltip title="New Session">
          <Button
            onClick={createSession}
            icon={<FileAddOutlined />}
            data-cy="createSessionBtn"
          />
        </Tooltip>
        <Upload
          maxCount={1}
          beforeUpload={uploadState}
          showUploadList={false}
          data-cy="uploadSessionBtn"
        >
          <Tooltip title="Open Session">
            <Button icon={<FolderOpenOutlined />} />
          </Tooltip>
        </Upload>
        <Tooltip title="Save Session">
          <Button
            onClick={downloadState}
            icon={<DownloadOutlined />}
            data-cy="saveSessionBtn"
          />
        </Tooltip>
        <Tooltip title="Undo">
          <Button
            onClick={handleUndo}
            disabled={!history || history.length === 1}
            icon={<UndoOutlined />}
            data-cy="undoActionBtn"
          />
        </Tooltip>
        <Tooltip title="Redo">
          <Button
            onClick={handleRedo}
            disabled={!future || future.length === 0}
            icon={<RedoOutlined />}
            data-cy="redoActionBtn"
          />
        </Tooltip>
        <Divider type="vertical" />
        <Tooltip title="Print Session">
          <Button
            onClick={printSession}
            icon={<FilePdfOutlined />}
            data-cy="printSessionBtn"
          />
        </Tooltip>
      </div>
      <DraggableTabs
        defaultActiveKey="1"
        onChange={selectTab}
        activeKey={String(selectedTeam)}
        data-cy="teamTabs"
        type="editable-card"
        hideAdd
        onEdit={onEditTeams}
        tabBarExtraContent={OperationsSlot}
        className="tabs"
      >
        <TabPane
          key="currentState"
          tab={
            <div
              data-cy={`teamTab${teams[0].id}`}
              className="currentStateNameTab"
            >
              <CurrentStateName /> Scenario
            </div>
          }
          closable={false}
        >
          <CurrentState />
        </TabPane>
        {teams.map((team: TeamProps, index: number) => (
          <TabPane
            closable={teams.length > 1}
            key={team.id}
            closeIcon={
              <CloseOutlined
                title="Delete team"
                data-cy={`close${team.name}Btn`}
              />
            }
            tab={
              <Row justify="space-between" wrap={false} gutter={2}>
                <Col flex={1}>
                  <TeamOutlined />
                </Col>
                <Col flex={5}>
                  <Tooltip title={team.name}>
                    {getTextOverflow(team.name)}
                  </Tooltip>
                </Col>
                <Col span={1} />
                <Col flex={2}>
                  <EditOutlined
                    onClick={
                      teamScenarioCount <= 1
                        ? toggleEditTeamResourcesVisibility
                        : undefined
                    }
                    title="Edit Team"
                    data-cy={`teamTab${index + 1}EditBtn`}
                    className="anticon-hover"
                  />
                </Col>
                <Col flex={2}>
                  <SolutionOutlined
                    onClick={() => duplicateTeam(team.id)}
                    title="Duplicate Team"
                    data-cy={`duplicateTeam${index + 1}Btn`}
                    className="anticon-hover"
                  />
                </Col>
              </Row>
            }
          >
            {selectedTeam === team.id && (
              <TeamTab
                team={team}
                cloneTeamState={cloneTeamState}
                scenarios={teamScenario}
                resources={resources}
                scenarioResources={teamScenarioResource}
              />
            )}
          </TabPane>
        ))}
        {getFutureState()}
        {getMetrics()}
      </DraggableTabs>
      {isTeamConfirmationOpen && (
        <Modal
          visible
          onCancel={() => setIsTeamConfirmationOpen(false)}
          footer={[
            <Button key="back" onClick={() => setIsTeamConfirmationOpen(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={confirmDeleteTeam}
              data-cy="deleteTeamSubmitBtn"
            >
              Delete
            </Button>,
          ]}
        >
          By closing this tab, this team will be deleted. Are you sure you want
          to proceed?
        </Modal>
      )}
      {!isClientModalClosed && (
        <CreateSessionModal
          isUpdatingClient={isUpdatingClient}
          onSubmit={toggleClientModalVisibility}
        />
      )}
    </StyledCanvas>
  );
};

Canvas.propTypes = {
  toggleCreateTeamVisibility: PropTypes.func.isRequired,
  toggleEditTeamResourcesVisibility: PropTypes.func.isRequired,
  fullContent: PropTypes.bool.isRequired,
  setClientImage: PropTypes.func.isRequired,
  toggleClientModalVisibility: PropTypes.func.isRequired,
  isClientModalClosed: PropTypes.bool.isRequired,
};

export default Canvas;
