import React, { FC, useEffect, useState } from "react";
import { Col, Row, Tooltip, Menu, Typography } from "antd";
import {
  QuestionCircleOutlined,
  SendOutlined,
  SisternodeOutlined,
} from "@ant-design/icons";
import AvailableResourcesProps from "../../types/available-resources-props";
import AvailableResource from "./AvailableResource";
import ResourceCounter from "./ResourceCounter";
import StyledAvailableResources from "./StyledAvailableResources";
import AssignToTeamModal from "./AssignToTeamModal";
import { useAppContext } from "../../Context/context";
import ContextMenu from "../ContextMenu/ContextMenu";
import { TriggerOptions } from "../../types/context-menu-props";
import TeamProps from "../../types/team-props";

const { Text } = Typography;

const AvailableResources: FC<AvailableResourcesProps> = ({
  resources,
  total,
  currentStateTotal,
  futureStateTotal,
}) => {
  const {
    state: { teams, teamScenario },
  } = useAppContext();
  const [isResourcesModalOpen, setIsResourcesModalOpen] =
    useState<boolean>(false);
  const [isAssignToTeamModalVisible, setIsAssignToTeamModalVisible] =
    useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<TeamProps>();
  const [teamsToSend, setTeamsToSend] = useState<TeamProps[]>([]);
  const [resourceAssignMode, setResourceAssignMode] = useState<
    "createTeam" | "sendToTeam"
  >("createTeam");

  useEffect(() => {
    setTeamsToSend(teams);
  }, [teams, teamScenario]);

  const switchResourcesModalVisibility = () => {
    setIsResourcesModalOpen(!isResourcesModalOpen);
  };

  const toggleAssignToTeamModalVisibility = () => {
    setIsAssignToTeamModalVisible(!isAssignToTeamModalVisible);
  };

  const onSelectTeam = (team: TeamProps) => {
    setSelectedTeam(team);
    setResourceAssignMode("sendToTeam");
    toggleAssignToTeamModalVisibility();
  };

  const teamsList = () => {
    return (
      <Menu>
        {teamsToSend.map((team) => (
          <Menu.Item onClick={() => onSelectTeam(team)}>{team.name}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const handleCreateTeamAssign = () => {
    setResourceAssignMode("createTeam");
    toggleAssignToTeamModalVisibility();
  };

  return (
    <StyledAvailableResources className="futureStateTeam">
      <Row>
        <Col span={24}>
          <Row className="title" justify="space-between">
            <Col>
              <div>Available Resources</div>
            </Col>
            <Col>
              <Tooltip title="View more">
                <QuestionCircleOutlined
                  onClick={switchResourcesModalVisibility}
                />
              </Tooltip>
            </Col>
            <Col>
              <ContextMenu menu={teamsList} trigger={[TriggerOptions.CLICK]}>
                <Tooltip title="Send to team">
                  <SendOutlined />
                </Tooltip>
              </ContextMenu>
            </Col>
            <Col>
              <Tooltip title="Create team">
                <SisternodeOutlined onClick={handleCreateTeamAssign} />
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={4} offset={12}>
          <Text strong>Junior</Text>
        </Col>
        <Col span={3} offset={1}>
          <Text strong>Mid</Text>
        </Col>
        <Col span={4}>
          <Text strong>Senior</Text>
        </Col>
      </Row>
      <div className="freedUpResources">
        {resources &&
          resources.length > 2 &&
          resources[2]
            .filter((resource) => !resource.role.showNumber8UserIcon)
            .map((resource) => {
              return (
                <AvailableResource
                  resource={resource.role}
                  amount={resource.amount}
                />
              );
            })}
      </div>
      <Row>
        <Col>Total freed up resources: {total.toFixed(2)}</Col>
      </Row>
      {isResourcesModalOpen && (
        <ResourceCounter
          resources={resources}
          total={total}
          futureStateTotal={futureStateTotal}
          currentStateTotal={currentStateTotal}
          switchVisibility={switchResourcesModalVisibility}
        />
      )}
      {isAssignToTeamModalVisible && (
        <AssignToTeamModal
          resources={resources[2]}
          team={selectedTeam}
          toggleVisibility={toggleAssignToTeamModalVisibility}
          mode={resourceAssignMode}
        />
      )}
    </StyledAvailableResources>
  );
};

export default AvailableResources;
