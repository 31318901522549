import { nanoid } from "nanoid";
import ResourceProp from "../types/resource-prop";
import RoleProps from "../types/role-props";
import TeamScenarioProps from "../types/team-scenario-props";
import TeamScenarioResourceProps from "../types/team-scenariou-resource-props";
import ResourceWithRoleProps from "../types/resource-with-role-props";
import TeamStateRolesProps from "../types/team-state-roles-props";
import { RoleSeniority } from "../assets/constants/RoleSeniority";

const MAX_AMOUNT_OF_ROLES_TO_RETURN = 18;

function getScenarioMembersTotalSalary(
  resources: ResourceWithRoleProps[]
): number {
  return resources.reduce(
    (previousValue: number, currentMember: ResourceWithRoleProps) =>
      previousValue +
      (currentMember.role?.annualSalary[currentMember.seniority] || 0) *
        currentMember.resourceAllocation,
    0
  );
}

function scenarioResourcesAmount(
  scenarioResources: TeamScenarioResourceProps[],
  scenarioId: string
): number {
  const resources = scenarioResources.filter(
    (scenario) => scenario.scenarioId === scenarioId
  );
  return resources.reduce((val, r) => val + r.resourceAllocation, 0);
}

function addResourceToTeam(
  scenarios: TeamScenarioProps[],
  scenariosResources: TeamScenarioResourceProps[],
  resources: ResourceProp[],
  roles: RoleProps[],
  scenarioId: string,
  roleId: string
): [TeamScenarioResourceProps[], ResourceProp[]] {
  const roleToAdd = roles.find((role) => role.id === roleId);
  const scenarioToAdd = scenarios.find(
    (scenario) => scenario.id === scenarioId
  );
  if (!roleToAdd || !scenarioToAdd) return [scenariosResources, resources];
  const newResource: ResourceProp = {
    id: nanoid(),
    roleId,
    seniority: RoleSeniority.MID,
  };
  const newScenarioResource: TeamScenarioResourceProps = {
    id: nanoid(),
    seniority: RoleSeniority.MID,
    resourceAllocation: 1,
    resourceId: newResource.id,
    scenarioId,
  };
  return [
    [...scenariosResources, newScenarioResource],
    [...resources, newResource],
  ];
}

function updateTeamMemberAmount(
  roles: TeamStateRolesProps[],
  roleId: string,
  amount: number
): TeamStateRolesProps[] {
  const existingRoles = roles.filter((role) => role.roleId === roleId);
  const currentAmount = existingRoles.length;
  if (amount === 0) {
    return roles.filter((role) => role.roleId !== roleId);
  }
  const firstRole = existingRoles[0];
  const lastRole = existingRoles[currentAmount - 1];
  const firstRoleIndex = roles.findIndex((role) => role.id === firstRole.id);
  const lastRoleIndex = roles.findIndex((role) => role.id === lastRole.id);
  const isAddingRoles = currentAmount < amount;
  if (isAddingRoles) {
    // for (let i = 0; i < amount - currentAmount; i += 1) {
    //   existingRoles = addResourceToTeam(existingRoles, roleId);
    // }
    const newRoles = [
      ...roles.slice(0, firstRoleIndex),
      ...existingRoles,
      ...roles.slice(lastRoleIndex + 1),
    ];
    return newRoles;
  }
  const areRolesConsecutive =
    lastRoleIndex - firstRoleIndex + 1 === currentAmount;
  if (areRolesConsecutive) {
    for (let i = 0; i < currentAmount - amount; i += 1) {
      existingRoles.pop();
    }
    const newRoles = [
      ...roles.slice(0, firstRoleIndex),
      ...existingRoles,
      ...roles.slice(lastRoleIndex + 1),
    ];
    return newRoles;
  }
  const indexesOfRole = roles
    .map((role, index) => (role.roleId === roleId ? index : -1))
    .filter((index) => index !== -1);
  const newRoles = roles;
  for (let i = currentAmount - amount; i > 0; i -= 1) {
    newRoles.splice(indexesOfRole[i], 1);
  }
  return newRoles;
}

/**
 * When a user clicks on a team state role's `Replace with number8`, this will be called to create
 * a new {TeamStateRolesProps[]} with that specific role being removed, and a new number8 with the replace role color.
 * This updates the amount of roles in {TeamStateRolesProps[]}.
 * It will add the new number8 role in place of the replace roled. If there are multiple of the replaced role, it will
 * add it at the start.
 * @param stateRoles {TeamStateRolesProps[]} Team state roles
 * @param roles {RoleProps[]} Original roles
 * @param roleToReplaceWithNumber8 {number} Role id to be replaced by the number8 role.
 * @returns {TeamStateRepeatingRoleProps[]}
 */
function replaceByNumber8(
  resources: ResourceProp[],
  roles: RoleProps[],
  idToReplace: string
): ResourceProp[] {
  const number8Role = roles.find((role) => role.showNumber8UserIcon);
  if (!number8Role) {
    return resources;
  }
  return resources.map((role) => {
    if (role.id === idToReplace) {
      return { ...role, roleId: number8Role.id, previousRole: role.roleId };
    }
    return role;
  });
}

/**
 * When a user clicks on a team state role's `Replace with`, and then chooses a new role this will be called to create
 * a new {TeamStateRolesProps[]} with that specific role being removed, and a new role.
 * This updates the amount of roles in {TeamStateRolesProps[]}.
 * It will add the new number8 role in place of the replace roled. If there are multiple of the replaced role, it will
 * add it at the start.
 * @param stateRoles {TeamStateRolesProps[]} Team state roles
 * @param roleToReplace {string} Role id to be replaced by the new role.
 * @param replacingRole {string} New role id
 * @returns {TeamStateRolesProps[]}
 */
function replaceByAnotherRole(
  resources: ResourceProp[],
  roles: RoleProps[],
  resourceId: string,
  roleId: string
): ResourceProp[] {
  const roleToReplace = roles.find((role) => role.id === roleId);
  if (!roleToReplace) return resources;
  return resources.map((resource) => {
    if (resource.id !== resourceId) return resource;
    return { ...resource, roleId, previousRole: undefined };
  });
}

function removeMember(
  resources: ResourceProp[],
  scenarioResources: TeamScenarioResourceProps[],
  resourceId: string
): [ResourceProp[], TeamScenarioResourceProps[]] {
  return [
    resources.filter((resource) => resource.id !== resourceId),
    scenarioResources.filter(
      (scenarioResource) => scenarioResource.resourceId !== resourceId
    ),
  ];
}

function compareSalaryThenName(
  roleA: ResourceWithRoleProps,
  roleB: ResourceWithRoleProps
): number {
  if (!roleA.role || !roleB.role) {
    return 0;
  }
  const salaryComparisonDesc =
    roleB.role.annualSalary.senior - roleA.role.annualSalary.senior;
  if (salaryComparisonDesc !== 0) {
    return salaryComparisonDesc;
  }
  const nameComparisonAsc = roleA.role.name > roleB.role.name;
  const nameComparisonAsc2 = roleB.role.name > roleA.role.name ? -1 : 0;
  return nameComparisonAsc ? 1 : nameComparisonAsc2;
}

function sortRoles(
  resources: ResourceWithRoleProps[]
): ResourceWithRoleProps[] {
  return resources.sort(compareSalaryThenName);
}

function buildStateRoleWithRoleDTO(
  scenarioResources: TeamScenarioResourceProps[],
  resources: ResourceProp[],
  roles: RoleProps[]
): ResourceWithRoleProps[] {
  const completeRoles: ResourceWithRoleProps[] = scenarioResources
    .map((scenarioResource) => {
      const resource = resources.find(
        (resourceMapped) => resourceMapped.id === scenarioResource.resourceId
      );
      if (!resource) {
        return {
          id: "-1",
          seniority: scenarioResource.seniority,
          resourceAllocation: scenarioResource.resourceAllocation,
        };
      }
      const completeRole = roles.find((role) => role.id === resource.roleId);
      if (!completeRole) {
        return {
          id: "-1",
          seniority: scenarioResource.seniority,
          resourceAllocation: scenarioResource.resourceAllocation,
        };
      }
      if (resource.previousRole && completeRole?.showNumber8UserIcon) {
        const previousRole = roles.find(
          (prevRole) => prevRole.id === resource.previousRole
        );
        if (!previousRole) {
          return {
            ...resource,
            role: completeRole,
            seniority: scenarioResource.seniority,
            resourceAllocation: scenarioResource.resourceAllocation,
          };
        }
        return {
          ...resource,
          role: {
            ...completeRole,
            color: previousRole.color,
            name: `number8 ${previousRole.name}`,
          },
          seniority: scenarioResource.seniority,
          resourceAllocation: scenarioResource.resourceAllocation,
        };
      }
      return {
        ...resource,
        role: completeRole,
        seniority: scenarioResource.seniority,
        resourceAllocation: scenarioResource.resourceAllocation,
      };
    })
    .filter((role) => role.id !== "-1");
  return sortRoles(completeRoles).splice(0, MAX_AMOUNT_OF_ROLES_TO_RETURN);
}

export {
  getScenarioMembersTotalSalary,
  scenarioResourcesAmount,
  addResourceToTeam,
  replaceByNumber8,
  replaceByAnotherRole,
  removeMember,
  updateTeamMemberAmount,
  buildStateRoleWithRoleDTO,
  sortRoles,
};
