import React, { FC } from "react";
import PropTypes from "prop-types";
import { Col, Row, Typography } from "antd";
import RoleProps from "../../types/role-props";
import StyledResourceView from "./StyledResourceView";
import UserIconAndName from "../UserIconAndName/UserIconAndName";
import UserIconSizeEnum from "../../assets/constants/UserIconSizeEnum";

const { Text } = Typography;

const ResourceView: FC<{ roles: RoleProps[] }> = ({ roles }) => {
  return (
    <StyledResourceView>
      <Row>
        <Col span={8}>
          <Text strong>Role & Salary References</Text>
        </Col>
        <Col span={4} offset={3}>
          <Text strong>Junior</Text>
        </Col>
        <Col span={3} offset={1}>
          <Text strong>Mid</Text>
        </Col>
        <Col span={4} offset={1}>
          <Text strong>Senior</Text>
        </Col>
      </Row>
      <div className="roles">
        {roles.map((role) => (
          <Row align="middle" justify="space-between">
            <Col span={10}>
              <UserIconAndName
                color={role.color}
                iconSize={UserIconSizeEnum.EXTRA_SMALL}
                name={role.name}
                showNumber8UserIcon={role.showNumber8UserIcon}
                style={{ height: 30 }}
              />
            </Col>
            <Col span={3}>
              <div>${role.annualSalary.junior / 1000}K</div>
            </Col>
            <Col span={3}>
              <div>${role.annualSalary.mid / 1000}K</div>
            </Col>
            <Col span={3}>
              <div>${role.annualSalary.senior / 1000}K</div>
            </Col>
          </Row>
        ))}
      </div>
    </StyledResourceView>
  );
};

ResourceView.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      annualSalary: PropTypes.shape({
        junior: PropTypes.number.isRequired,
        mid: PropTypes.number.isRequired,
        senior: PropTypes.number.isRequired,
      }).isRequired,
      showNumber8UserIcon: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      isReadOnly: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
};

export default ResourceView;
