import React, { FC, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import TeamSummaryViewProps from "../../types/team-summary-view-props";
import StyledTeamSummaryView from "./StyledTeamSummaryView";
import CPSPBubble from "../CPSPBubble/CPSPBubble";
import calculateCPSP, { getCPSPColorCode } from "../../helpers/calculateCPSP";
import {
  buildStateRoleWithRoleDTO,
  getScenarioMembersTotalSalary,
} from "../../helpers/scenarioResources";
import CPSPBubbleSizeEnum from "../../assets/constants/CPSPSizeEnum";
import CurrentStateName from "../CommonStateTitle/CurrrentStateName";
import TeamScenarioProps from "../../types/team-scenario-props";

const TeamSummaryView: FC<TeamSummaryViewProps> = ({
  team,
  scenarioResources,
  scenarios,
  resources,
  roles,
}) => {
  const [currentScenarioCPSP, setCurrenScenarioCPSP] = useState<number>(0);
  const [withN8CPSP, setWithN8CPSP] = useState<number>(0);
  const [currentScenario, setCurrentScenario] = useState<TeamScenarioProps>();
  const [withN8Scenario, setWithN8Scenario] = useState<TeamScenarioProps>();
  const [currentScenarioResources, setCurrenScenarioResources] =
    useState<number>(0);
  const [withN8ScenarioResources, setWithN8ScenarioResources] =
    useState<number>(0);

  useEffect(() => {
    const teamScenarios = scenarios.filter(
      (scenario) => scenario.teamId === team.id
    );
    const currentState = teamScenarios.find(
      (scenario) => scenario.teamOrder === 0
    );
    const currentScenarioId = currentState?.id;
    if (currentScenarioId) {
      const currentScenarioResource =
        resources.filter((resource) =>
          scenarioResources
            ?.filter(
              (scenarioResource) =>
                scenarioResource.scenarioId === currentScenarioId
            )
            .map((scenarioResource) => scenarioResource.resourceId)
            .includes(resource.id)
        ) || [];
      const currentStateMembers = buildStateRoleWithRoleDTO(
        scenarioResources.filter(
          (scenarioResource) =>
            scenarioResource.scenarioId === currentScenarioId
        ),
        currentScenarioResource,
        roles
      );
      const currentStateCPSP = calculateCPSP(
        getScenarioMembersTotalSalary(currentStateMembers),
        currentState.velocity || team.velocity,
        (currentState.weeksPerSprint || team.weeksPerSprint) * 12
      );
      setCurrenScenarioCPSP(currentStateCPSP);
      setCurrentScenario(currentState);
      setCurrenScenarioResources(currentStateMembers.length);
    }
    if (teamScenarios.length > 1) {
      const futureState = teamScenarios[teamScenarios.length - 1];
      const withN8ScenarioId = futureState.id;
      const withN8ScenarioResource =
        resources.filter((resource) =>
          scenarioResources
            ?.filter(
              (scenarioResource) =>
                scenarioResource.scenarioId === withN8ScenarioId
            )
            .map((scenarioResource) => scenarioResource.resourceId)
            .includes(resource.id)
        ) || [];
      const withN8Members = buildStateRoleWithRoleDTO(
        scenarioResources.filter(
          (scenarioResource) => scenarioResource.scenarioId === withN8ScenarioId
        ),
        withN8ScenarioResource,
        roles
      );
      const futureStateCPSP = calculateCPSP(
        getScenarioMembersTotalSalary(withN8Members),
        futureState.velocity || team.velocity,
        (futureState.weeksPerSprint || team.weeksPerSprint) * 12
      );
      setWithN8CPSP(futureStateCPSP);
      setWithN8Scenario(futureState);
      setWithN8ScenarioResources(withN8Members.length);
    }
  }, [
    currentScenario,
    resources,
    roles,
    scenarioResources,
    scenarios,
    setWithN8ScenarioResources,
    team.id,
    team.velocity,
    team.weeksPerSprint,
  ]);

  return (
    <StyledTeamSummaryView>
      <Row>
        <Col className="title">{team.name}</Col>
      </Row>
      <Row justify="space-between">
        <Col className="subTitle">
          <CurrentStateName />
        </Col>
        <Col className="subTitle withN8">With #8</Col>
      </Row>
      <Row justify="space-between">
        <Col className="comparison">
          {currentScenario?.velocity || team.velocity}
        </Col>
        <Col>Average Velocity</Col>
        <Col className="comparison">{withN8Scenario?.velocity || "-"}</Col>
      </Row>
      <Row justify="space-between">
        <Col className="comparison">{team.weeksPerSprint}</Col>
        <Col>Weeks per Sprint</Col>
        <Col className="comparison">
          {withN8Scenario ? team.weeksPerSprint : "-"}
        </Col>
      </Row>
      <Row justify="space-between">
        <Col className="comparison">{currentScenarioResources}</Col>
        <Col>Team Members</Col>
        <Col className="comparison">{withN8ScenarioResources || "-"}</Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <CPSPBubble
            value={currentScenarioCPSP}
            color="#666"
            size={CPSPBubbleSizeEnum.EXTRA_SMALL}
          />
        </Col>
        <Col>CPSP</Col>
        <Col>
          <CPSPBubble
            value={withN8CPSP}
            color={getCPSPColorCode(currentScenarioCPSP, withN8CPSP)}
            size={CPSPBubbleSizeEnum.EXTRA_SMALL}
          />
        </Col>
      </Row>
    </StyledTeamSummaryView>
  );
};

TeamSummaryView.propTypes = {
  team: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    weeksPerSprint: PropTypes.number.isRequired,
    velocity: PropTypes.number.isRequired,
  }).isRequired,
};

export default TeamSummaryView;
