import React, { FC } from "react";
import { Col, Row, Typography } from "antd";
import UserIconSizeEnum from "../../assets/constants/UserIconSizeEnum";
import AvailableResourceProps from "../../types/available-resource-props";
import UserIcon from "../UserIcon/UserIcon";
import { replaceRoleNameWithAbbreviation } from "../../helpers/roles";

const { Text } = Typography;

const AvailableResource: FC<AvailableResourceProps> = ({
  resource,
  amount,
}) => {
  return (
    <Row align="middle" gutter={[0, 0]} data-cy="availableResources">
      <Col span={4}>
        <UserIcon
          color={resource.color}
          showNumber8UserIcon={false}
          size={UserIconSizeEnum.MEDIUM}
          name={resource.name}
        />
      </Col>
      <Col span={7}>
        <Text style={{ maxWidth: "80%" }} ellipsis>
          {replaceRoleNameWithAbbreviation(resource).name}
        </Text>
      </Col>
      <Col span={2}>x</Col>
      <Col span={2} data-cy={`availableResources${resource.id}JuniorAmount`}>
        {amount.junior.toFixed(2)}
      </Col>
      <Col
        span={2}
        offset={2}
        data-cy={`availableResources${resource.id}MidAmount`}
      >
        {amount.mid.toFixed(2)}
      </Col>
      <Col
        span={2}
        offset={2}
        data-cy={`availableResources${resource.id}SeniorAmount`}
      >
        {amount.senior.toFixed(2)}
      </Col>
    </Row>
  );
};
export default AvailableResource;
