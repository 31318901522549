import styled from "styled-components";

const StyledMetricsState = styled.div`
  margin: 0 5px;
  padding: 8px;
  margin-bottom: 10px;
  text-align: center;
  .tab-headers {
    border: solid 1px #666666;
    display: flex;
    padding: 20px;
  }
  .metrics-stats-headers {
    border: solid 1px #807979;
  }
  .metrics-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #4c4b4b;
  }
  .new-teams-header {
    border: solid 1px #807979;
  }
  .column-header {
    color: #4c4b4b;
    font-size: 20px;
  }
  h5 {
    margin-bottom: 0;
    color: #676767;
  }
  .sub-title {
    color: #898787;
    font-size: 16px;
  }
  .n8 {
    h5.ant-typography {
      color: #f26522;
      font-size: 20px;
    }
  }
  .n8Col {
    background-color: #efefef;
  }
  .left-title {
    text-align: left;
    padding: 25px 10px 0;

    &.first {
      padding: 40px 10px 0;
    }
  }
  .last-title {
    padding-bottom: 17px;
  }
  .metrics-values {
    padding: 26px 0;
    h5.ant-typography {
      font-size: 20px;
    }
  }
  .first-row .metrics-values {
    padding: 50px 0 26px;
    h5.ant-typography {
      font-size: 20px;
    }
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 80px;
    > .ant-row > .ant-col {
      > h5 {
        font-size: 14px;
      }
      > div {
        height: 60px;
        width: 60px;
        line-height: 60px;
      }
    }
  }
`;

export default StyledMetricsState;
