import React, { FC } from "react";
import { Row, Modal, Col, Divider, Typography } from "antd";
import AvailableResourcesProps from "../../types/available-resources-props";
import UserIconSizeEnum from "../../assets/constants/UserIconSizeEnum";
import UserIconAndName from "../UserIconAndName/UserIconAndName";
import StyledResourceCounter from "./StyledResourceCounter";
import WithN8Name from "../CommonStateTitle/WithN8Name";

const { Text } = Typography;

const ResourceCounter: FC<AvailableResourcesProps> = ({
  resources,
  total,
  futureStateTotal,
  currentStateTotal,
  switchVisibility,
}) => {
  return (
    <Modal
      visible
      title="Resource Counter"
      width={600}
      footer={null}
      onCancel={switchVisibility}
    >
      <StyledResourceCounter>
        <Row align="middle" justify="space-around" className="titles">
          <Col span={5} offset={10}>
            <Text strong>Current Scenario</Text>
          </Col>
          <Col span={5}>
            <Text strong>
              <WithN8Name />
            </Text>
          </Col>
          <Col span={3}>
            <Text strong>Delta</Text>
          </Col>
        </Row>
        <Row justify="space-around">
          <Col span={12}>
            {resources &&
              resources[0].map((resource) => (
                <UserIconAndName
                  color={resource.role.color}
                  showNumber8UserIcon={resource.role.showNumber8UserIcon}
                  iconSize={UserIconSizeEnum.SMALL}
                  name={resource.role.name}
                  style={{ height: 30 }}
                />
              ))}
          </Col>
          <Col span={2}>
            {/* TODO: to be fixed */}
            {resources &&
              resources[0].map((resource) => (
                <div>{resource.amount.senior.toFixed(2)}</div>
              ))}
          </Col>
          <Col span={2}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={2}>
            {/* TODO: to be fixed */}
            {resources &&
              resources[1].map((resource) => (
                <div>{resource.amount.senior.toFixed(2)}</div>
              ))}
          </Col>
          <Col span={2}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={2}>
            {/* TODO: to be fixed */}
            {resources &&
              resources[2].map((resource) => (
                <div>{resource.amount.senior.toFixed(2)}</div>
              ))}
          </Col>
        </Row>
        <Divider />
        <Row justify="space-around">
          <Col span={12}>
            <Text>Total: </Text>
          </Col>
          <Col span={2}>
            <Text>{currentStateTotal.toFixed(2)}</Text>
          </Col>
          <Col span={2}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={2}>
            <Text>{futureStateTotal.toFixed(2)}</Text>
          </Col>
          <Col span={2}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={2}>
            <Text>{total.toFixed(2)}</Text>
          </Col>
        </Row>
      </StyledResourceCounter>
    </Modal>
  );
};

export default ResourceCounter;
