import styled from "styled-components";
import StyledFutureStateBanner from "../FutureState/StyledFutureStateBanner";

const StyledSummaryViewBanner = styled(StyledFutureStateBanner)`
  background: #fff;
  border: 1px solid #dbdbdb;
  width: 950px;
  height: 55px;
  > .ant-row > .ant-col > .ant-row > .ant-col > .withN8 {
    color: #f26522;
    display: inline-block;
  }
  h5 {
    margin-bottom: 0;
  }
  > .ant-row > .ant-col > .current {
    color: #666;
    margin-right: 5px;
    display: inline-block;
  }
  > .ant-row > .ant-col > .currentStateTotal {
    margin-right: 5px;
    display: inline-block;
  }
  > .ant-row > .ant-col > .ant-row > .ant-col > .currentStateTotal {
    margin-right: 5px;
    display: inline-block;
  }
`;

export default StyledSummaryViewBanner;
