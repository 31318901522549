enum ProTipsType {
  multiSelect = "multiSelect",
  changeLogo = "changeLogo",
}

type ProTips = Record<ProTipsType, boolean>;

type ProTipProps = {
  message: string;
  type: ProTipsType;
  className?: string;
};

export { ProTipsType };

export type { ProTipProps, ProTips };
