import React, { useRef } from "react";
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from "react-dnd";
import { DragItem, TabNodeProps } from "../../types/draggable-tabs";

export const WrapTabNode = ({
  index,
  moveTabNode,
  children,
}: TabNodeProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: "div",
    drop(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      moveTabNode(dragIndex, hoverIndex);
    },
  });

  const [, drag] = useDrag({
    type: "div",
    item: () => {
      return { index };
    },
  });

  drag(drop(ref));
  return <div ref={ref}>{children}</div>;
};
