import React, { FC } from "react";
import PropTypes from "prop-types";
import { Col, Input, Row, Typography } from "antd";
import StateSummaryProps from "../../types/state-summary-props";
import CPSPBubble from "../CPSPBubble/CPSPBubble";
import UserIcon from "../UserIcon/UserIcon";
import StyledStateSummaryCol from "./StyledStateSummaryCol";
import StyledTeamStateSummary from "./StyledTeamStateSummary";
import { RoleSeniorityList } from "../../assets/constants/RoleSeniority";

const { Text } = Typography;

const StateSummary: FC<StateSummaryProps> = ({
  name,
  velocity,
  weeksPerSprint,
  cpsp,
  cpspColor,
  members,
  index,
  futureStateTeam,
}) => {
  return (
    <StyledTeamStateSummary
      data-cy={`currentStateTeam${index}`}
      className={`${futureStateTeam ? "futureStateTeam" : ""}`}
    >
      <Row justify="space-between">
        <Col span={17}>
          <div className="stateName">{name}</div>
        </Col>
      </Row>
      <Row className="velocity teamStateRow">
        <Col span={17}>
          <Text>Average Velocity: </Text>
        </Col>
        <Col span={7}>
          <Input value={velocity} size="middle" type="number" />
        </Col>
      </Row>
      <Row className="weeksPerSprint teamStateRow">
        <Col span={17}>
          <Text>Weeks Per Sprint: </Text>
        </Col>
        <Col span={7}>
          <Input value={weeksPerSprint} size="middle" type="number" />
        </Col>
      </Row>
      <Row className="teamStateRow" align="middle">
        <Col span={10}>
          <Text className="cpsp-description">Cost Per Story Point: </Text>
        </Col>
        <Col span={8} offset={4}>
          <CPSPBubble
            value={cpsp === 0 ? "-" : cpsp.toString()}
            color={cpspColor}
          />
        </Col>
      </Row>
      <Row className="teamStateRow">
        <Col span={24}>
          <Row>
            <StyledStateSummaryCol span={16}>
              <Text>Team members:</Text>
            </StyledStateSummaryCol>
            <StyledStateSummaryCol span={2} offset={2}>
              <Text>
                {members.reduce((val, m) => val + m.resourceAllocation, 0)}
              </Text>
            </StyledStateSummaryCol>
          </Row>
        </Col>
      </Row>
      <Row className="stateTeamMembers">
        {members &&
          members.map((teamMember) => (
            <Col span={6} key={teamMember.id}>
              <UserIcon
                color={teamMember.role?.color || ""}
                showNumber8UserIcon={
                  teamMember.role?.showNumber8UserIcon || false
                }
                seniority={teamMember.seniority}
                key={teamMember.id}
                name={teamMember.role?.name}
                withResourceAllocationShadow={teamMember.resourceAllocation < 1}
                resourceAllocation={teamMember.resourceAllocation}
              />
            </Col>
          ))}
      </Row>
    </StyledTeamStateSummary>
  );
};

StateSummary.propTypes = {
  name: PropTypes.string.isRequired,
  velocity: PropTypes.number.isRequired,
  weeksPerSprint: PropTypes.number.isRequired,
  cpsp: PropTypes.number.isRequired,
  cpspColor: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      role: PropTypes.shape({
        name: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        annualSalary: PropTypes.shape({
          junior: PropTypes.number.isRequired,
          mid: PropTypes.number.isRequired,
          senior: PropTypes.number.isRequired,
        }).isRequired,
        showNumber8UserIcon: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired,
        isReadOnly: PropTypes.bool.isRequired,
      }).isRequired,
      resourceAllocation: PropTypes.number.isRequired,
      seniority: PropTypes.oneOf(RoleSeniorityList).isRequired,
    }).isRequired
  ).isRequired,
  index: PropTypes.number.isRequired,
  futureStateTeam: PropTypes.bool.isRequired,
};
export default StateSummary;
