import styled from "styled-components";

const StyledSummaryState = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-y: auto;
  height: 85vh;
  @media screen and (min-width: 1400px) {
    height: 89vh;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 1024px;
  }
`;

export default StyledSummaryState;
