import React, { FC } from "react";
import PropTypes from "prop-types";
import { Typography, Row, Col } from "antd";
import StyledSummaryViewBanner from "./StyledSummaryViewBanner";
import SummaryViewBannerProps from "../../types/future-state-banner-props";
import CPSPBubble from "../CPSPBubble/CPSPBubble";
import CPSPBubbleSizeEnum from "../../assets/constants/CPSPSizeEnum";

const { Title } = Typography;
const BANNER_STATES_DEFAULT_COLOR = "#F7E2D7";
const BANNER_STATES_DEFAULT_FONT_COLOR = "#666";

const SummaryViewBanner: FC<SummaryViewBannerProps> = ({
  currentStateTotal,
  futureStateTotal,
  variance,
  varianceColor,
}) => {
  return (
    <StyledSummaryViewBanner>
      <Row justify="start" align="middle">
        <Col span={5}>
          <Row gutter={4}>
            <Col>
              <Title level={5} className="current">
                Current
              </Title>
            </Col>
            <Col>
              <Title level={5} className="currentStateTotal">
                CPSP Total
              </Title>
            </Col>
          </Row>
        </Col>
        <Col span={3}>
          <CPSPBubble
            value={currentStateTotal}
            color={BANNER_STATES_DEFAULT_FONT_COLOR}
            backgroundColor={BANNER_STATES_DEFAULT_COLOR}
            size={CPSPBubbleSizeEnum.EXTRA_SMALL}
          />
        </Col>
        <Col span={5}>
          <Row gutter={4}>
            <Col>
              <Title level={5} className="withN8">
                With #8
              </Title>
            </Col>
            <Col>
              <Title level={5} className="currentStateTotal">
                CPSP Total
              </Title>
            </Col>
          </Row>
        </Col>
        <Col span={3}>
          <CPSPBubble
            value={futureStateTotal}
            color={BANNER_STATES_DEFAULT_FONT_COLOR}
            backgroundColor={BANNER_STATES_DEFAULT_COLOR}
            size={CPSPBubbleSizeEnum.EXTRA_SMALL}
          />
        </Col>
        <Col span={3}>
          <Title level={5}>Variance</Title>
        </Col>
        <Col span={3} data-cy="futureStateVariance">
          <CPSPBubble
            value={variance}
            color={varianceColor}
            backgroundColor={varianceColor}
            size={CPSPBubbleSizeEnum.EXTRA_SMALL}
          />
        </Col>
      </Row>
    </StyledSummaryViewBanner>
  );
};

SummaryViewBanner.propTypes = {
  currentStateTotal: PropTypes.number.isRequired,
  futureStateTotal: PropTypes.number.isRequired,
  variance: PropTypes.number.isRequired,
  varianceColor: PropTypes.string.isRequired,
};
export default SummaryViewBanner;
