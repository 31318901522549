function calculateCPSP(
  annualSalary: number,
  velocity: number,
  sprintsPerYear: number
): number {
  const cpsp = Math.round(annualSalary / sprintsPerYear / velocity);
  if (!Number.isFinite(cpsp)) {
    return 0;
  }
  return cpsp;
}

function getCPSPColorCode(
  currentStateCPSP: number,
  selectedStateCPSP: number
): string {
  if (selectedStateCPSP < currentStateCPSP) {
    if (selectedStateCPSP <= currentStateCPSP * 0.8) {
      return "#028557";
    }
    return "#80cfad";
  }
  if (selectedStateCPSP >= currentStateCPSP) {
    if (selectedStateCPSP <= currentStateCPSP * 1.2) {
      return "#ff991f";
    }
    return "#cc0000";
  }
  return "#666666";
}

export { getCPSPColorCode };

export default calculateCPSP;
