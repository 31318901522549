import styled from "styled-components";

const StyledSummaryView = styled.div`
  height: 816px;
  width: 1054px;
  > .ant-layout {
    height: 816px;
    background: #fff;
    > header {
      background: #fff;
      padding: 0 24px;
      display: flex;
      width: 1054px;
      > .ant-row {
        width: 1054px;
      }
      > .ant-row > .ant-col > .ant-typography {
        margin-bottom: 0;
      }
      > .ant-row > .ant-col > .ant-row > .ant-col:first-child {
        max-width: 120px;
        max-height: 50px;
      }
      > .ant-row > .ant-col > .ant-row > .ant-col {
        > .ant-divider {
          height: 50px;
          width: 10px;
        }
        > .n8Image {
          height: 40px;
          width: 177px;
        }
        > .clientImage {
          width: 100%;
          height: auto;
        }
      }
    }
    > .ant-layout {
      margin-left: 20px;
      background: #fff;
      height: 633px;
      > .ant-row > .ant-col {
        > .teams {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
        }
        > .detailedViewTeams {
          display: grid;
          grid-template-columns: repeat(6, 130px);
          grid-column-gap: 10px;
        }
        > .ant-row {
          > .ant-col {
            > .banner {
              height: 40px;
              background-color: #f7f4f4;
              width: 700px;
              border-radius: 5px;
              font-size: 18px;
              padding-left: 20px;
              display: flex;
              align-items: center;
              > .n8 {
                color: #f26522;
                margin-right: 3px;
              }
            }
            > .currentState {
              display: flex;
            }
            > .title {
              font-size: 23px;
            }
            > .subtitle {
              display: flex;
              font-size: 21px;
              > div {
                margin: 0 5px;
              }
            }
            > .typeOfView {
              color: #f26522;
              font-weight: bold;
            }
          }
        }
      }
      > .teams {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
      }
      > .detailedViewTeams {
        display: grid;
        grid-template-columns: repeat(6, 130px);
        grid-column-gap: 10px;
      }
      > .ant-row {
        > .ant-col {
          > .banner {
            height: 40px;
            background-color: #f7f4f4;
            width: 700px;
            border-radius: 5px;
            font-size: 18px;
            padding-left: 20px;
            display: flex;
            align-items: center;
            > div {
              color: #f26522;
            }
          }
          > .currentState {
            display: flex;
          }
          > .title {
            font-size: 23px;
          }
          > .subtitle {
            display: flex;
            font-size: 21px;
            > div {
              margin: 0 5px;
            }
          }
          > .typeOfView {
            color: #f26522;
            font-weight: bold;
          }
        }
      }
    }
    > footer {
      background: #fff;
      color: #666666;
      font-size: 10px;
      padding-bottom: 0;
      height: 126px;
      > .ant-row > .ant-col > .ant-divider {
        width: 900px;
      }
    }
  }
`;

export default StyledSummaryView;
