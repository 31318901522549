import styled from "styled-components";

const StyledEditRoleRow = styled.div`
  > .ant-row > .ant-col > .deleteRoleBtn {
    justify-self: center;
    align-self: center;
    margin-left: 15px;
  }
`;

export default StyledEditRoleRow;
