import styled from "styled-components";
import StyledTeamState from "../TeamState/StyledTeamState";

const StyledTeamStateSummary = styled(StyledTeamState)`
  outline: 1px solid #9d9999;
  margin-top: 1px;
  margin-left: 1px;
  padding-top: 20px;
  padding-left: 10px;
  width: 250px;
  height: 550px;
  > .ant-row > .ant-col > .stateName {
    height: 60px;
    word-break: normal;
    overflow-wrap: anywhere;
    width: 280px;
  }
`;

export default StyledTeamStateSummary;
