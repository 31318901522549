import React, { FC, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, Col, Divider, Input, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import { CopyOutlined } from "@ant-design/icons";
import TeamTabProps from "../../types/team-tab-props";
import TeamState from "../TeamState/TeamState";
import StyledTeamTab from "./StyledTeamTab";
import { getTeamScenarioNameSuggestions } from "../../helpers/teams";
import TeamValuesEnum from "../../assets/constants/TeamValuesEnum";
import TeamScenarioProps from "../../types/team-scenario-props";

const { Title } = Typography;

const TeamTab: FC<TeamTabProps> = ({
  team,
  cloneTeamState,
  scenarios,
  resources,
  scenarioResources,
}) => {
  const [isNewStateNameFormVisible, setIsNewStateNameFormVisible] =
    useState<boolean>(false);
  const [selectedState, setSelectedState] = useState<string>("0");
  const [newStateName, setNewStateName] = useState<string>("");
  const [teamScenarios, setTeamScenarios] = useState<TeamScenarioProps[]>();
  const [currentScenario, setCurrentScenario] = useState("currentScenario");

  useEffect(() => {
    setTeamScenarios(
      scenarios.filter((scenario) => scenario.teamId === team.id)
    );

    if (scenarios.length <= 1) {
      setCurrentScenario("currentScenario");
    } else {
      setCurrentScenario("currentScenarioTwo");
    }
  }, [scenarios, team.id]);

  const toggleNewStateVisibilityForm = (stateId: string | number) => {
    setIsNewStateNameFormVisible(!isNewStateNameFormVisible);
    setSelectedState(stateId.toString());
  };

  const submitStateClone = () => {
    cloneTeamState(
      selectedState,
      newStateName || getTeamScenarioNameSuggestions(scenarios, team.id)
    );
    setNewStateName("");
    toggleNewStateVisibilityForm(selectedState);
  };

  return (
    <StyledTeamTab>
      {teamScenarios?.map((teamScenario: TeamScenarioProps, index: number) => (
        <div
          className={`teamState ${index === 0 ? currentScenario : ""}`}
          key={teamScenario.id}
          data-cy={`teamState-${index}`}
        >
          <TeamState
            name={teamScenario.name}
            order={teamScenario.teamOrder}
            resources={
              resources.filter((resource) =>
                scenarioResources
                  ?.filter(
                    (scenarioResource) =>
                      scenarioResource.scenarioId === teamScenario.id
                  )
                  .map((scenarioResource) => scenarioResource.resourceId)
                  .includes(resource.id)
              ) || []
            }
            velocity={teamScenario?.velocity || team.velocity}
            weeksPerSprint={teamScenario?.weeksPerSprint || team.weeksPerSprint}
            teamId={team.id}
            id={teamScenario.id}
            toggleNewStateVisibilityForm={toggleNewStateVisibilityForm}
            amountOfStatesInTeam={teamScenarios.length}
            teamName={team.name}
            teamScenarios={teamScenarios}
            teamScenarioResources={scenarioResources.filter(
              (scenarioResource) =>
                scenarioResource.scenarioId === teamScenario.id
            )}
          />
          <Divider
            type="vertical"
            style={{ height: 510 }}
            className="teamStatesDivider"
          />
        </div>
      ))}
      {!teamScenarios ||
        (teamScenarios.length <= 1 && (
          <Col
            className="missingStatePlaceHolder"
            data-cy={`missingStateMessage${team.id}`}
            style={{ height: "100%", marginLeft: 80 }}
          >
            <Card
              style={{ width: 300, marginLeft: 0 }}
              data-cy="missingStateMessage"
            >
              To include this team in the “Future” Summary Tab at least one
              additional scenario must be added with the <CopyOutlined />{" "}
              button.
            </Card>
          </Col>
        ))}
      {isNewStateNameFormVisible && (
        <div
          role="button"
          onKeyDown={({ code }) => {
            if (code === "Enter") {
              submitStateClone();
            }
          }}
          tabIndex={0}
        >
          <Modal
            onOk={submitStateClone}
            onCancel={() =>
              toggleNewStateVisibilityForm(
                selectedState || TeamValuesEnum.CURRENT_SCENARIO_ID
              )
            }
            footer={[
              <Button
                key="back"
                onClick={() =>
                  toggleNewStateVisibilityForm(
                    selectedState || TeamValuesEnum.CURRENT_SCENARIO_ID
                  )
                }
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={submitStateClone}
                data-cy="submitNewCloneNameBtn"
              >
                Create
              </Button>,
            ]}
            visible
            title={
              <Title level={3} style={{ marginBottom: 0 }}>
                New Scenario Name
              </Title>
            }
          >
            <Input
              placeholder={getTeamScenarioNameSuggestions(scenarios, team.id)}
              onChange={(event) => setNewStateName(event.target.value)}
            />
          </Modal>
        </div>
      )}
    </StyledTeamTab>
  );
};

TeamTab.propTypes = {
  team: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    velocity: PropTypes.number.isRequired,
    weeksPerSprint: PropTypes.number.isRequired,
  }).isRequired,
  cloneTeamState: PropTypes.func.isRequired,
};

export default TeamTab;
