import styled from "styled-components";
import UserIconSizeEnum from "../../assets/constants/UserIconSizeEnum";

type StyledChevronIconProps = {
  size: UserIconSizeEnum;
};

const calculateFontSize = (iconSize: UserIconSizeEnum) => {
  if (iconSize === UserIconSizeEnum.EXTRA_SMALL) {
    return "8px";
  }
  return "14px";
};

const StyledChevronIcon = styled.div<StyledChevronIconProps>`
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  z-index: 1;
  transform: rotate(90deg);
  width: ${(props) => calculateFontSize(props.size)};
  span {
    font-size: ${(props) => calculateFontSize(props.size)};
    color: #808080;
    font-family: "Titan One";
    font-weight: 900;
  }
`;

export default StyledChevronIcon;
