import React, { FC } from "react";
import TeamValuesEnum from "../../assets/constants/TeamValuesEnum";
import { useAppContext } from "../../Context/context";
import calculateCPSP from "../../helpers/calculateCPSP";
import { getTeamScenarioCPSPColor } from "../../helpers/teams";
import {
  buildStateRoleWithRoleDTO,
  getScenarioMembersTotalSalary,
} from "../../helpers/scenarioResources";
import StateSummary from "../StateSummary/StateSummary";
import StyledSummaryState from "./StyledSummaryState";

const CurrentState: FC = () => {
  const {
    state: { teams, roles, teamScenario, resources, teamScenarioResource },
  } = useAppContext();
  return (
    <StyledSummaryState>
      {teams.map((team, index) =>
        teamScenario
          .filter((scenario) => scenario.teamId === team.id)
          .filter(
            (scenario) =>
              scenario.teamOrder.toString() ===
              TeamValuesEnum.CURRENT_SCENARIO_ID
          )
          .map((scenario) => {
            const members = buildStateRoleWithRoleDTO(
              teamScenarioResource.filter(
                (scenarioResource) =>
                  scenarioResource.scenarioId === scenario.id
              ),
              resources.filter((resource) =>
                teamScenarioResource
                  .filter(
                    (scenarioResource) =>
                      scenarioResource.scenarioId === scenario.id
                  )
                  .map((scenarioResource) => scenarioResource.resourceId)
                  .includes(resource.id)
              ),
              roles
            );
            const scenarioResourcesAmnt =
              getScenarioMembersTotalSalary(members);
            const cpsp = calculateCPSP(
              scenarioResourcesAmnt,
              scenario.velocity || team.velocity,
              team.weeksPerSprint * 12
            );
            const cpspColor = getTeamScenarioCPSPColor(
              resources,
              teamScenario,
              teamScenarioResource,
              teams,
              roles,
              team.id,
              scenario.id
            );
            return (
              <StateSummary
                index={index}
                name={team.name}
                velocity={scenario.velocity || team.velocity}
                weeksPerSprint={team.weeksPerSprint}
                cpsp={cpsp}
                cpspColor={cpspColor}
                members={members}
                futureStateTeam={false}
              />
            );
          })
      )}
    </StyledSummaryState>
  );
};

export default CurrentState;
