import CPSPEnum from "./assets/constants/CPSPEnum";
import OktaLoginEnum from "./assets/constants/OktaLoginEnum";

const clientId = window.location.origin.includes(CPSPEnum.PROD_URL)
  ? OktaLoginEnum.PROD_CLIENT_ID
  : OktaLoginEnum.DEV_CLIENT_ID;

const oktaAuthConfig = {
  issuer: `${OktaLoginEnum.HOST}${OktaLoginEnum.OAUTH}`,
  clientId,
  redirectUri: `${window.location.origin}${OktaLoginEnum.LOGIN_CALLBACK}`,
};

const oktaSignInConfig = {
  baseUrl: `${OktaLoginEnum.HOST}`,
  clientId,
  redirectUri: `${window.location.origin}${OktaLoginEnum.LOGIN_CALLBACK}`,
  authParams: {
    scopes: ["openid", "email", "address", "profile", "phone"],
    issuer: `${OktaLoginEnum.HOST}`,
  },
};

export { oktaAuthConfig, oktaSignInConfig };
