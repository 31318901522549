enum OktaLoginEnum {
  MAIN_PATH = "/",
  PDF_PATH = "/pdf/:cacheId",
  HOST = "https://number8.okta.com",
  LOGIN_PATH = "/login",
  LOGIN_CALLBACK = "/login/callback",
  OAUTH = "/oauth2/default",
  TEST_ENV = "test",
  DEV_ENV = "development",
  PROD_ENV = "production",
  DEV_CLIENT_ID = "0oac8cobcl7yyjFLX357",
  PROD_CLIENT_ID = "0oacbvackhmslkWxu357",
}

export default OktaLoginEnum;
