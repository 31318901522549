import React, { FC, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { parse } from "papaparse";
import { List, Modal, Button, Typography } from "antd";
import PropTypes from "prop-types";
import { CloudUploadOutlined } from "@ant-design/icons";
import ImportRolesModalProps from "../../types/edit-roles-modal-props";
import { addRoleAction, updateRoleAction } from "../../Context/actions";
import RoleProps from "../../types/role-props";
import Role from "../Role/Role";
import { useAppContext } from "../../Context/context";

const { Title } = Typography;

const ImportRolesModal: FC<ImportRolesModalProps> = ({ closeModal }) => {
  const fileTypes = ["CSV"];
  const [file, setFile] = useState<Array<RoleProps>>([]);
  const {
    state: { roles, colors },
    dispatch,
  } = useAppContext();
  const availableColors = colors;
  const pickRandomColor = (c: string): string => {
    const colorAlreadyExists = roles.find((r) => r.color === c);
    if (colorAlreadyExists) {
      return availableColors.splice(
        Math.random() * availableColors.length,
        1
      )[0];
    }
    return c;
  };
  interface CsvInputType {
    name: string;
    abbreviation?: string;
    color: string;
    salary_junior: number;
    salary_senior: number;
    salary_mid: number;
  }
  const readFile = (incomingFile: File) => {
    parse(incomingFile, {
      header: true,
      complete: (results: { data: CsvInputType[] }) => {
        const parsedData = results.data
          .map((role: CsvInputType): RoleProps => {
            const roleAlreadyExists = roles.find(
              (r) => r.name.toLowerCase() === role.name.toLowerCase()
            );
            return {
              name: role.name,
              abbreviation: role.abbreviation ? role.abbreviation : "",
              color: roleAlreadyExists
                ? roleAlreadyExists.color
                : pickRandomColor(role.color),
              annualSalary: {
                junior: +role.salary_junior,
                mid: +role.salary_mid,
                senior: +role.salary_senior,
              },
              showNumber8UserIcon: role.name === "number8",
              id: "1", // This id is temporary
              isReadOnly: false,
            };
          })
          .filter((role: RoleProps) => {
            return role.name;
          });
        setFile(parsedData);
      },
    });
  };
  const syncRoles = () => {
    file.forEach((role: RoleProps) => {
      //  If we find the same role name, update the salaries
      const foundRole = roles.find((r: RoleProps) => {
        return r.name.toLowerCase() === role.name.toLowerCase();
      });
      if (foundRole) {
        dispatch(
          updateRoleAction(foundRole.id, "annualSalary", role.annualSalary)
        );
      } else {
        dispatch(
          addRoleAction(
            role.name,
            role.abbreviation,
            role.annualSalary,
            role.color,
            false
          )
        );
      }
    });
    closeModal();
  };

  return (
    <Modal
      visible
      centered
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          data-cy="editRolesSubmitBtn"
          onClick={syncRoles}
        >
          Upload
        </Button>,
      ]}
      data-cy="ImportRolesModal"
      width={550}
    >
      <div role="button" tabIndex={0}>
        {file.length ? (
          <div>
            <Title level={3} style={{ marginBottom: 25, marginTop: 15 }}>
              Review the Roles and Salaries to import
            </Title>
            <div
              style={{
                borderStyle: "dashed",
                borderColor: "grey",
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
                minHeight: "25vh",
                maxHeight: "50vh",
                height: "auto",
                overflow: "auto",
              }}
            >
              <List
                size="small"
                bordered
                dataSource={file}
                renderItem={({
                  annualSalary,
                  color,
                  showNumber8UserIcon,
                  name,
                  id,
                  isReadOnly,
                }) => (
                  <List.Item style={{ padding: 0 }}>
                    <Role
                      annualSalary={annualSalary}
                      color={color}
                      showNumber8UserIcon={showNumber8UserIcon}
                      name={name}
                      id={id}
                      isReadOnly={isReadOnly}
                    />
                  </List.Item>
                )}
              />
            </div>
          </div>
        ) : (
          <FileUploader handleChange={readFile} name="file" types={fileTypes}>
            <p>Upload an CSV file</p>
            <div
              style={{
                borderStyle: "dashed",
                borderColor: "grey",
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                height: "25vh",
                justifyContent: "center",
              }}
            >
              <CloudUploadOutlined style={{ fontSize: 40, marginBottom: 6 }} />
              Drop file to attach,
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                }}
              >
                or <p style={{ color: "orange", marginLeft: 2 }}>browse</p>
              </div>
            </div>
          </FileUploader>
        )}
      </div>
    </Modal>
  );
};

ImportRolesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
export default ImportRolesModal;
