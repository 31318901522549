import React, {
  FC,
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { Button, Col, Input, Menu, Row, Tooltip, Typography } from "antd";
import { CopyOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { isMobile } from "react-device-detect";
import SubMenu from "antd/lib/menu/SubMenu";
import TeamStateProps from "../../types/team-state-props";
import StyledTeamState from "./StyledTeamState";
import calculateCPSP from "../../helpers/calculateCPSP";
import { useAppContext } from "../../Context/context";
import UserIcon from "../UserIcon/UserIcon";
import Number8Icon from "../../assets/icons/n8-hashtag.png";
import {
  buildStateRoleWithRoleDTO,
  scenarioResourcesAmount,
  getScenarioMembersTotalSalary,
} from "../../helpers/scenarioResources";
import CPSPBubble from "../CPSPBubble/CPSPBubble";
import {
  removeTeamMemberAction,
  addRoleToStateAction,
  replaceRoleWithNumber8Action,
  replaceRoleWithRole8Action,
  updateTeamStateField,
  deleteStateAction,
  propagateStateChange,
} from "../../Context/actions";
import { getTeamScenarioCPSPColor } from "../../helpers/teams";
import ContextMenu from "../ContextMenu/ContextMenu";
import { TriggerOptions } from "../../types/context-menu-props";
import TeamValuesEnum from "../../assets/constants/TeamValuesEnum";
import ResourceWithRoleProps from "../../types/resource-with-role-props";
import TeamScenarioName from "./TeamScenarioName";
import UserIconSizeEnum from "../../assets/constants/UserIconSizeEnum";
import UserIconAndName from "../UserIconAndName/UserIconAndName";
import resourceAllocations from "../../assets/constants/ResourceAllocation";
import { replaceRoleNameWithAbbreviation } from "../../helpers/roles";
import {
  RoleSeniority,
  RoleSeniorityList,
} from "../../assets/constants/RoleSeniority";
import RoleProps from "../../types/role-props";

const { Text } = Typography;

const TeamState: FC<TeamStateProps> = ({
  name,
  resources: scenarioResources,
  velocity,
  weeksPerSprint,
  teamId,
  id,
  toggleNewStateVisibilityForm,
  order,
  teamName,
  teamScenarios,
  teamScenarioResources,
}) => {
  const {
    state: { roles, teams, teamScenarioResource, resources },
    dispatch,
  } = useAppContext();
  const [focused, setFocused] = useState(false);
  const [members, setMembers] = useState<ResourceWithRoleProps[]>(
    buildStateRoleWithRoleDTO(
      teamScenarioResources,
      scenarioResources,
      roles
    ) || []
  );
  const [cpsp, setCPSP] = useState<number>(0);
  const [showRemoveMemberConfirmation, setShowRemoveMemberConfirmation] =
    useState<boolean>(false);
  const [membersCount, setMembersCount] = useState<number>(0);
  const [showDeleteStateConfirmation, setShowDeleteStateConfirmation] =
    useState<boolean>(false);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [stateVelocity, setStateVelocity] = useState<number>(velocity);
  const [stateWeeksPerSprint, setStateWeeksPerSprint] =
    useState<number>(weeksPerSprint);
  const [stateName, setStateName] = useState<string>(name);
  const [customResourceAllocation, setCustomResourceAllocation] = useState<
    number | string
  >(1);
  const [selectedResource, setSelectedResource] = useState<{
    id: string;
    seniority: RoleSeniority;
    replacingId?: string;
  }>();
  const [isCustomResourceVisible, setIsCustomResourceVisible] =
    useState<boolean>(false);
  const [isContextMenuOpen, setContextMenuOpen] = useState(false);

  useEffect(() => {
    setCPSP(
      calculateCPSP(
        getScenarioMembersTotalSalary(members),
        stateVelocity,
        stateWeeksPerSprint * 12
      )
    );
  }, [members, roles, stateVelocity, stateWeeksPerSprint]);

  useEffect(() => {
    setMembers(
      buildStateRoleWithRoleDTO(teamScenarioResources, scenarioResources, roles)
    );
  }, [scenarioResources, roles, teamScenarioResources]);

  useEffect(() => {
    setMembersCount(scenarioResourcesAmount(teamScenarioResource, id));
  }, [id, teamScenarioResource]);

  useEffect(() => {
    if (members.length <= 0) {
      setStateVelocity(0);
    } else {
      setStateVelocity(velocity);
    }
    setStateName(name);
  }, [name, velocity, members]);

  const handleRemoveMember = useCallback(() => {
    selectedRoles.forEach((member) => {
      dispatch(removeTeamMemberAction(teamId, id, member));
    });
    setSelectedRoles([]);
    setShowRemoveMemberConfirmation(false);
  }, [dispatch, id, selectedRoles, teamId]);

  useEffect(() => {
    const listener = (event: { code: string; preventDefault: () => void }) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleRemoveMember();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleRemoveMember]);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlurName = () => {
    setFocused(false);
    dispatch(updateTeamStateField(teamId, id, "name", stateName));
  };

  const onChangeStateName = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setStateName(event.target.value);
  };

  const handleCloneStateBtnClick = () => {
    if (toggleNewStateVisibilityForm) {
      return toggleNewStateVisibilityForm(id);
    }
    return () => {};
  };

  const onBlurVelocity = () => {
    setFocused(false);
    dispatch(updateTeamStateField(teamId, id, "velocity", stateVelocity));
  };

  const onChangeStateVelocity = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(event.target.value);
    if (Number.isNaN(inputValue)) return;
    setStateVelocity(inputValue);
  };

  const onChangeStateWeeksPerSprint = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = Number(event.target.value);
    if (inputValue <= 0) return;
    setStateWeeksPerSprint(inputValue);
  };
  useEffect(() => {
    if (order === 0 && teamScenarioResource.length > 1) {
      dispatch(propagateStateChange("weeksPerSprint", stateWeeksPerSprint));
    }
  }, [dispatch, order, teamScenarioResource, stateWeeksPerSprint]);
  useEffect(() => {
    setStateWeeksPerSprint(weeksPerSprint);
  }, [weeksPerSprint]);

  const onBlurWeeksPerSprint = () => {
    setFocused(false);
  };

  const handleReplaceWithNumber8Click = (roleId: string) => {
    const areThereOtherSelected = selectedRoles.length > 0;
    if (areThereOtherSelected) {
      selectedRoles
        .filter((member) => member !== roleId)
        .forEach((member) => {
          dispatch(replaceRoleWithNumber8Action(member));
        });
      setSelectedRoles([]);
    }
    dispatch(replaceRoleWithNumber8Action(roleId));
  };

  const confirmRemoveMember = (roleId: string) => {
    const isAlreadySelected = selectedRoles.find((member) => member === roleId);
    if (!isAlreadySelected) {
      const newSelectedRoles = selectedRoles.concat(roleId);
      setSelectedRoles(newSelectedRoles);
    }
    setShowRemoveMemberConfirmation(true);
  };

  const handleReplaceRole = (memberId: string, roleId: string) => {
    const areThereOtherSelected = selectedRoles.length > 0;
    if (areThereOtherSelected) {
      selectedRoles
        .filter((member) => member !== roleId)
        .forEach((member) => {
          dispatch(replaceRoleWithRole8Action(member, roleId));
        });
      setSelectedRoles([]);
    }
    dispatch(replaceRoleWithRole8Action(memberId, roleId));
  };

  const addRoleToState = (
    roleId: string,
    seniority: RoleSeniority,
    resourceAllocation = 1,
    replacingRole = ""
  ) => {
    dispatch(
      addRoleToStateAction(
        teamId,
        id,
        roleId,
        seniority,
        resourceAllocation,
        replacingRole
      )
    );
  };

  const addRoleMenu = () => {
    const getSeniorityTitle = (
      role: RoleProps,
      seniority: RoleSeniority
    ): string => {
      return `${seniority[0].toUpperCase() + seniority.substr(1)} ($${
        role.annualSalary[seniority] / 1000
      }K)`;
    };
    const n8Role = roles.find((role) => role.showNumber8UserIcon);
    if (
      order !== Number(TeamValuesEnum.CURRENT_SCENARIO_ID) &&
      order !== Number(TeamValuesEnum.FUTURE_STATE_ID) &&
      n8Role
    ) {
      return (
        <Menu>
          {roles
            .filter((role) => !role.showNumber8UserIcon)
            .map((subRole) => {
              return (
                <Menu.SubMenu
                  key={subRole.id}
                  className="state-roles-n8-submenu"
                  data-cy={`addRole${subRole.id}ToState${order}`}
                  icon={
                    <UserIconAndName
                      color={subRole.color}
                      name={subRole.name}
                      showNumber8UserIcon={n8Role.showNumber8UserIcon}
                      style={{ height: 32 }}
                      iconSize={UserIconSizeEnum.SMALL}
                    />
                  }
                >
                  {RoleSeniorityList.map((roleSeniority) => (
                    <Menu.SubMenu
                      key={`${subRole.id}-${roleSeniority}`}
                      className="state-roles-n8-submenu"
                      title={getSeniorityTitle(subRole, roleSeniority)}
                      onTitleClick={() => {
                        addRoleToState(n8Role.id, roleSeniority, 1, subRole.id);
                        setContextMenuOpen(false);
                      }}
                      data-cy={`addRole${subRole.id}${roleSeniority}ToState${order}`}
                    >
                      {[...resourceAllocations, "Custom"].map(
                        (resourceAllocation) => (
                          <Menu.Item
                            key={`addRole${n8Role.id}-${subRole.id}-${resourceAllocation}`}
                            data-cy={`addRole${subRole.id}-${resourceAllocation}`}
                            onClick={() => {
                              setContextMenuOpen(false);
                              if (resourceAllocation === "Custom") {
                                setIsCustomResourceVisible(true);
                                setSelectedResource({
                                  id: n8Role.id,
                                  seniority: roleSeniority,
                                  replacingId: subRole.id,
                                });
                              } else {
                                addRoleToState(
                                  n8Role.id,
                                  roleSeniority,
                                  Number(resourceAllocation),
                                  subRole.id
                                );
                              }
                            }}
                          >
                            {resourceAllocation === "Custom"
                              ? "Custom"
                              : `${Number(resourceAllocation) * 100}%`}
                          </Menu.Item>
                        )
                      )}
                    </Menu.SubMenu>
                  ))}
                </Menu.SubMenu>
              );
            })}
        </Menu>
      );
    }
    return (
      <Menu>
        {roles.map((role) => {
          return role.showNumber8UserIcon ? (
            <Menu.SubMenu
              key={role.id}
              className="state-roles-n8-submenu"
              icon={
                <UserIcon
                  color={role.color}
                  showNumber8UserIcon={role.showNumber8UserIcon}
                  size={UserIconSizeEnum.SMALL}
                />
              }
              title={role.name}
            >
              {roles
                .filter((subRole) => !subRole.showNumber8UserIcon)
                .map((subRole) => {
                  return (
                    <Menu.SubMenu
                      key={`${role.id}-${subRole.id}`}
                      data-cy={`addRole${role.id}ToState${order}`}
                      className="state-roles-n8-submenu"
                      icon={
                        <UserIconAndName
                          color={subRole.color}
                          name={subRole.name}
                          showNumber8UserIcon={role.showNumber8UserIcon}
                          style={{ height: 32 }}
                          iconSize={UserIconSizeEnum.SMALL}
                        />
                      }
                    >
                      {RoleSeniorityList.map((roleSeniority) => (
                        <Menu.SubMenu
                          key={`${role.id}-${subRole.id}-${roleSeniority}`}
                          className="state-roles-n8-submenu"
                          title={getSeniorityTitle(subRole, roleSeniority)}
                          onTitleClick={() => {
                            addRoleToState(
                              role.id,
                              roleSeniority,
                              1,
                              subRole.id
                            );
                            setContextMenuOpen(false);
                          }}
                          data-cy={`addRole${subRole.id}${roleSeniority}ToState${order}`}
                        >
                          {[...resourceAllocations, "Custom"].map(
                            (resourceAllocation) => (
                              <Menu.Item
                                data-cy={`addRole${role.id}-${resourceAllocation}`}
                                key={`addRole${role.id}-${subRole.id}-${resourceAllocation}`}
                                onClick={() => {
                                  setContextMenuOpen(false);
                                  if (resourceAllocation === "Custom") {
                                    setIsCustomResourceVisible(true);
                                    setSelectedResource({
                                      id: role.id,
                                      seniority: roleSeniority,
                                      replacingId: subRole.id,
                                    });
                                  } else {
                                    addRoleToState(
                                      role.id,
                                      roleSeniority,
                                      Number(resourceAllocation),
                                      subRole.id
                                    );
                                  }
                                }}
                              >
                                {resourceAllocation === "Custom"
                                  ? "Custom"
                                  : `${Number(resourceAllocation) * 100}%`}
                              </Menu.Item>
                            )
                          )}
                        </Menu.SubMenu>
                      ))}
                    </Menu.SubMenu>
                  );
                })}
            </Menu.SubMenu>
          ) : (
            <Menu.SubMenu
              key={role.id}
              data-cy={`addRole${role.id}ToState${order}`}
              className="state-roles-n8-submenu"
              icon={
                <UserIconAndName
                  color={role.color}
                  name={role.name}
                  showNumber8UserIcon={role.showNumber8UserIcon}
                  style={{ height: 32 }}
                  iconSize={UserIconSizeEnum.SMALL}
                />
              }
            >
              {RoleSeniorityList.map((roleSeniority) => (
                <Menu.SubMenu
                  key={`${role.id}-${roleSeniority}`}
                  className="state-roles-n8-submenu"
                  title={getSeniorityTitle(role, roleSeniority)}
                  onTitleClick={() => {
                    addRoleToState(role.id, roleSeniority, 1);
                    setContextMenuOpen(false);
                  }}
                  data-cy={`addRole${role.id}${roleSeniority}ToState${order}`}
                >
                  {[...resourceAllocations, "Custom"].map(
                    (resourceAllocation) => (
                      <Menu.Item
                        key={`addRole${role.id}-${resourceAllocation}`}
                        data-cy={`addRole${role.id}-${resourceAllocation}`}
                        onClick={() => {
                          setContextMenuOpen(false);
                          if (!role.showNumber8UserIcon) {
                            if (resourceAllocation === "Custom") {
                              setIsCustomResourceVisible(true);
                              setSelectedResource({
                                id: role.id,
                                seniority: roleSeniority,
                              });
                            } else {
                              addRoleToState(
                                role.id,
                                roleSeniority,
                                Number(resourceAllocation)
                              );
                            }
                          }
                        }}
                      >
                        {resourceAllocation === "Custom"
                          ? "Custom"
                          : `${Number(resourceAllocation) * 100}%`}
                      </Menu.Item>
                    )
                  )}
                </Menu.SubMenu>
              ))}
            </Menu.SubMenu>
          );
        })}
      </Menu>
    );
  };

  const deleteState = useCallback(() => {
    dispatch(deleteStateAction(id, teamId));
  }, [dispatch, id, teamId]);

  useEffect(() => {
    if (showDeleteStateConfirmation) {
      const listener = (event: {
        code: string;
        preventDefault: () => void;
      }) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          event.preventDefault();
          deleteState();
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
    return undefined;
  }, [deleteState, showDeleteStateConfirmation]);

  const handleDeleteStateClick = () => {
    setShowDeleteStateConfirmation(true);
  };

  const handleMultiSelectRoles = (e: React.MouseEvent, memberId: string) => {
    if (e.ctrlKey || e.metaKey) {
      const isAlreadySelected = selectedRoles.find(
        (member) => member === memberId
      );
      if (isAlreadySelected) {
        const newSelectedRoles = selectedRoles.filter(
          (member) => member !== memberId
        );
        setSelectedRoles(newSelectedRoles);
      } else {
        const newSelectedRoles = selectedRoles.concat(memberId);
        setSelectedRoles(newSelectedRoles);
      }
    }
  };

  const teamMemberClassName = (memberId: string) => {
    const isSelected = selectedRoles.find((member) => member === memberId);
    return `role-icon ${isSelected ? "selectedRole" : ""}`;
  };

  const removeRoleText = () =>
    selectedRoles.length > 0
      ? `You’re about to delete ${selectedRoles.length} team member(s). Are you sure you want to proceed?`
      : "You’re about to delete a team member. Are you sure you want to proceed?";

  const saveCustomResourceAllocation = () => {
    if (selectedResource && typeof customResourceAllocation === "number") {
      addRoleToState(
        selectedResource.id,
        selectedResource.seniority,
        customResourceAllocation,
        selectedResource.replacingId
      );
      setIsCustomResourceVisible(false);
      setCustomResourceAllocation(1);
    }
  };

  const onChangeCustomResourceAllocation = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const numberValue = Number(value);
    if (numberValue) {
      setCustomResourceAllocation(
        (numberValue > 100 ? 100 : numberValue) / 100
      );
    }
  };

  const menu = (index: number, teamMember: ResourceWithRoleProps) => {
    const roleProp = teamMember.role;
    const teamScenarioOrder = order;
    const memberId = teamMember.id;
    const teamScenarioId = id;
    const renderDisabledName = () => (
      <Menu.Item key={roleProp?.id} disabled>
        {roleProp?.name}
      </Menu.Item>
    );
    if (
      teamScenarioOrder.toString() !== TeamValuesEnum.CURRENT_SCENARIO_ID &&
      teamScenarioOrder.toString() !== TeamValuesEnum.FUTURE_STATE_ID
    ) {
      return (
        <Menu>
          {isMobile && renderDisabledName()}
          <Menu.Item
            key="1"
            onClick={() => handleReplaceWithNumber8Click(memberId)}
            data-cy={`replaceState${teamScenarioOrder}Member${index}WithNumber8ContextMenuOption`}
            disabled={roleProp?.showNumber8UserIcon}
          >
            Replace with number8
          </Menu.Item>
          {roleProp?.showNumber8UserIcon && (
            <Menu.Item
              key="3"
              onClick={() => confirmRemoveMember(memberId)}
              data-cy={`removeState${teamScenarioOrder}Member${index}ContextMenuOption`}
            >
              Remove
            </Menu.Item>
          )}
        </Menu>
      );
    }
    return (
      <Menu>
        {isMobile && renderDisabledName()}
        <Menu.Item
          key="1"
          onClick={() => handleReplaceWithNumber8Click(memberId)}
          data-cy={`replaceState${teamScenarioOrder}Member${index}WithNumber8ContextMenuOption`}
          disabled={roleProp?.showNumber8UserIcon}
        >
          Replace with number8
        </Menu.Item>
        <SubMenu
          title="Replace with"
          key="2"
          data-cy="replaceWithAnotherRoleContextMenuOption"
        >
          {roles
            .filter((role) => !role.showNumber8UserIcon)
            .map((role) => (
              <Menu.Item
                key={`${teamScenarioId}-${memberId}-${role.id}`}
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => handleReplaceRole(memberId, role.id)}
                disabled={role.id === roleProp?.id}
                data-cy={`state${teamScenarioOrder}replace${roleProp?.id}WithAnotherRoleContextMenuOption${role.id}`}
              >
                <UserIconAndName
                  color={role.color}
                  name={role.name}
                  showNumber8UserIcon={role.showNumber8UserIcon}
                  style={{ height: 32 }}
                  iconSize={UserIconSizeEnum.SMALL}
                />
              </Menu.Item>
            ))}
        </SubMenu>
        <Menu.Item
          key="3"
          onClick={() => confirmRemoveMember(memberId)}
          data-cy={`removeState${teamScenarioOrder}Member${index}ContextMenuOption`}
        >
          Remove
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <StyledTeamState data-cy="state">
      <Row justify="space-between">
        {order.toString() === TeamValuesEnum.CURRENT_SCENARIO_ID && teamName ? (
          <Col data-cy="teamName">{teamName.toUpperCase()}</Col>
        ) : (
          <Col />
        )}
        <Col>
          <Row justify="end">
            <Col>
              <Tooltip title="Clone Scenario" className="cloneStateBtn">
                <Button
                  icon={<CopyOutlined />}
                  shape="circle"
                  onClick={handleCloneStateBtnClick}
                  data-cy={`cloneState${order}Btn`}
                  disabled={
                    teamScenarios.length >= 3 ||
                    order < teamScenarios.length - 1
                  }
                />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title="Delete Scenario">
                <Button
                  icon={<DeleteOutlined />}
                  title="Delete Scenario"
                  shape="circle"
                  onClick={handleDeleteStateClick}
                  data-cy={`deleteState${order}Btn`}
                  style={{ marginLeft: 10 }}
                  disabled={
                    order.toString() === TeamValuesEnum.CURRENT_SCENARIO_ID ||
                    order < teamScenarios.length - 1
                  }
                />
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={24}>
          <TeamScenarioName
            name={name}
            onChangeStateName={onChangeStateName}
            teamScenarioOrder={order}
            scenarioName={stateName}
            onFocus={onFocus}
            focused={focused}
            onBlurName={onBlurName}
            teamScenariosAmount={teamScenarios.length}
          />
        </Col>
      </Row>
      <Row className="velocity teamStateRow">
        <Col span={17}>
          <Text>Average Velocity: </Text>
        </Col>
        <Col span={7}>
          <Input
            value={stateVelocity < 0 ? 0 : stateVelocity}
            size="middle"
            data-cy={`state${order}VelocityInput`}
            type="text"
            onChange={onChangeStateVelocity}
            onBlur={onBlurVelocity}
            autoFocus
            disabled={order > 0 && order < teamScenarios.length - 1}
            style={{ textAlign: "right" }}
            onKeyDown={(e) => {
              const val = Number(e.key);
              const allowedKeys = [
                "ArrowRight",
                "ArrowLeft",
                "Tab",
                "Backspace",
              ];
              if (allowedKeys.includes(e.key)) return;
              if (Number.isNaN(val)) e.preventDefault();
            }}
          />
        </Col>
      </Row>
      <Row className="weeksPerSprint teamStateRow">
        <Col span={17}>
          <Text>Weeks Per Sprint:</Text>
        </Col>
        <Col span={7}>
          <Input
            value={stateWeeksPerSprint}
            size="middle"
            data-cy={`state${order}WeeksPerSprintInput`}
            type="number"
            onChange={onChangeStateWeeksPerSprint}
            onBlur={onBlurWeeksPerSprint}
            disabled={order > 0 && teamScenarios.length > 1}
          />
        </Col>
      </Row>
      <Row data-cy={`state${order}CPSP`} className="teamStateRow">
        <Col span={10} style={{ alignSelf: "center" }}>
          <Text className="cpsp-description">Cost Per Story Point: </Text>
        </Col>
        <Col span={8} offset={4}>
          <CPSPBubble
            value={cpsp === 0 ? "-" : cpsp.toString()}
            color={getTeamScenarioCPSPColor(
              resources,
              teamScenarios,
              teamScenarioResource,
              teams,
              roles,
              teamId,
              id
            )}
          />
        </Col>
      </Row>
      <Row className="teamStateRow">
        <Col span={24}>
          <Row>
            <Col span={14} style={{ alignSelf: "flex-end" }}>
              <Text>Team members:</Text>
            </Col>
            <Col span={4} offset={2} style={{ alignSelf: "flex-end" }}>
              <Text>{membersCount}</Text>
            </Col>
            <Col span={2} offset={1} style={{ alignSelf: "flex-end" }}>
              <ContextMenu
                setMenuOpen={setContextMenuOpen}
                isOpen={isContextMenuOpen}
                menu={addRoleMenu}
                trigger={
                  (order < teamScenarios.length - 1 &&
                    order.toString() === TeamValuesEnum.CURRENT_SCENARIO_ID) ||
                  order.toString() === TeamValuesEnum.FUTURE_STATE_ID
                    ? []
                    : [TriggerOptions.CLICK]
                }
              >
                <Tooltip
                  title={
                    order.toString() !== TeamValuesEnum.CURRENT_SCENARIO_ID &&
                    order.toString() !== TeamValuesEnum.FUTURE_STATE_ID
                      ? "Add new number8"
                      : "Add new team member"
                  }
                >
                  <Button
                    icon={<PlusOutlined />}
                    shape="circle"
                    data-cy={`addMembersToState${order}Btn`}
                    disabled={
                      (order < teamScenarios.length - 1 &&
                        order.toString() ===
                          TeamValuesEnum.CURRENT_SCENARIO_ID) ||
                      order.toString() === TeamValuesEnum.FUTURE_STATE_ID
                    }
                  />
                </Tooltip>
              </ContextMenu>
            </Col>
          </Row>
        </Col>
        <Row data-cy={`state${order}TeamMembers`} className="stateTeamMembers">
          {members &&
            members.slice(0, 20).map((teamMember, index) => (
              <ContextMenu
                menu={() => menu(index, teamMember)}
                key={teamMember.id}
                trigger={
                  (order < teamScenarios.length - 1 &&
                    order.toString() === TeamValuesEnum.CURRENT_SCENARIO_ID) ||
                  order.toString() === TeamValuesEnum.FUTURE_STATE_ID
                    ? []
                    : [
                        isMobile
                          ? TriggerOptions.CLICK
                          : TriggerOptions.CONTEXT_MENU,
                      ]
                }
              >
                <Col span={24} key={teamMember.id}>
                  <div
                    role="button"
                    onClick={(e) => handleMultiSelectRoles(e, teamMember.id)}
                    className={teamMemberClassName(teamMember.id)}
                    onKeyDown={() => {}}
                    tabIndex={index}
                    key={teamMember.id}
                  >
                    <div className="dot" />
                    <UserIcon
                      isMobile={isMobile}
                      color={teamMember.role?.color || ""}
                      showNumber8UserIcon={
                        teamMember.role?.showNumber8UserIcon || false
                      }
                      seniority={teamMember.seniority}
                      key={teamMember.id}
                      name={teamMember.role?.name}
                      resourceAllocation={teamMember.resourceAllocation}
                      withResourceAllocationShadow={
                        teamMember.resourceAllocation < 1
                      }
                    />
                    {teamMember.role && (
                      <div className="member-captions">
                        <div>
                          {replaceRoleNameWithAbbreviation(
                            teamMember.role
                          ).name.replace("number8", "")}
                        </div>
                        <div className="second-row">
                          {teamMember.role.showNumber8UserIcon && (
                            <div>
                              <img
                                src={Number8Icon}
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  marginTop: "-2px",
                                }}
                                className="n8-icon"
                                alt="number8-logo"
                              />
                            </div>
                          )}
                          {teamMember.resourceAllocation < 1 && (
                            <div>{teamMember.resourceAllocation * 100}%</div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </ContextMenu>
            ))}
        </Row>
      </Row>
      {showRemoveMemberConfirmation && (
        <Modal
          visible
          data-cy="deleteMemberConfirmationModal"
          onCancel={() => setShowRemoveMemberConfirmation(false)}
          footer={[
            <Button
              key="back"
              onClick={() => setShowRemoveMemberConfirmation(false)}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleRemoveMember}
              data-cy="deleteMemberConfirmationBtn"
              autoFocus
            >
              Delete
            </Button>,
          ]}
        >
          {removeRoleText()}
        </Modal>
      )}
      {showDeleteStateConfirmation && (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={({ code }) => {
            if (code === "Enter") {
              deleteState();
            }
          }}
        >
          <Modal
            visible
            data-cy="deleteStateConfirmationModal"
            onCancel={() => setShowDeleteStateConfirmation(false)}
            footer={[
              <Button
                key="back"
                onClick={() => setShowDeleteStateConfirmation(false)}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={deleteState}
                data-cy="deleteStateConfirmationBtn"
              >
                Delete
              </Button>,
            ]}
          >
            You’re going to delete this scenario, are you sure?
          </Modal>
        </div>
      )}
      {isCustomResourceVisible && (
        <div>
          <Modal
            visible
            title="Custom Resource Allocation"
            onCancel={() => setIsCustomResourceVisible(false)}
            onOk={saveCustomResourceAllocation}
          >
            <Input
              onBlur={onChangeCustomResourceAllocation}
              max={100}
              min={0}
              suffix="%"
              defaultValue={100}
              title="If greater than 100, it'll default to 100. If less than 0, it'll default to 0."
            />
          </Modal>
        </div>
      )}
    </StyledTeamState>
  );
};

TeamState.defaultProps = {
  toggleNewStateVisibilityForm: () => {},
};

TeamState.propTypes = {
  name: PropTypes.string.isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      roleId: PropTypes.string.isRequired,
      seniority: PropTypes.oneOf(RoleSeniorityList).isRequired,
    }).isRequired
  ).isRequired,
  velocity: PropTypes.number.isRequired,
  weeksPerSprint: PropTypes.number.isRequired,
  teamId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  toggleNewStateVisibilityForm: PropTypes.func,
  order: PropTypes.number.isRequired,
  teamName: PropTypes.string.isRequired,
};
export default TeamState;
