import styled from "styled-components";

const StyledAvailableResources = styled.div`
  border: 1px #eee7e7 solid;
  width: 156px;
  height: 222px;
  font-size: 8px;
  .banner {
    background-color: #eee7e7;
    margin: 4px;
    border-radius: 50px;
    height: 15px;
    padding-left: 5px;
    padding-top: 3px;
  }
  .resources {
    height: 175px;
    > .ant-row {
      margin: 3px 8px;
    }
  }
  .summary {
    margin: 8px;
  }
`;

export default StyledAvailableResources;
