const colors = [
  "Gold",
  "Yellow",
  "Orange",
  "DarkOrange",
  "LightSalmon",
  "Coral",
  "Tomato",
  "OrangeRed",
  "Goldenrod",
  "DarkGoldenrod",
  "Peru",
  "Chocolate",
  "SaddleBrown",
  "Sienna",
  "Brown",
  "Maroon",
  "IndianRed",
  "LightCoral",
  "Salmon",
  "DarkSalmon",
  "LightSalmon",
  "Crimson",
  "Red",
  "FireBrick",
  "Pink",
  "LightPink",
  "HotPink",
  "Magenta",
  "PaleVioletRed",
  "DeepPink",
  "MediumVioletRed",
  "DarkMagenta",
  "MediumSlateBlue",
  "DeepSkyBlue",
  "CornflowerBlue",
  "DodgerBlue",
  "RoyalBlue",
  "Blue",
  "MediumBlue",
  "DarkBlue",
  "#f16823",
  "LimeGreen",
  "PaleGreen",
  "LightGreen",
  "MediumSpringGreen",
  "SpringGreen",
  "MediumSeaGreen",
  "SeaGreen",
  "ForestGreen",
  "Silver",
  "DarkGray",
  "Gray",
  "DimGray",
  "LightSlateGray",
  "SlateGray",
  "DarkSlateGray",
  "Black",
];

export default colors;
