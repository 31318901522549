import styled from "styled-components";

const StyledRolesPanel = styled.div`
  display: grid;
  grid-template-rows: 50px 26px 30px auto;
  padding: 25px 15px 30px 10px;
  height: 95%;
  position: sticky;
  .title {
    font-size: 19px;
    font-weight: bold;
  }
  .titleBtns {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }
`;
export const StyledRolesPanelMain = styled.div`
  overflow-y: auto;
  height: 65vh;
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 100%;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    height: 550px;
  }
`;

export const StyledClosedRolesPanel = styled.div`
  height: 72px;
  display: flex;
  align-items: center;
  width: 50px;
`;

export default StyledRolesPanel;
