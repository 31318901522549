import styled from "styled-components";

const StyledRoleName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 2px;
  display: flex;
  height: 100%;
  align-items: center;
`;

export default StyledRoleName;
