import React, { FC, useEffect, useState } from "react";
import { Typography, Row, Col } from "antd";
import { useAppContext } from "../../Context/context";
import { getCPSPColorCode } from "../../helpers/calculateCPSP";
import { calculateAvailableResources } from "../../helpers/teams";
import {
  buildStateRoleWithRoleDTO,
  getScenarioMembersTotalSalary,
} from "../../helpers/scenarioResources";
import StyledMetricsState from "./StyledMetricsState";
import VarianceCost from "./VarianceCost";

const { Title } = Typography;
const reducer = (accumulator: number, currentValue: number) =>
  accumulator + currentValue;

const MetricsState: FC = () => {
  const {
    state: { teams, roles, teamScenario, teamScenarioResource, resources },
  } = useAppContext();
  const [currentStateTotal, setCurrentStateTotal] = useState<number>(0);
  const [futureStateTotal, setFutureStateTotal] = useState<number>(0);
  const [currentCost, setCurrentCost] = useState<number>(0);
  const [futureCost, setFutureCost] = useState<number>(0);
  const [currentHeadCount, setCurrentHeadCount] = useState<number>(0);
  const [futureHeadCount, setFutureHeadCount] = useState<number>(0);
  const [newVelocity, setNewVelocity] = useState<number>(0);
  const [newHeadCount, setNewHeadCount] = useState<number>(0);

  useEffect(() => {
    const teamDiff = calculateAvailableResources(
      teams,
      teamScenario,
      teamScenarioResource,
      resources,
      roles
    );
    const currentTeamSizeCalc = teamDiff[0]
      .map(
        (resource) =>
          resource.amount.junior + resource.amount.mid + resource.amount.senior
      )
      .reduce(reducer);
    setCurrentHeadCount(currentTeamSizeCalc);
    // Start New Teams //
    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
    /* eslint-disable @typescript-eslint/no-explicit-any */
    let newTeamsVelocity = 0;
    const benchTeams: any[] = [];
    teams.map((team) => {
      const teamKeys = Object.keys(team);
      if (teamKeys[0] === "name") {
        benchTeams.push(team);
      }
      return benchTeams;
    });
    let newMembers = 0;
    benchTeams
      .filter(
        (team) =>
          teamScenario.filter((scenario) => scenario.teamId === team.id)
            .length > 1
      )
      .map((team) => ({
        team,
        scenario: teamScenario
          .filter((scenario) => scenario.teamId === team.id)
          .pop(),
      }))
      .map(({ scenario }) => {
        for (let index = 0; index < teamScenarioResource.length; index++) {
          if (scenario?.id === teamScenarioResource[index].scenarioId) {
            newMembers += 1;
          }
        }
        if (scenario?.velocity) {
          newTeamsVelocity += scenario?.velocity;
        }
        return setNewVelocity(newTeamsVelocity);
      });
    setNewHeadCount(newMembers);
    // End New Teams //
  }, [teams, roles, teamScenario, teamScenarioResource, resources]);

  useEffect(() => {
    const teamsToShow = teams.filter((team) =>
      teamScenario.filter((scenario) => scenario.teamId === team.id)
    );
    let currentVariance = 0;
    let currentCostTemp = 0;
    let futureVariance = 0;
    let futureCostTemp = 0;
    teamsToShow.map((team) => {
      const currentScenario = teamScenario.find(
        (scenario) => scenario.teamId === team.id && scenario.teamOrder === 0
      );
      if (!currentScenario) return 0;
      const currentScenarioResources =
        resources.filter((resource) =>
          teamScenarioResource
            ?.filter(
              (scenarioResource) =>
                scenarioResource.scenarioId === currentScenario.id
            )
            .map((scenarioResource) => scenarioResource.resourceId)
            .includes(resource.id)
        ) || [];
      const members = buildStateRoleWithRoleDTO(
        teamScenarioResource.filter(
          (scenarioResource) =>
            scenarioResource.scenarioId === currentScenario.id
        ),
        currentScenarioResources,
        roles
      );
      currentCostTemp += getScenarioMembersTotalSalary(members);
      if (currentScenario.velocity) {
        currentVariance += currentScenario.velocity;
      }
      return setCurrentStateTotal(currentVariance);
    });
    setCurrentCost(currentCostTemp);
    teamsToShow.map((team) => {
      const teamScenarios = teamScenario.filter(
        (scenario) => scenario.teamId === team.id
      );
      const withN8Scenario = teamScenarios[teamScenarios.length - 1];
      if (!withN8Scenario) {
        return 0;
      }

      const withN8ScenarioResources =
        resources.filter((resource) =>
          teamScenarioResource
            ?.filter(
              (scenarioResource) =>
                scenarioResource.scenarioId === withN8Scenario.id
            )
            .map((scenarioResource) => scenarioResource.resourceId)
            .includes(resource.id)
        ) || [];
      const members = buildStateRoleWithRoleDTO(
        teamScenarioResource.filter(
          (scenarioResource) =>
            scenarioResource.scenarioId === withN8Scenario.id
        ),
        withN8ScenarioResources,
        roles
      );
      futureCostTemp += getScenarioMembersTotalSalary(members);
      return setFutureCost(futureCostTemp);
    });
    // Start With #8 Variance //
    let futureMembers = 0;
    teams
      .filter(
        (team) =>
          teamScenario.filter((scenario) => scenario.teamId === team.id)
            .length > 1
      )
      .map((team) => ({
        team,
        scenario: teamScenario
          .filter((scenario) => scenario.teamId === team.id)
          .pop(),
      }))
      .map(({ scenario }) => {
        for (let index = 0; index < teamScenarioResource.length; index++) {
          if (scenario?.id === teamScenarioResource[index].scenarioId) {
            futureMembers += 1;
          }
        }
        if (scenario?.velocity) {
          futureVariance += scenario?.velocity;
        }
        return setFutureStateTotal(futureVariance);
      });
    setFutureHeadCount(futureMembers);
    if (futureMembers === 0) {
      setFutureCost(0);
    }
    // End With #8 Variance //
  }, [resources, roles, teamScenario, teamScenarioResource, teams]);
  return (
    <StyledMetricsState>
      <Row className="tab-headers">
        <h5 className="metrics-title">Productivity Metrics</h5>
        <Col span={24}>
          <Row>
            <Col span={12} offset={4} className="metrics-stats-headers">
              <Row>
                <Col span={8}>
                  <Title level={5} className="column-header">
                    Current
                  </Title>
                  <div className="sub-title">scenario</div>
                </Col>
                <Col span={8} className="n8 n8Col">
                  <Title level={5}>With number8</Title>
                  <div className="sub-title">scenario</div>
                </Col>
                <Col span={8}>
                  <Title level={5} className="column-header">
                    Variances
                  </Title>
                  <div className="sub-title">scenario</div>
                </Col>
              </Row>
            </Col>
            <Col span={7} offset={1} className="new-teams-header">
              <Title level={5} className="column-header">
                New Teams
              </Title>
              <div className="sub-title">
                (created from available resources)
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={4}>
          <Row>
            <Col span={24} className="left-title first">
              <Title level={5} className="column-header">
                Velocity
              </Title>
              <div className="sub-title">(Story points)</div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="left-title">
              <Title level={5} className="column-header">
                Head count
              </Title>
              <div className="sub-title">(Number of people)</div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="left-title last-title">
              <Title level={5} className="column-header">
                Cost
              </Title>
              <div className="sub-title">(Salary * HC)</div>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row className="first-row">
            <Col span={8} className="metrics-values">
              <Title level={5}>{currentStateTotal} pts</Title>
            </Col>
            <Col span={8} className="n8Col metrics-values">
              <Title level={5}>{futureStateTotal} pts</Title>
            </Col>
            <Col span={8} className="metrics-values">
              <Title level={5}>
                {currentStateTotal - futureStateTotal} pts
              </Title>
            </Col>
          </Row>
          <Row>
            <Col span={8} className="metrics-values">
              <Title level={5}>{currentHeadCount}</Title>
            </Col>
            <Col span={8} className="n8Col metrics-values">
              <Title level={5}>{futureHeadCount}</Title>
            </Col>
            <Col span={8} className="metrics-values">
              <Title level={5}>{currentHeadCount - futureHeadCount}</Title>
            </Col>
          </Row>
          <Row>
            <Col span={8} className="metrics-values">
              <Title level={5}>${currentCost / 1000}K</Title>
            </Col>
            <Col
              span={8}
              className="n8Col metrics-values"
              data-cy="futureStateVariance"
            >
              <Title level={5}>
                <VarianceCost
                  variance={futureCost}
                  varianceColor={getCPSPColorCode(currentCost, futureCost)}
                />
              </Title>
            </Col>
            <Col span={8} className="metrics-values">
              <Title level={5}>
                <VarianceCost
                  variance={currentCost - futureCost}
                  varianceColor={getCPSPColorCode(currentCost, futureCost)}
                />
              </Title>
            </Col>
          </Row>
        </Col>
        <Col span={7} offset={1}>
          <Row className="first-row">
            <Col span={24} className="metrics-values">
              <Title level={5}>{newVelocity} pts</Title>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="metrics-values">
              <Title level={5}>{newHeadCount}</Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledMetricsState>
  );
};

export default MetricsState;
