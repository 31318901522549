import React, { FC } from "react";
import PropTypes from "prop-types";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Input, Col, Row } from "antd";
import UserIcon from "../UserIcon/UserIcon";
import StyledEditRoleRow from "./StyledEditRoleRow";
import EditRoleRowProps from "../../types/edit-role-row-props";
import UserIconSizeEnum from "../../assets/constants/UserIconSizeEnum";
import RoleFieldsEnum from "../../assets/constants/RoleFieldsEnum";
import { RoleSeniorityList } from "../../assets/constants/RoleSeniority";
import { RoleSalary } from "../../types/role-props";
import { replaceRoleNameWithAbbreviation } from "../../helpers/roles";

const EditRoleRow: FC<EditRoleRowProps> = ({
  annualSalary,
  name,
  abbreviation,
  color,
  showNumber8UserIcon,
  id,
  deleteRole,
  updateRole,
  isReadOnly,
}) => {
  const updateRoleSalary = (roleSeniority: string, value: string) => {
    const newSalary: RoleSalary = {
      ...annualSalary,
      [roleSeniority]: Number(value) * 1000,
    };
    updateRole(id, RoleFieldsEnum.ANNUAL_SALARY, newSalary);
  };
  return (
    <StyledEditRoleRow key={id} data-cy={`editRoleItem-${id}`}>
      <Row align="middle" justify="space-between">
        <Col span={1}>
          <UserIcon
            color={color}
            showNumber8UserIcon={showNumber8UserIcon}
            size={UserIconSizeEnum.MEDIUM}
          />
        </Col>
        <Col span={4}>
          <Input
            placeholder="Role Name"
            value={name}
            onChange={({ target: { value } }) => {
              updateRole(id, RoleFieldsEnum.NAME, value);
            }}
            className="editRoleNameInput"
            data-cy={`editRoleNameInput-${id}`}
          />
        </Col>
        <Col span={4}>
          <Input
            placeholder={
              replaceRoleNameWithAbbreviation({
                name,
                color,
                annualSalary,
                id,
                showNumber8UserIcon,
                isReadOnly,
              }).name || "Role Abbreviation"
            }
            value={abbreviation}
            onChange={({ target: { value } }) => {
              updateRole(id, RoleFieldsEnum.ABBREVIATION, value);
            }}
            className="editRoleAbbreviationInput"
            data-cy={`editRoleAbbreviationInput-${id}`}
          />
        </Col>
        {RoleSeniorityList.map((roleSeniority) => (
          <Col span={4}>
            <Input
              placeholder="Annual Salary"
              value={annualSalary[roleSeniority] / 1000}
              type="number"
              prefix="$"
              onChange={({ target: { value } }) => {
                updateRoleSalary(roleSeniority, value);
              }}
              data-cy={`edit${roleSeniority}RoleAnnualSalaryInput-${id}`}
              suffix="K"
            />
          </Col>
        ))}
        <Col span={2}>
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            className="deleteRoleBtn"
            onClick={() => deleteRole(id, color)}
            data-cy={`deleteRoleBtn-${id}`}
            disabled={isReadOnly}
          />
        </Col>
      </Row>
    </StyledEditRoleRow>
  );
};

EditRoleRow.propTypes = {
  annualSalary: PropTypes.shape({
    junior: PropTypes.number.isRequired,
    mid: PropTypes.number.isRequired,
    senior: PropTypes.number.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  showNumber8UserIcon: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  deleteRole: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default EditRoleRow;
