import styled from "styled-components";
import StyledAppProps from "./types/styled-app-props";

const StyledApp = styled.main<StyledAppProps>`
  display: grid;
  height: calc(100vh - 200px);
  grid-row: 1;
  margin-right: 20px;
  grid-template-columns: ${(props) =>
    props.fullContent ? "98% 2%" : "auto 390px"};
  min-width: 750px;
  > .rightSide {
    display: grid;
    width: 390px;
    > .ant-row > .ant-col {
      > .ant-divider {
        height: 80px;
      }
    }
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 900px;
    width: 770px;
    grid-template-columns: ${(props) =>
      props.fullContent ? "94% 6%" : "auto 360px"};
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    height: 665px;
    width: 995px;
  }
`;

export default StyledApp;
