import styled from "styled-components";

const StyledTeamTab = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  overflow-x: auto;
  width: calc(90vw - 250px);
  height: calc(94vh - 220px);
  > .teamState {
    display: flex;
    flex-direction: row;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    width: calc(90vw - 232px);
    height: 670px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    width: calc(90vw - 145px);
    height: 640px;
  }
  .currentScenario {
    width: 427px !important;
    margin-left: 60px;
  }
  .currentScenarioTwo {
    width: 427px !important;
    margin-left: 60px;
  }
  .teamState.currentScenario > div:first-child {
    width: 100%;
  }
  .teamState.currentScenarioTwo > div:first-child {
    width: 100%;
  }
`;

export default StyledTeamTab;
