/* eslint-disable no-debugger */
import { useEffect, useRef } from "react";

type BeforeunloadHandler = (evt: BeforeUnloadEvent) => void;

export function useBeforeunload(
  handler: BeforeunloadHandler,
  isSaved: boolean
): void {
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    if (isSaved) {
      return () => {};
    }
    const handleBeforeunload: BeforeunloadHandler = (evt) => {
      let returnValue;

      if (typeof handlerRef.current === "function") {
        returnValue = handlerRef.current(evt);
      }

      if (evt.defaultPrevented) {
        const event = evt;
        event.returnValue = "";
        localStorage.setItem("cpsp-state", "");
      }

      if (typeof returnValue === "string") {
        const event = evt;
        event.returnValue = returnValue;
        return returnValue;
      }
      return returnValue;
    };

    window.addEventListener("beforeunload", handleBeforeunload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, [isSaved]);
}
