import styled from "styled-components";

const StyledAssignToTeamModal = styled.div`
  > .resources-titles {
    > .resources-titles-seniority {
      text-align: center;
    }
  }
  .ant-row:not(.role) {
    margin: 7px 0;
  }
  .assignAllBtn {
    width: 147px;
  }
  .teamNameLbl {
    font-size: 16px;
  }
`;

export default StyledAssignToTeamModal;
