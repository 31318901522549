import styled from "styled-components";
import CPSPBubbleSizeEnum, {
  CPSPBubbleFontSizeEnum,
} from "../../assets/constants/CPSPSizeEnum";
import StyledCPSPBubbleProps from "../../types/styled-cpsp-bubble-props";

const getCPSPFontSize = (
  bubbleSize: CPSPBubbleSizeEnum
): CPSPBubbleFontSizeEnum => {
  if (bubbleSize === CPSPBubbleSizeEnum.LARGE) {
    return CPSPBubbleFontSizeEnum.LARGE;
  }
  if (bubbleSize === CPSPBubbleSizeEnum.SMALL) {
    return CPSPBubbleFontSizeEnum.SMALL;
  }
  return CPSPBubbleFontSizeEnum.EXTRA_SMALL;
};

const StyledCPSPBubble = styled.div<StyledCPSPBubbleProps>`
  color: ${(props) => props.color};
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};
  background-color: ${(props) => props.backgroundColor || "#e6e6e6"};
  border-radius: 50%;
  display: inline-block;
  text-justify: center;
  text-align: center;
  line-height: ${(props) => `${props.size}px`};
  font-size: ${(props) => `${getCPSPFontSize(props.size)}px`};
`;

export default StyledCPSPBubble;
