import React, { FC } from "react";
import PropTypes from "prop-types";
import { UserIconAndNameProps } from "../../types/user-icon-and-name-props";
import UserIcon from "../UserIcon/UserIcon";
import StyledUserIconAndName from "./StyledUserIconAndName";

const UserIconAndName: FC<UserIconAndNameProps> = ({
  color,
  name,
  showNumber8UserIcon,
  style,
  iconSize,
}) => {
  return (
    <StyledUserIconAndName height={style?.height}>
      <UserIcon
        color={color}
        showNumber8UserIcon={showNumber8UserIcon}
        size={iconSize}
      />
      <div className="userIconName">{name}</div>
    </StyledUserIconAndName>
  );
};

UserIconAndName.propTypes = {
  color: PropTypes.string.isRequired,
  showNumber8UserIcon: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  style: PropTypes.shape({ height: PropTypes.number.isRequired }).isRequired,
  iconSize: PropTypes.oneOf([50, 30]).isRequired,
};

export default UserIconAndName;
