import styled from "styled-components";

const StyledResourceView = styled.div`
  margin-top: 80px;
  margin-right: 10px;
  font-size: 12px;
  > .roles {
    margin-top: 5px;
    background: #eee7e7;
    height: 570px;
    font-size: 10px;
    padding-right: 10px;
    color: #595555;
    .n8-icon {
      position: absolute;
      top: 40%;
      left: 37%;
    }
  }
`;

export default StyledResourceView;
