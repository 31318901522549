import styled from "styled-components";

const StyledFutureState = styled.div`
  .states {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 69vh;
    .stateName {
      word-break: break-all;
    }
    .futureStateTeam {
      width: 300px;
    }
    @media screen and (min-width: 1400px) {
      height: 80vh;
    }
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 1024px;
  }
`;

export default StyledFutureState;
