import React, { FC } from "react";
import { Redirect } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { OktaAuthOptions, Tokens } from "@okta/okta-auth-js";
import OktaSignInWidget from "./OktaSignInWidget";
import OktaLoginEnum from "../../assets/constants/OktaLoginEnum";

const Login: FC<{ config: OktaAuthOptions }> = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const onSuccess = (tokens: Tokens) => {
    oktaAuth.handleLoginRedirect(tokens);
  };

  const behaviour = () => {
    if (authState && authState.isAuthenticated) {
      return <Redirect to={{ pathname: OktaLoginEnum.MAIN_PATH }} />;
    }
    return (
      <OktaSignInWidget
        config={config}
        onSuccess={onSuccess}
        onError={() => null}
      />
    );
  };
  return behaviour();
};
export default Login;
