import styled from "styled-components";
import StyledNumber8UserIconProps from "../../types/styled-number8-user-icon-props";

const StyledNumber8User = styled.div<StyledNumber8UserIconProps>`
  position: relative;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  > .standing-user-icon-n8 {
    display: block;
    max-width: 100%;
    height: auto;
    position: absolute;
  }
  > .n8-icon {
    position: absolute;
    top: ${(props) =>
      props.height === "50px" || props.height === "40px" ? "39%" : "39%"};
    left: ${(props) =>
      props.height === "50px" || props.height === "40px" ? "34%" : "40%"};
  }
  > .shadow {
    width: ${(props) => props.width || "50px"};
    position: absolute;
    height: ${(props) => props.width || "50px"};
    transform: rotate(-90deg);
    background: transparent;
    border-radius: 50%;
    opacity: 50%;
    > circle {
      fill: transparent;
      stroke: #666;
      stroke-width: 32;
      stroke-dasharray: ${(props) => props.resourceAllocation * 100} 100;
      stroke-opacity: 25%;
    }
  }
`;

const StyledUserIcon = styled.div<{
  resourceAllocation: number;
  height?: string;
  width?: string;
}>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  > svg {
    position: absolute;
  }
  > .shadow {
    width: ${(props) => props.height || "50px"};
    height: ${(props) => props.height || "50px"};
    transform: rotate(-90deg);
    background: transparent;
    border-radius: 50%;
    opacity: 50%;
    > circle {
      fill: transparent;
      stroke-width: 32;
    }
  }
  > .completeAvatar {
    > circle {
      stroke: #666;
      stroke-dasharray: ${(props) => props.resourceAllocation * 100} 100;
      stroke-opacity: 25%;
    }
  }
  > .incompleteAvatar {
    > circle {
      stroke: #fff;
      stroke-dasharray: 0 ${(props) => props.resourceAllocation * 100} 100;
      stroke-opacity: 100%;
    }
  }
`;

export { StyledUserIcon };

export default StyledNumber8User;
