import { Col } from "antd";
import styled from "styled-components";

const StyledStateSummaryCol = styled(Col)`
  align-self: flex-end;
  > span {
    overflow-wrap: normal;
  }
`;

export default StyledStateSummaryCol;
