enum TeamScenarioOperationsEnum {
  CLONE_SCENARIO,
  DELETE_SCENARIO,
  ADD_ROLE_TO_SCENARIO,
  ADD_N8_ROLE_TO_SCENARIO,
  REPLACE_ROLE,
  REPLACE_WITH_N8,
  REMOVE_RESOURCE,
  REMOVE_N8_RESOURCE,
}
const operationsAllowedWhenTheScenarioIsTheLast = [
  TeamScenarioOperationsEnum.CLONE_SCENARIO,
  TeamScenarioOperationsEnum.DELETE_SCENARIO,
];

const operationsAllowedWhenThereIs1Scenario = [
  TeamScenarioOperationsEnum.ADD_ROLE_TO_SCENARIO,
  TeamScenarioOperationsEnum.REMOVE_RESOURCE,
];

const operationsAllowedOnlyOnFirstAndSecondScenario = [
  TeamScenarioOperationsEnum.ADD_N8_ROLE_TO_SCENARIO,
];

export {
  operationsAllowedWhenTheScenarioIsTheLast,
  operationsAllowedWhenThereIs1Scenario,
  operationsAllowedOnlyOnFirstAndSecondScenario,
};

export default TeamScenarioOperationsEnum;
