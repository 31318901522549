import React, { FC, useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import RoleAmountProps from "../../types/role-amount-props";
import TeamsCurrentAndFutureMembersProps from "../../types/teams-current-and-future-members-props";
import StyledAvailableResources from "./StyledAvailableResources";
import { replaceRoleNameWithAbbreviation } from "../../helpers/roles";

const { Text } = Typography;

const reducer = (accumulator: number, currentValue: number) =>
  accumulator + currentValue;

const MAX_AMOUNT = 12;

const AvailableResources: FC<{
  resources: TeamsCurrentAndFutureMembersProps;
}> = ({ resources }) => {
  const [availableResources, setAvailableResources] = useState<
    RoleAmountProps[]
  >([]);
  const [metaTeamSize, setMetaTeamSize] = useState<number>(0);
  useEffect(() => {
    if (resources.length > 0) {
      setAvailableResources(resources[2]);
      const size = resources[2]
        .map(
          (resource) =>
            resource.amount.junior +
            resource.amount.mid +
            resource.amount.senior
        )
        .reduce(reducer);
      setMetaTeamSize(size);
    }
  }, [resources]);
  return (
    <StyledAvailableResources>
      <div className="banner">Available Resources</div>
      <div className="resources">
        <Row>
          <Col span={5} offset={9}>
            <Text strong>Junior</Text>
          </Col>
          <Col span={3} offset={1}>
            <Text strong>Mid</Text>
          </Col>
          <Col span={5} offset={1}>
            <Text strong>Senior</Text>
          </Col>
        </Row>
        {availableResources.slice(0, MAX_AMOUNT - 1).map((role) => (
          <Row>
            <Col span={7}>
              {replaceRoleNameWithAbbreviation(role.role).name}
            </Col>
            <Col offset={2}>x {role.amount.junior.toFixed(2)}</Col>
            <Col offset={2}>{role.amount.mid.toFixed(2)}</Col>
            <Col offset={1}>{role.amount.senior.toFixed(2)}</Col>
          </Row>
        ))}
        {availableResources.length >= MAX_AMOUNT && (
          <Row>
            <Col span={20}>Others</Col>
            <Col>
              x{" "}
              {availableResources
                .slice(MAX_AMOUNT)
                .map(
                  (resource) =>
                    resource.amount.junior +
                    resource.amount.mid +
                    resource.amount.senior
                )
                .reduce(reducer, 0)
                .toFixed(2)}
            </Col>
          </Row>
        )}
      </div>
      <Row className="summary">
        <Col>Total freed up resources: {metaTeamSize.toFixed(2)}</Col>
      </Row>
    </StyledAvailableResources>
  );
};
export default AvailableResources;
