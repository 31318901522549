import React, { createContext, FC, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import initialConfig from "../assets/data/initialConfig.json";
import ContextProps, { State } from "../types/context-props";
import ConfigProps from "../types/context-config-props";
import reducer from "./reducer";
import TeamValuesEnum from "../assets/constants/TeamValuesEnum";
import { sortRoles } from "../helpers/roles";
import { ProTipsType } from "../types/pro-tips";
import colors from "../assets/data/colors";

const AppContext = createContext<ContextProps | undefined>(undefined);

function useAppContext(): ContextProps {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
}
const AppContextProvider: FC<ConfigProps> = ({ children }) => {
  const dataFromLocalStorage = localStorage.getItem("cpsp-state");
  const parsedLocalStorage = JSON.parse(dataFromLocalStorage || "{}");
  const initialContext: State = {
    roles: sortRoles(initialConfig.roles),
    colors: colors.filter(
      (color: string) => !initialConfig.roles.find((r) => r.color === color)
    ),
    teams: initialConfig.teams,
    selectedTeam: TeamValuesEnum.DEFAULT_TEAM_ID,
    resources: initialConfig.resources,
    teamScenario: initialConfig.teamScenario,
    teamScenarioResource: initialConfig.teamScenarioResource,
    isSaved: true,
    showProTips: {
      [ProTipsType.multiSelect]: true,
      [ProTipsType.changeLogo]: true,
    },
    ...parsedLocalStorage,
  };
  const [state, dispatch] = useReducer(reducer, {
    ...initialContext,
    history: [initialContext],
  });
  const value = { state, dispatch };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppContextProvider, useAppContext };

export default AppContext;
