import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";
import { isMobile } from "react-device-detect";
import StyledProTip from "./StyledProTip";
import { useAppContext } from "../../Context/context";
import { setShowProTips } from "../../Context/actions";
import { ProTipProps } from "../../types/pro-tips";

const ProTip = ({
  message,
  type,
  className = "pro-tip",
}: ProTipProps): JSX.Element => {
  const {
    dispatch,
    state: { showProTips },
  } = useAppContext();

  const onCloseProTip = (): void => {
    dispatch(setShowProTips({ ...showProTips, [type]: false }));
  };

  const ProTipMessage = ({ text }: Record<string, string>): JSX.Element => {
    return (
      <span>
        <b>Pro Tip: </b>
        {text}
      </span>
    );
  };

  return (
    <StyledProTip className={className}>
      {showProTips?.[type] && !isMobile && (
        <Alert
          message={<ProTipMessage text={message} />}
          onClose={onCloseProTip}
          closable
        />
      )}
    </StyledProTip>
  );
};

ProTip.defaultProps = {
  className: "pro-tip",
};

ProTip.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ProTip;
