const TEAM_STATES_SUGGESTIONS = [
  "With number8",
  "With number8, after team normalization",
];

const DEFAULT_TEAM_SUGGESTION = "State";

export { DEFAULT_TEAM_SUGGESTION };

export default TEAM_STATES_SUGGESTIONS;
