/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Tabs, TabsProps } from "antd";
import { useAppContext } from "../../Context/context";
import { changeTeamOrder } from "../../Context/actions";
import { WrapTabNode } from "./WrapTabNode";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TabBar = (props: TabsProps, DefaultTabBar: any): JSX.Element => {
  const {
    state: { teams },
    dispatch,
  } = useAppContext();

  const moveTabNode = (dragIndex: number, hoverIndex: number) => {
    dispatch(changeTeamOrder(dragIndex, hoverIndex));
  };

  return (
    <DefaultTabBar {...props}>
      {(node: React.ReactHTMLElement<HTMLDivElement>) => {
        const index = teams.findIndex((team) => team.id === node.key);
        return index === -1 ? (
          node
        ) : (
          <WrapTabNode index={index} moveTabNode={moveTabNode}>
            {node}
          </WrapTabNode>
        );
      }}
    </DefaultTabBar>
  );
};

const DraggableTabs = (props: TabsProps): JSX.Element => {
  const { children } = props;
  return (
    <Tabs renderTabBar={TabBar} {...props}>
      {children}
    </Tabs>
  );
};

export default DraggableTabs;
