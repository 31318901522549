import React, { FC } from "react";
import { Row, Col, Divider, Menu } from "antd";
import { Footer as FooterAntd } from "antd/lib/layout/layout";
import N8Lettering from "../../assets/pictures/n8-lettering.png";
import ContextMenu from "../ContextMenu/ContextMenu";
import { TriggerOptions } from "../../types/context-menu-props";
import { useAppContext } from "../../Context/context";
import FooterProps from "../../types/footer-props";
import { deleteClientInfoAction } from "../../Context/actions";
import ProTip from "../ProTip/ProTip";
import { ProTipsType } from "../../types/pro-tips";

const Footer: FC<FooterProps> = ({
  clientImageURL,
  setClientImage,
  updateClient,
}) => {
  const {
    dispatch,
    state: { client, selectedTeam },
  } = useAppContext();

  const deleteClientInfo = () => {
    dispatch(deleteClientInfoAction());
    setClientImage("");
  };

  const ImageMenu = () => {
    if (client)
      return (
        <Menu>
          <Menu.Item key="0" onClick={() => updateClient(true)}>
            Update
          </Menu.Item>
          <Menu.Item key="1" onClick={deleteClientInfo}>
            Delete
          </Menu.Item>
        </Menu>
      );
    return (
      <Menu>
        <Menu.Item key="0" onClick={() => updateClient(false)}>
          Add Client Info
        </Menu.Item>
      </Menu>
    );
  };

  const renderN8Logo = () => {
    return (
      <Col>
        <ContextMenu menu={ImageMenu} trigger={[TriggerOptions.CONTEXT_MENU]}>
          <img src={N8Lettering} alt="n8Lettering" />
        </ContextMenu>
      </Col>
    );
  };

  return (
    <FooterAntd className="app-footer">
      <Row align="top">
        {selectedTeam !== "currentState" && selectedTeam !== "2" && (
          <ProTip
            message="hold &#8984; key to select multiple roles on Mac or Ctrl key on Windows."
            type={ProTipsType.multiSelect}
            className="pro-tip-multi"
          />
        )}
        <ProTip
          message="click on the logos to add, change or remove Client’s info."
          type={ProTipsType.changeLogo}
          className="pro-tip-logo"
        />
      </Row>
      <Row align="middle" justify="end">
        {renderN8Logo()}
        {clientImageURL && (
          <>
            <Col>
              <Divider type="vertical" />
            </Col>
            <Col>
              <ContextMenu
                menu={ImageMenu}
                trigger={[TriggerOptions.CONTEXT_MENU]}
              >
                <img
                  src={clientImageURL}
                  alt="clientImage"
                  className="clientImage"
                />
              </ContextMenu>
            </Col>
          </>
        )}
      </Row>
    </FooterAntd>
  );
};

export default Footer;
