import styled from "styled-components";

const StyledTeamSummaryView = styled.div`
  border: 1px #eee7e7 solid;
  width: 156px;
  height: 222px;
  font-size: 8px;
  display: grid;
  align-content: space-evenly;
  > .ant-row {
    margin: 0px 20px;
    > .title {
      height: 35px;
      width: 114px;
      font-size: 11px;
      font-weight: bold;
      overflow: hidden;
    }
    > .subTitle {
      background: #f7f4f4;
      padding: 5px 10px;
      border-radius: 5px;
    }
    > .withN8 {
      color: #f26522;
    }
  }
`;

export default StyledTeamSummaryView;
