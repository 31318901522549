import React, { FC, useState } from "react";
import { List, Button, Tooltip, Row, Col, Typography } from "antd";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  EditOutlined,
  PlusOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { unparse } from "papaparse";
import dayjs from "dayjs";
import { saveAction } from "../../Context/actions";
import Role from "../Role/Role";
import StyledRolesPanel, {
  StyledRolesPanelMain,
  StyledClosedRolesPanel,
} from "./StyledRolesPanel";
import EditRolesModal from "../EditRolesModal/EditRolesModal";
import ImportRolesModal from "../ImportRolesModal/ImportRolesModal";
import { useAppContext } from "../../Context/context";
import AddRoleModal from "../AddRoleModal/AddRoleModal";
import RolesPanelProps from "../../types/roles-panel-props";

const { Title, Text } = Typography;

const RolesPanel: FC<RolesPanelProps> = ({
  isRolesPanelClosed,
  switchRolesPanelDisplay,
}) => {
  const {
    state: { roles },
    dispatch,
  } = useAppContext();

  const downloadCSV = () => {
    const preProcess = roles.map((r) => {
      return {
        name: r.name,
        abbreviation: r.abbreviation,
        color: r.color,
        salary_junior: r.annualSalary.junior,
        salary_mid: r.annualSalary.mid,
        salary_senior: r.annualSalary.senior,
      };
    });
    const blob = new Blob([unparse(preProcess)], { type: "text/plain" });
    const todaysDate = dayjs().format("YYYY-MM-DD");
    const fileName = `${todaysDate}-CPSP-Roles.cpsp.csv`;
    if (navigator.userAgent.match("CriOS")) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader && typeof reader.result === "string") {
          window.location.href = reader.result;
        }
      };
      reader.readAsDataURL(blob);
      return;
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    if (!link.parentNode) return;
    link?.parentNode.removeChild(link);
    dispatch(saveAction());
  };
  const [isEditRolesModalOpen, setIsEditRolesModalOpen] =
    useState<boolean>(false);
  const [isImportRolesModalOpen, setIsImportRolesModalOpen] =
    useState<boolean>(false);
  const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState<boolean>(false);

  const openEditRolesModal = () => {
    setIsEditRolesModalOpen(true);
  };

  const closeEditRolesModal = () => {
    setIsEditRolesModalOpen(false);
  };

  const openImportRolesModal = () => {
    setIsImportRolesModalOpen(true);
  };

  const closeImportRolesModal = () => {
    setIsImportRolesModalOpen(false);
  };

  const openAddRoleModal = () => {
    setIsAddRoleModalOpen(true);
  };

  const closeAddRoleModal = () => {
    setIsAddRoleModalOpen(false);
  };

  return isRolesPanelClosed ? (
    <StyledClosedRolesPanel>
      <Tooltip title="Open Roles">
        <Button
          icon={<DoubleLeftOutlined />}
          onClick={() => switchRolesPanelDisplay()}
          id="openPanelBtn"
          data-cy="openPanelBtn"
        />
      </Tooltip>
    </StyledClosedRolesPanel>
  ) : (
    <StyledRolesPanel>
      <Row>
        <Col flex="32px">
          <Tooltip title="Close Roles">
            <Button
              icon={<DoubleRightOutlined />}
              onClick={() => switchRolesPanelDisplay()}
              id="closePanelBtn"
              data-cy="closePanelBtn"
            />
          </Tooltip>
        </Col>
        <Col>
          <Title level={4} className="title">
            Roles
          </Title>
        </Col>
        <Col span={15} offset={3}>
          <div className="titleBtns">
            <Tooltip title="Export CSV">
              <Button
                icon={<CloudDownloadOutlined />}
                id="ExportRolesBtn"
                data-cy="ExportBtn"
                onClick={downloadCSV}
                type="text"
                size="large"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  fontSize: "25px",
                  padding: 0,
                }}
              >
                <p style={{ fontSize: "10px", margin: 0, marginTop: "-8px" }}>
                  Download List
                </p>
              </Button>
            </Tooltip>
            <Tooltip title="Import CSV">
              <Button
                icon={<CloudUploadOutlined />}
                id="ImportRolesBtn"
                data-cy="ImportBtn"
                onClick={openImportRolesModal}
                type="text"
                size="large"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  fontSize: "25px",
                  padding: 0,
                }}
              >
                <p style={{ fontSize: "10px", margin: 0, marginTop: "-8px" }}>
                  Upload List
                </p>
              </Button>
            </Tooltip>
            <Tooltip title="Create Role">
              <Button
                icon={<PlusOutlined />}
                id="createRoleBtn"
                data-cy="createRoleBtn"
                onClick={openAddRoleModal}
              />
            </Tooltip>
            <Tooltip title="Edit Roles">
              <Button
                icon={<EditOutlined />}
                id="editRoleBtn"
                data-cy="editRoleBtn"
                onClick={openEditRolesModal}
              />
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={10} offset={14}>
          <Text strong>Annual Salaries</Text>
        </Col>
      </Row>
      <Row>
        <Col span={6} offset={4}>
          <Text strong>Role Name</Text>
        </Col>
        <Col span={4} offset={2}>
          <Text strong>Junior</Text>
        </Col>
        <Col span={3} offset={1}>
          <Text strong>Mid</Text>
        </Col>
        <Col span={4}>
          <Text strong>Senior</Text>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ width: "100%" }}>
          <StyledRolesPanelMain id="roles_list" data-cy="roles_list">
            <List
              size="small"
              bordered
              dataSource={roles}
              renderItem={({
                annualSalary,
                color,
                showNumber8UserIcon,
                name,
                id,
                isReadOnly,
              }) => (
                <List.Item style={{ padding: 0 }}>
                  <Role
                    annualSalary={annualSalary}
                    color={color}
                    showNumber8UserIcon={showNumber8UserIcon}
                    name={name}
                    id={id}
                    isReadOnly={isReadOnly}
                  />
                </List.Item>
              )}
            />
            {isEditRolesModalOpen && (
              <EditRolesModal
                closeModal={closeEditRolesModal}
                openAddRoleModal={openAddRoleModal}
              />
            )}
            {isImportRolesModalOpen && (
              <ImportRolesModal
                closeModal={closeImportRolesModal}
                openAddRoleModal={openAddRoleModal}
              />
            )}
            {isAddRoleModalOpen && (
              <AddRoleModal closeModal={closeAddRoleModal} />
            )}
          </StyledRolesPanelMain>
        </Col>
      </Row>
    </StyledRolesPanel>
  );
};

RolesPanel.propTypes = {
  isRolesPanelClosed: PropTypes.bool.isRequired,
  switchRolesPanelDisplay: PropTypes.func.isRequired,
};

export default RolesPanel;
