import styled from "styled-components";
import StyledUserIconAndNameProps from "../../types/styled-user-icon-and-name-props";

const StyledUserIconAndName = styled.div<StyledUserIconAndNameProps>`
  display: flex;
  flex-direction: row;
  height: ${(props) => `${props.height}px` || "50px"};
  align-items: center;
  > .userIconName {
    text-overflow: ellipsis;
  }
`;

export default StyledUserIconAndName;
