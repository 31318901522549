import React, { useState, FC } from "react";
import {
  BrowserRouter as Router,
  Route,
  useHistory,
  Switch,
} from "react-router-dom";
import Layout from "antd/lib/layout/layout";
import "./App.less";

import {
  Security,
  SecureRoute,
  LoginCallback,
  useOktaAuth,
} from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import RolesPanel from "./Components/RolesPanel/RolesPanel";
import StyledApp from "./StyledApp";
import TeamCanvas from "./Components/Canvas/Canvas";
import { AppContextProvider } from "./Context/context";
import CreateTeamModal from "./Components/CreateTeamModal/CreateTeamModal";
import EditTeamModal from "./Components/EditTeamModal/EditTeamModal";
import EditTeamResourcesModal from "./Components/EditTeamModal/EditTeamResourcesModal";
import { oktaAuthConfig, oktaSignInConfig } from "./config";
import Login from "./Components/Login/Login";
import SummaryView from "./Components/SummaryView/SummaryView";
import OktaLoginEnum from "./assets/constants/OktaLoginEnum";
import Footer from "./Components/Footer/Footer";

const oktaAuth = new OktaAuth(oktaAuthConfig);

const App: FC = () => {
  const [isRolesPanelClosed, setIsRolesPanelClosed] = useState<boolean>(false);
  const [isClientModalClosed, setIsClientModalClosed] = useState<boolean>(true);
  const [isUpdatingClient, setIsUpdatingClient] = useState<boolean>(false);
  const [isNewTeamModalClosed, setIsNewTeamModalClosed] =
    useState<boolean>(true);
  const [isEditTeamModalClosed, setIsEditTeamModalClosed] =
    useState<boolean>(true);
  const [isEditTeamResourcesModalClosed, setIsEditTeamResourcesModalClosed] =
    useState<boolean>(true);
  const [clientImageURL, setClientImageURL] = useState<string>("");

  const switchRolesPanelDisplay = () => {
    setIsRolesPanelClosed(!isRolesPanelClosed);
  };

  const switchNewTeamModalDisplay = () => {
    setIsNewTeamModalClosed(!isNewTeamModalClosed);
  };

  const switchEditTeamModalDisplay = () => {
    setIsEditTeamModalClosed(!isEditTeamModalClosed);
  };

  const switchEditTeamResourcesModalDisplay = () => {
    setIsEditTeamResourcesModalClosed(!isEditTeamResourcesModalClosed);
  };

  const setClientImage = (imageUrl: string) => {
    setClientImageURL(imageUrl);
  };

  const toggleClientModalVisibility = (updating = false) => {
    setIsClientModalClosed(!isClientModalClosed);
    setIsUpdatingClient(updating);
  };

  return (
    <Layout style={{ backgroundColor: "#FFF" }}>
      <AppContextProvider>
        <StyledApp className="site-layout" fullContent={isRolesPanelClosed}>
          <TeamCanvas
            toggleCreateTeamVisibility={switchNewTeamModalDisplay}
            toggleEditTeamVisibility={switchEditTeamModalDisplay}
            toggleEditTeamResourcesVisibility={
              switchEditTeamResourcesModalDisplay
            }
            toggleClientModalVisibility={toggleClientModalVisibility}
            fullContent={isRolesPanelClosed}
            setClientImage={setClientImage}
            isClientModalClosed={isClientModalClosed}
            isUpdatingClient={isUpdatingClient}
          />
          <div className="rightSide">
            <RolesPanel
              isRolesPanelClosed={isRolesPanelClosed}
              switchRolesPanelDisplay={switchRolesPanelDisplay}
            />
          </div>
        </StyledApp>
        {!isNewTeamModalClosed && (
          <CreateTeamModal close={switchNewTeamModalDisplay} />
        )}
        {!isEditTeamModalClosed && (
          <EditTeamModal close={switchEditTeamModalDisplay} />
        )}
        {!isEditTeamResourcesModalClosed && (
          <EditTeamResourcesModal close={switchEditTeamResourcesModalDisplay} />
        )}
        <Footer
          clientImageURL={clientImageURL}
          setClientImage={setClientImage}
          updateClient={toggleClientModalVisibility}
        />
      </AppContextProvider>
    </Layout>
  );
};

const RenderAuthRoutes = () => {
  const history = useHistory();
  const { authState } = useOktaAuth();
  const shouldRedirectToLogin =
    !authState && history.location.pathname === OktaLoginEnum.MAIN_PATH;
  if (shouldRedirectToLogin) {
    history.push(OktaLoginEnum.LOGIN_PATH);
  }
  return (
    <>
      <SecureRoute path="/" component={App} exact />
      <Route
        path={OktaLoginEnum.LOGIN_PATH}
        render={() => <Login config={oktaSignInConfig} />}
      />
      <Route path={OktaLoginEnum.LOGIN_CALLBACK} component={LoginCallback} />
    </>
  );
};

const AppWithRouterAccess: FC = () => {
  const history = useHistory();

  const customAuthHandler = () => {
    history.push(OktaLoginEnum.LOGIN_PATH);
  };

  const restoreOriginalUri = async (
    _authState: OktaAuth,
    originalUri: string
  ) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Switch>
        <Route exact path={OktaLoginEnum.PDF_PATH}>
          <AppContextProvider>
            <SummaryView />
          </AppContextProvider>
        </Route>
        {process.env.NODE_ENV !== OktaLoginEnum.PROD_ENV &&
        process.env.NODE_ENV !== OktaLoginEnum.TEST_ENV ? (
          <Route path="/" component={App} />
        ) : (
          <RenderAuthRoutes />
        )}
      </Switch>
    </Security>
  );
};

const CPSP: FC = () => {
  return (
    <Layout className="app-layout" style={{ backgroundColor: "#FFF" }}>
      <Router>
        <AppWithRouterAccess />
      </Router>
    </Layout>
  );
};

export default CPSP;
