enum TeamValuesEnum {
  CURRENT_SCENARIO_ID = "0",
  DEFAULT_TEAM_ID = "1",
  ADD_TEAM_TAB_KEY = "addTeamKey",
  FUTURE_STATE_ID = "2",
  MAX_SCENARIOS = "3",
  DEFAULT_VELOCITY = "100",
  DEFAULT_WEEKS_PER_SPRINT = "2",
  DEFAULT_SCENARIO_NAME = "Current Scenario",
}

export default TeamValuesEnum;
