import React, { FC, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { Typography, Col, Modal, Row, Input, Divider, Button } from "antd";
import PropTypes from "prop-types";
import { useAppContext } from "../../Context/context";
import { CreateTeamModalProps } from "../../types/create-team-modal-props";
import UserIconSizeEnum from "../../assets/constants/UserIconSizeEnum";
import RoleAmountProps from "../../types/role-amount-props";
import UserIcon from "../UserIcon/UserIcon";
import EditTeamForm from "../EditTeamForm/EditTeamForm";
import TeamProps from "../../types/team-props";
import TeamValuesEnum from "../../assets/constants/TeamValuesEnum";
import {
  createTeamWithAvailableResourcesAction,
  addRoleToStateAction,
} from "../../Context/actions";
import {
  RoleSeniority,
  RoleSeniorityList,
} from "../../assets/constants/RoleSeniority";
import { convertRoleNameToPascal } from "../../helpers/roles";

const { Title } = Typography;
const reducer = (prev: number, curr: number) => curr + prev;

const CreateTeamModal: FC<CreateTeamModalProps> = ({ close }) => {
  const {
    dispatch,
    state: { roles },
  } = useAppContext();
  const [newTeamId] = useState<string>(nanoid());
  const [isRepeatedName, setIsRepeatedName] = useState<boolean>(false);
  const [assignedRoleToTeam, setAssignedRoleToTeam] = useState<
    RoleAmountProps[]
  >([]);
  const [teamFields, setTeamFields] = useState<TeamProps>({
    id: newTeamId,
    name: "Bench Team",
    weeksPerSprint: Number(TeamValuesEnum.DEFAULT_WEEKS_PER_SPRINT),
    velocity: Number(TeamValuesEnum.DEFAULT_VELOCITY),
  });

  useEffect(() => {
    setAssignedRoleToTeam(
      roles.map((resource) => ({
        role: resource,
        amount: { junior: 0, mid: 0, senior: 0 }, // TODO: to be fixed in CPSP-311
      }))
    );
  }, [roles]);

  const save = () => {
    const newArr: {
      roleId: string;
      seniority: RoleSeniority;
      teamId: string;
      id: string;
    }[] = [];
    assignedRoleToTeam.map((tempArr) => {
      RoleSeniorityList.map((seniorit) => {
        if (tempArr.amount[seniorit] > 0) {
          for (let index = 0; index < tempArr.amount[seniorit]; index++) {
            newArr.push({
              id: newTeamId,
              roleId: tempArr.role.id,
              teamId: "",
              seniority: seniorit,
            });
          }
        }
        return 2;
      });
      return 1;
    });
    dispatch(
      createTeamWithAvailableResourcesAction(teamFields, assignedRoleToTeam)
    );
    newArr.map((role) =>
      dispatch(
        addRoleToStateAction(role.teamId, role.id, role.roleId, role.seniority)
      )
    );
    close();
  };

  const onChangeField = (fieldName: string, fieldValue: string) => {
    if (fieldName !== "name") {
      setTeamFields({ ...teamFields, [fieldName]: Number(fieldValue) });
    } else {
      setTeamFields({ ...teamFields, [fieldName]: fieldValue });
    }
  };

  const calculateAssignedResources = (resources1: RoleAmountProps[]) => {
    const total = resources1
      .map((resource) => {
        const subTotal = RoleSeniorityList.map(
          (seniority) => resource.amount[seniority]
        ).reduce(reducer, 0);
        return subTotal;
      })
      .reduce(reducer, 0);
    return total;
  };

  const calculateSeniorityResources = (
    resources1: RoleAmountProps[],
    seniority: RoleSeniority
  ) => {
    const total = resources1
      .map((resource) => {
        return resource.amount[seniority];
      })
      .reduce(reducer, 0);
    return total;
  };

  const assignRoleToTeam = (
    roleId: string,
    seniority: RoleSeniority,
    amount: string,
    limit: number
  ) => {
    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
    const newAmount = Number(amount);
    if (newAmount <= limit && newAmount >= 0) {
      const newAssignedRole = assignedRoleToTeam.map((assignedRole) => {
        if (assignedRole.role.id === roleId) {
          return {
            ...assignedRole,
            amount: {
              ...assignedRole.amount,
              [seniority]: Number(amount),
            },
          };
        }
        return assignedRole;
      });
      setAssignedRoleToTeam(newAssignedRole);
    }
  };

  return (
    <Modal
      visible
      onCancel={close}
      width={600}
      title={
        <Title level={3} style={{ marginBottom: 0 }}>
          Create Team
        </Title>
      }
      centered
      data-cy="createTeamModal"
      footer={[
        <Button key="back" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={save}
          title={!teamFields.name ? "Please include a team name" : undefined}
          data-cy="createTeamSubmitBtn"
        >
          Create
        </Button>,
      ]}
    >
      <EditTeamForm
        name={teamFields.name}
        velocity={teamFields.velocity}
        weeksPerSprint={teamFields.weeksPerSprint}
        onChangeTeamName={(event) => onChangeField("name", event.target.value)}
        onChangeTeamVelocity={(event) =>
          onChangeField("velocity", event.target.value)
        }
        onChangeWeeksPerSprint={(event) =>
          onChangeField("weeksPerSprint", event.target.value)
        }
        scenarios={0}
      />
      <Row justify="space-between" className="resources-titles">
        <Col span={9} style={{ marginLeft: "10px" }} />
        {RoleSeniorityList.map((roleSeniority) => (
          <Col span={2} className="resources-titles-seniority">
            {convertRoleNameToPascal(roleSeniority)}
          </Col>
        ))}
        <Col
          span={2}
          className="resources-titles-seniority"
          style={{ fontSize: "10px", marginTop: "5px" }}
        >
          Assigning
        </Col>
        <div style={{ maxHeight: "400px", overflow: "auto" }}>
          {roles
            .filter((resource) => !resource.showNumber8UserIcon)
            .map((resource) => {
              const assignedResource = assignedRoleToTeam.find(
                (assignedRole) => assignedRole.role.id === resource.id
              );
              if (!assignedResource) return null;
              return (
                <Row
                  key={resource.id}
                  align="middle"
                  justify="space-between"
                  className="role"
                >
                  <Col span={1}>
                    <UserIcon
                      color={resource.color}
                      showNumber8UserIcon={resource.showNumber8UserIcon}
                      size={UserIconSizeEnum.LARGE}
                    />
                  </Col>
                  <Col span={4}>{resource.name}</Col>
                  <Col span={1}>x</Col>
                  {RoleSeniorityList.map((resourceAmountKey) => (
                    <>
                      <Col span={2}>
                        <Input
                          value={assignedResource.amount[resourceAmountKey]}
                          min={0}
                          onChange={(event) =>
                            assignRoleToTeam(
                              resource.id,
                              resourceAmountKey,
                              event.target.value,
                              100
                            )
                          }
                          size="small"
                          type="number"
                        />
                      </Col>
                    </>
                  ))}
                  <Col span={2} style={{ textAlign: "center" }}>
                    {RoleSeniorityList.reduce(
                      (previousValue, currentValue) =>
                        previousValue + assignedResource.amount[currentValue],
                      0
                    )}
                  </Col>
                </Row>
              );
            })}
        </div>
      </Row>
      <Divider />
      <Row>
        <Col span={11}>Team members</Col>
        {RoleSeniorityList.map((seniority, i, arr) => (
          <Col span={i === arr.length - 1 ? 3 : 4}>
            {calculateSeniorityResources(assignedRoleToTeam, seniority)}
          </Col>
        ))}
        <Col span={2} style={{ textAlign: "center" }}>
          {calculateAssignedResources(assignedRoleToTeam)}
        </Col>
      </Row>
      {isRepeatedName && (
        <Modal
          data-cy="cantCreateTeamModal"
          visible
          title="Can't create team"
          footer={null}
          onCancel={() => setIsRepeatedName(false)}
        >
          That team name is already used. Please type a different name
        </Modal>
      )}
    </Modal>
  );
};

CreateTeamModal.propTypes = {
  close: PropTypes.func.isRequired,
};

export default CreateTeamModal;
