import React, { FC, useEffect, useState } from "react";
import { useAppContext } from "../../Context/context";
import StateSummary from "../StateSummary/StateSummary";
import calculateCPSP from "../../helpers/calculateCPSP";
import {
  buildStateRoleWithRoleDTO,
  getScenarioMembersTotalSalary,
} from "../../helpers/scenarioResources";
import {
  calculateAvailableResources,
  getTeamScenarioCPSPColor,
} from "../../helpers/teams";
import StyledFutureState from "./StyledFutureState";
import AvailableResources from "../AvailableResources/AvailableResources";
import TeamsCurrentAndFutureMembersProps from "../../types/teams-current-and-future-members-props";

const reducer = (accumulator: number, currentValue: number) =>
  accumulator + currentValue;

const FutureState: FC = () => {
  const {
    state: { teams, roles, teamScenario, teamScenarioResource, resources },
  } = useAppContext();
  const [metaTeam, setMetaTeam] = useState<TeamsCurrentAndFutureMembersProps>(
    []
  );
  const [metaTeamSize, setMetaTeamSize] = useState<number>(0);
  const [currentTeamSize, setCurrentTeamSize] = useState<number>(0);
  const [futureTeamSize, setFutureTeamSize] = useState<number>(0);

  useEffect(() => {
    const teamDiff = calculateAvailableResources(
      teams,
      teamScenario,
      teamScenarioResource,
      resources,
      roles
    );
    setMetaTeam(teamDiff);
    const currentTeamSizeCalc = teamDiff[0]
      .map(
        (resource) =>
          resource.amount.junior + resource.amount.mid + resource.amount.senior
      )
      .reduce(reducer);
    const futureTeamSizeCalc = teamDiff[1]
      .map(
        (resource) =>
          resource.amount.junior + resource.amount.mid + resource.amount.senior
      )
      .reduce(reducer);
    const size = teamDiff[2]
      .map(
        (resource) =>
          resource.amount.junior + resource.amount.mid + resource.amount.senior
      )
      .reduce(reducer);
    setFutureTeamSize(futureTeamSizeCalc);
    setCurrentTeamSize(currentTeamSizeCalc);
    setMetaTeamSize(size);
  }, [teams, roles, teamScenario, teamScenarioResource, resources]);

  return (
    <StyledFutureState>
      <div className="states">
        {teams
          .filter(
            (team) =>
              teamScenario.filter((scenario) => scenario.teamId === team.id)
                .length > 1
          )
          .map((team) => ({
            team,
            scenario: teamScenario
              .filter((scenario) => scenario.teamId === team.id)
              .pop(),
          }))
          .map(({ team, scenario }, index) => {
            const members = buildStateRoleWithRoleDTO(
              teamScenarioResource.filter(
                (scenarioResource) =>
                  scenarioResource.scenarioId === scenario?.id
              ),
              resources.filter((resource) =>
                teamScenarioResource
                  .filter(
                    (scenarioResource) =>
                      scenario && scenarioResource.scenarioId === scenario.id
                  )
                  .map((scenarioResource) => scenarioResource.resourceId)
                  .includes(resource.id)
              ),
              roles
            );
            const scenarioResourcesAmnt =
              getScenarioMembersTotalSalary(members);
            const cpsp = calculateCPSP(
              scenarioResourcesAmnt,
              scenario?.velocity || team.velocity,
              team.weeksPerSprint * 12
            );
            const cpspColor = getTeamScenarioCPSPColor(
              resources,
              teamScenario,
              teamScenarioResource,
              teams,
              roles,
              team.id,
              scenario?.id || ""
            );
            return (
              <StateSummary
                index={index}
                name={team.name}
                velocity={scenario?.velocity || team.velocity || 100}
                weeksPerSprint={team.weeksPerSprint || 2}
                cpsp={cpsp}
                cpspColor={cpspColor}
                members={members}
                futureStateTeam
              />
            );
          })}
        <AvailableResources
          resources={metaTeam}
          total={metaTeamSize}
          futureStateTotal={futureTeamSize}
          currentStateTotal={currentTeamSize}
        />
      </div>
    </StyledFutureState>
  );
};

export default FutureState;
