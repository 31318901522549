import React, { FC } from "react";
import StyledWithN8Name from "./StyledWithN8Name";

const WithN8Name: FC = () => {
  return (
    <StyledWithN8Name>
      <div>With</div> <div>#8</div>
    </StyledWithN8Name>
  );
};

export default WithN8Name;
