import styled from "styled-components";

const StyledTeamState = styled.div`
  height: calc(94vh - 220px);
  width: 310px;
  padding: 20px;
  padding-left: 0;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 18px;
  > .stateTeamMembers {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    width: 100%;
    margin-left: 10px;
  }
  > .ant-row > .stateTeamMembers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50px;
    width: 100%;
    margin-top: 20px;
    overflow: auto;
    > .ant-col {
      height: 50px;
      > .role-icon {
        width: 100%;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        > .member-captions {
          margin-left: 5px;
          > .second-row {
            display: flex;
            div {
              margin-right: 2px;
            }
          }
        }
      }
      > .selectedRole > .dot {
        background-color: #f26522;
        border-radius: 10px;
        width: 40px;
        height: 10px;
        width: 10px;
        margin-left: 35px;
        position: absolute;
        top: 0px;
      }
      > .selectedRole {
        background-color: #807979;
      }
    }
  }
  @media only screen and (max-width: 1360px) {
    grid-row-gap: 10px;
  }
  > .ant-row > .ant-col > .stateName {
    display: flex;
    align-content: center;
    padding: 6.5px 11px;
    border: white 1px solid;
    font-size: 19px;
    font-weight: bold;
  }
  > .ant-row > .ant-col > .teamStateName {
    display: flex;
    padding: 6.5px 11px;
    border: white 1px solid;
    font-size: 19px;
    font-weight: bold;
    height: 73px;
    margin-bottom: 0;
    &:not(textarea):hover {
      background: #f0f0f0;
    }
    &:is(textarea) {
      background: #f0f0f0;
      border-color: blue;
      margin: 0;
    }
  }

  > .cloneStateBtn {
    align-self: flex-end;
  }

  > .teamStateRow {
    margin-left: 25px;
    display: flex;
    flex-direction: row;
    font-size: 17px;
    > .ant-col {
      > input[type="number"] {
        text-align: right;
      }
      > span > input[type="number"] {
        text-align: right;
      }
      > input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      > span > input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  > .teamStateRow > div > .cpsp-description {
    color: #808080;
  }
`;
export default StyledTeamState;
