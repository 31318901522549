import styled from "styled-components";
import StyledTeamStateSummary from "../StateSummary/StyledTeamStateSummary";

const StyledAvailableResources = styled(StyledTeamStateSummary)`
  > .freedUpResources {
    height: 90%;
    overflow-y: auto;
  }
  > .ant-row > .ant-col > .title {
    background-color: #e6e6e6;
    border-radius: 7px;
    height: 35px;
    width: 100%;
    text-align: start;
    margin: 0 5px;
    padding: 8px;
    font-weight: bold;
    padding-left: 12px;
  }
`;
export default StyledAvailableResources;
