import React, { FC, useState, useEffect } from "react";
import { Modal, Input, Typography, Button, Row, Col } from "antd";
import PropTypes from "prop-types";
import { useAppContext } from "../../Context/context";
import UserIcon from "../UserIcon/UserIcon";
import StyledAddRoleModal from "./StyledAddRoleModal";
import AddRoleModalProps from "../../types/add-role-modal-props";
import { addRoleAction } from "../../Context/actions";
import { RoleSalary } from "../../types/role-props";
import { RoleSeniorityList } from "../../assets/constants/RoleSeniority";
import { convertRoleNameToPascal } from "../../helpers/roles";

const { Text, Title } = Typography;

const AddRoleModal: FC<AddRoleModalProps> = ({ closeModal }) => {
  const {
    dispatch,
    state: { colors, roles },
  } = useAppContext();
  const [name, setName] = useState<string>("");
  const [abbreviation, setAbbreviation] = useState<string>("");
  const [annualSalary, setAnnualSalary] = useState<RoleSalary>({
    mid: 0,
    junior: 0,
    senior: 0,
  });
  const [color, setColor] = useState<string>("");
  const availableColors = colors.filter((c) => {
    return !roles.find((r) => r.color.toLowerCase() === c.toLowerCase());
  });
  useEffect(() => {
    if (colors && colors.length > 0) {
      setColor(colors[0]);
    }
  }, [colors]);

  const createRole = () => {
    dispatch(addRoleAction(name, abbreviation, annualSalary, color, false));
    closeModal();
  };

  const enableSaveBtn = () => {
    return (
      name &&
      annualSalary &&
      annualSalary.mid > 0 &&
      annualSalary.junior > 0 &&
      annualSalary.senior > 0
    );
  };

  return (
    <Modal
      visible
      title={
        <Title level={3} style={{ marginBottom: 0 }}>
          Create New Role
        </Title>
      }
      data-cy="addRolesModal"
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={createRole}
          data-cy="createRoleSubmitBtn"
          disabled={!enableSaveBtn()}
        >
          Create
        </Button>,
      ]}
    >
      <StyledAddRoleModal
        onKeyDown={({ code }) => {
          if (code === "Enter" && name) {
            createRole();
          }
        }}
        tabIndex={0}
      >
        <Row>
          <Col span={8}>
            <Text>Role Name</Text>
            <Input
              placeholder="Role Name"
              value={name}
              onChange={({ target: { value } }) => {
                setName(value);
              }}
              className="editRoleNameInput"
              data-cy="newRoleNameInput"
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Text>Role Abbreviation</Text>
            <Input
              placeholder="Role Abbreviation"
              value={abbreviation}
              onChange={({ target: { value } }) => {
                setAbbreviation(value);
              }}
              className="editRoleAbbreviationInput"
              data-cy="newRoleAbbreviationInput"
            />
          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <Text>Annual Salary</Text>
            <Row>
              {RoleSeniorityList.map((roleSeniority) => (
                <Col span={8}>
                  <Text>{convertRoleNameToPascal(roleSeniority)}</Text>
                  <Input
                    placeholder="Annual Salary"
                    value={annualSalary[roleSeniority] / 1000}
                    type="number"
                    prefix="$"
                    onChange={({ target: { value } }) => {
                      setAnnualSalary({
                        ...annualSalary,
                        [roleSeniority]: Number(value) * 1000,
                      });
                    }}
                    data-cy={`newRole${roleSeniority}SalaryInput`}
                    suffix="K"
                    style={{ width: 100 }}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <div className="addRoleRow">
          <Text>Pick Avatar Color</Text>
          <div
            className="availableColors"
            style={{
              overflow: "hidden",
              overflowY: "scroll",
              flexWrap: "wrap",
              maxHeight: "20vh",
              position: "relative",
            }}
          >
            {availableColors.map((c: string, index: number) => (
              <div
                role="button"
                onClick={() => setColor(c)}
                className={c === color ? "selectedColor" : "unselectedColor"}
                onKeyDown={() => setColor(c)}
                tabIndex={index}
                data-cy={`newRoleColor${c.substring(1)}`}
                key={c}
              >
                <UserIcon color={c} showNumber8UserIcon={false} />
              </div>
            ))}
          </div>
        </div>
      </StyledAddRoleModal>
    </Modal>
  );
};

AddRoleModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
export default AddRoleModal;
