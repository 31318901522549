import React, { FC, useCallback, useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Col, Divider, Row, Typography } from "antd";
import axios from "axios";
import Layout, { Footer, Header } from "antd/lib/layout/layout";
import { MailOutlined, WhatsAppOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useAppContext } from "../../Context/context";
import calculateCPSP, { getCPSPColorCode } from "../../helpers/calculateCPSP";
import {
  buildStateRoleWithRoleDTO,
  getScenarioMembersTotalSalary,
} from "../../helpers/scenarioResources";
import StyledSummaryView from "./StyledSummaryView";
import TeamStateSummaryView from "./TeamStateSummaryView";
import N8LetteringImage from "../../assets/pictures/n8-lettering.png";
import ClientImage from "../../assets/pictures/client.png";
import ResourceView from "./ResourceView";
import CurrentStateName from "../CommonStateTitle/CurrrentStateName";
import { loadAppStateAction } from "../../Context/actions";
import WithN8Name from "../CommonStateTitle/WithN8Name";
import {
  calculateAvailableResources,
  getTeamScenarioMembers,
} from "../../helpers/teams";
import VarianceCost from "./SummaryVarianceCost";
import StyledMetricsState from "./StyledSummaryMetrics";
import TeamSummaryView from "./TeamSummaryView";
import SummaryViewBanner from "./SummaryViewBanner";
import AvailableResources from "./AvailableResources";
import TeamsCurrentAndFutureMembersProps from "../../types/teams-current-and-future-members-props";

const { Text, Title } = Typography;

const DETAILED_VIEW_PAGE_AMOUNT = 5;

const reducer = (accumulator: number, currentValue: number) =>
  accumulator + currentValue;

const SummaryView: FC<RouteComponentProps> = ({ match }) => {
  const typeOfView = () => "CONSOLIDATED VIEW";
  const todaysDate = dayjs().format("MM-DD-YYYY");
  const {
    state: {
      teams,
      roles,
      client,
      teamScenario,
      teamScenarioResource,
      resources,
      userInfo,
    },
    dispatch,
  } = useAppContext();
  const [currentStateTotal, setCurrentStateTotal] = useState<number>(0);
  const [futureStateTotal, setFutureStateTotal] = useState<number>(0);
  const [metaTeam, setMetaTeam] = useState<TeamsCurrentAndFutureMembersProps>(
    []
  );
  const [currentCost, setCurrentCost] = useState<number>(0);
  const [futureCost, setFutureCost] = useState<number>(0);
  const [currentHeadCount, setCurrentHeadCount] = useState<number>(0);
  const [futureHeadCount, setFutureHeadCount] = useState<number>(0);
  const [newVelocity, setNewVelocity] = useState<number>(0);
  const [newHeadCount, setNewHeadCount] = useState<number>(0);
  useEffect(() => {
    const teamDiff = calculateAvailableResources(
      teams,
      teamScenario,
      teamScenarioResource,
      resources,
      roles
    );
    setMetaTeam(teamDiff);
    const currentTeamSizeCalc = teamDiff[0]
      .map(
        (resource) =>
          resource.amount.junior + resource.amount.mid + resource.amount.senior
      )
      .reduce(reducer);
    setCurrentHeadCount(currentTeamSizeCalc);
    // Start New Teams //
    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
    /* eslint-disable @typescript-eslint/no-explicit-any */
    let newTeamsVelocity = 0;
    const benchTeams: any[] = [];
    teams.map((team) => {
      const teamKeys = Object.keys(team);
      if (teamKeys[0] === "name") {
        benchTeams.push(team);
      }
      return benchTeams;
    });
    let newMembers = 0;
    benchTeams
      .filter(
        (team) =>
          teamScenario.filter((scenario) => scenario.teamId === team.id)
            .length > 1
      )
      .map((team) => ({
        team,
        scenario: teamScenario
          .filter((scenario) => scenario.teamId === team.id)
          .pop(),
      }))
      .map(({ scenario }) => {
        for (let index = 0; index < teamScenarioResource.length; index++) {
          if (scenario?.id === teamScenarioResource[index].scenarioId) {
            newMembers += 1;
          }
        }
        if (scenario?.velocity) {
          newTeamsVelocity += scenario?.velocity;
        }
        return setNewVelocity(newTeamsVelocity);
      });
    setNewHeadCount(newMembers);
    // End New Teams //
  }, [teams, roles, teamScenario, teamScenarioResource, resources]);
  const loadData = useCallback(async () => {
    const cacheId = match.url.split("/").slice(-1);
    const { data } = await axios.get(`/api/state/${cacheId}`);
    if (data && data.teams.length > 0) {
      dispatch(loadAppStateAction(data));
    }
  }, [dispatch, match.url]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    const teamsToShow = teams.filter((team) =>
      teamScenario.filter((scenario) => scenario.teamId === team.id)
    );
    let currentVariance = 0;
    let currentCostTemp = 0;
    let futureVariance = 0;
    let futureCostTemp = 0;
    teamsToShow.map((team) => {
      const currentScenario = teamScenario.find(
        (scenario) => scenario.teamId === team.id && scenario.teamOrder === 0
      );
      if (!currentScenario) return 0;
      const currentScenarioResources =
        resources.filter((resource) =>
          teamScenarioResource
            ?.filter(
              (scenarioResource) =>
                scenarioResource.scenarioId === currentScenario.id
            )
            .map((scenarioResource) => scenarioResource.resourceId)
            .includes(resource.id)
        ) || [];
      const members = buildStateRoleWithRoleDTO(
        teamScenarioResource.filter(
          (scenarioResource) =>
            scenarioResource.scenarioId === currentScenario.id
        ),
        currentScenarioResources,
        roles
      );
      currentCostTemp += getScenarioMembersTotalSalary(members);
      if (currentScenario.velocity) {
        currentVariance += currentScenario.velocity;
      }
      return setCurrentStateTotal(currentVariance);
    });
    setCurrentCost(currentCostTemp);
    teamsToShow.map((team) => {
      const teamScenarios = teamScenario.filter(
        (scenario) => scenario.teamId === team.id
      );
      const withN8Scenario = teamScenarios[teamScenarios.length - 1];
      if (!withN8Scenario) {
        return 0;
      }

      const withN8ScenarioResources =
        resources.filter((resource) =>
          teamScenarioResource
            ?.filter(
              (scenarioResource) =>
                scenarioResource.scenarioId === withN8Scenario.id
            )
            .map((scenarioResource) => scenarioResource.resourceId)
            .includes(resource.id)
        ) || [];
      const members = buildStateRoleWithRoleDTO(
        teamScenarioResource.filter(
          (scenarioResource) =>
            scenarioResource.scenarioId === withN8Scenario.id
        ),
        withN8ScenarioResources,
        roles
      );
      futureCostTemp += getScenarioMembersTotalSalary(members);
      return setFutureCost(futureCostTemp);
    });
    // Start With #8 Variance //
    let futureMembers = 0;
    teams
      .filter(
        (team) =>
          teamScenario.filter((scenario) => scenario.teamId === team.id)
            .length > 1
      )
      .map((team) => ({
        team,
        scenario: teamScenario
          .filter((scenario) => scenario.teamId === team.id)
          .pop(),
      }))
      .map(({ scenario }) => {
        for (let index = 0; index < teamScenarioResource.length; index++) {
          if (scenario?.id === teamScenarioResource[index].scenarioId) {
            futureMembers += 1;
          }
        }
        if (scenario?.velocity) {
          futureVariance += scenario?.velocity;
        }
        return setFutureStateTotal(futureVariance);
      });
    setFutureHeadCount(futureMembers);
    if (futureMembers === 0) {
      setFutureCost(0);
    }
    // End With #8 Variance //
  }, [resources, roles, teamScenario, teamScenarioResource, teams]);

  const header = () => {
    return (
      <Header>
        <Row align="middle" justify="space-between">
          <Col>
            <Title level={4}>
              Cost Per Story Point (CPSP) Summary for {client?.name}
            </Title>
          </Col>
          <Col>
            <Row>
              <Col>
                <img
                  src={(client && client.image) || ClientImage}
                  alt="clientImage"
                  className="clientImage"
                />
              </Col>
              <Col>
                <Divider type="vertical" />
              </Col>
              <Col>
                <img
                  src={N8LetteringImage}
                  alt="n8Lettering"
                  className="n8Image"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>
    );
  };

  const footer = () => {
    return (
      <Footer className="pdf-footer">
        <Row justify="space-between">
          <Col>
            <Divider />
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                All prices, CPSP amounts, and velocity figures in this
                presentation are estimates and do not represent guarantees or
                quotes for services from number8.
              </Col>
              <Col>{todaysDate}</Col>
            </Row>
            <Row justify="start" gutter={10}>
              <Col>
                Created for {client?.name} by{" "}
                {(userInfo && userInfo.name) || `<User email>`}
              </Col>
              <Col>|</Col>
              <Col>
                <MailOutlined />{" "}
                {(userInfo && userInfo.email) || `<User email>`}
              </Col>
              {userInfo && userInfo.phoneNumber && (
                <Col>
                  <WhatsAppOutlined /> {userInfo.phoneNumber}
                </Col>
              )}
              <Col>|</Col>
              <Col>
                <MailOutlined />
                {" info@number8.com"}
              </Col>
              <Col>
                <WhatsAppOutlined />
                {" 502-212-0978"}
              </Col>
              <Col>|</Col>
              <Col>
                <a href="https://number8.com">number8.com</a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Footer>
    );
  };

  const displayTeamDetails = () => {
    return (
      <StyledSummaryView>
        <Layout>
          {header()}
          <Layout>
            <Row>
              <Col>
                <Text className="subtitle typeOfView">{typeOfView()}</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text className="subtitle">
                  Cost Per Story Point (CPSP) comparison between{" "}
                  <CurrentStateName /> and <WithN8Name /> Scenarios
                </Text>
              </Col>
            </Row>
            <SummaryViewBanner
              futureStateTotal={futureStateTotal}
              currentStateTotal={currentStateTotal}
              variance={currentStateTotal - futureStateTotal}
              varianceColor={getCPSPColorCode(
                currentStateTotal,
                futureStateTotal
              )}
            />
            <div className="teams">
              {teams &&
                teams.map((team) => (
                  <TeamSummaryView
                    team={team}
                    key={team.id}
                    roles={roles}
                    resources={resources}
                    scenarioResources={teamScenarioResource}
                    scenarios={teamScenario}
                  />
                ))}
              <AvailableResources resources={metaTeam} />
            </div>
          </Layout>
          {footer()}
        </Layout>
      </StyledSummaryView>
    );
  };

  const displayDetailedView = () => {
    if (!teams) {
      return <div />;
    }
    const repeatingTimes = Math.ceil(teams.length / DETAILED_VIEW_PAGE_AMOUNT);
    return [...Array(repeatingTimes)].map((val, index) => (
      <StyledSummaryView>
        <Layout>
          {header()}
          <Layout>
            <Row>
              <Col span={18}>
                <Row>
                  <Col>
                    <Text className="subtitle typeOfView">DETAILED VIEW</Text>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Text className="subtitle">
                      Cost Per Story Point (CPSP) comparison between{" "}
                      <CurrentStateName /> and <WithN8Name /> Scenarios
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="banner currentState">
                      <CurrentStateName /> Scenario
                    </div>
                  </Col>
                </Row>
                <div className="detailedViewTeams">
                  {teams &&
                    teams
                      .slice(
                        index * DETAILED_VIEW_PAGE_AMOUNT,
                        index * DETAILED_VIEW_PAGE_AMOUNT +
                          DETAILED_VIEW_PAGE_AMOUNT
                      )
                      .map((team) => {
                        const teamsCurrentScenario = teamScenario.find(
                          (scenario) =>
                            scenario.teamId === team.id &&
                            scenario.teamOrder === 0
                        );
                        if (!teamsCurrentScenario) return null;
                        const velocity =
                          teamsCurrentScenario.velocity || team.velocity;
                        const weeksPerSprint =
                          teamsCurrentScenario.weeksPerSprint ||
                          team.weeksPerSprint;
                        const scenarioMembers = getTeamScenarioMembers(
                          teamScenarioResource,
                          resources,
                          teamsCurrentScenario,
                          roles
                        );
                        const cpsp = calculateCPSP(
                          getScenarioMembersTotalSalary(scenarioMembers),
                          velocity,
                          weeksPerSprint * 12
                        );
                        return (
                          <TeamStateSummaryView
                            members={scenarioMembers}
                            weeksPerSprint={weeksPerSprint}
                            teamName={team.name}
                            velocity={velocity}
                            cpsp={cpsp}
                          />
                        );
                      })}
                </div>
                <Row>
                  <Col>
                    <div className="banner">
                      <div className="n8">With #8 </div>
                      Scenario
                    </div>
                  </Col>
                </Row>
                <div className="detailedViewTeams">
                  {teams &&
                    teams
                      .filter(
                        (team) =>
                          teamScenario.filter(
                            (scenario) => scenario.teamId === team.id
                          ).length >= 2
                      )
                      .slice(
                        index * DETAILED_VIEW_PAGE_AMOUNT,
                        index * DETAILED_VIEW_PAGE_AMOUNT +
                          DETAILED_VIEW_PAGE_AMOUNT
                      )
                      .map((team) => {
                        const teamScenarios = teamScenario.filter(
                          (scenario) => scenario.teamId === team.id
                        );
                        const teamsCurrentScenario = teamScenarios[0];
                        const withN8Scenario =
                          teamScenarios[teamScenarios.length - 1];
                        if (!teamsCurrentScenario || !withN8Scenario)
                          return null;
                        const velocity =
                          withN8Scenario.velocity || team.velocity;
                        const scenarioMembers = getTeamScenarioMembers(
                          teamScenarioResource,
                          resources,
                          teamsCurrentScenario,
                          roles
                        );
                        const withN8ScenarioMembers = getTeamScenarioMembers(
                          teamScenarioResource,
                          resources,
                          withN8Scenario,
                          roles
                        );
                        const currentCpsp = calculateCPSP(
                          getScenarioMembersTotalSalary(scenarioMembers),
                          velocity,
                          team.weeksPerSprint * 12
                        );
                        const cpspWithN8 = calculateCPSP(
                          getScenarioMembersTotalSalary(withN8ScenarioMembers),
                          velocity,
                          team.weeksPerSprint * 12
                        );
                        const cpspColor = getCPSPColorCode(
                          currentCpsp,
                          cpspWithN8
                        );
                        return (
                          <TeamStateSummaryView
                            members={withN8ScenarioMembers}
                            teamName={team.name}
                            key={team.id}
                            velocity={velocity}
                            weeksPerSprint={team.weeksPerSprint}
                            cpsp={cpspWithN8}
                            cpspColor={cpspColor}
                          />
                        );
                      })}
                </div>
              </Col>
              <Col span={6}>
                <ResourceView roles={roles} />
              </Col>
            </Row>
          </Layout>
          {footer()}
        </Layout>
      </StyledSummaryView>
    ));
  };

  return (
    <>
      <StyledSummaryView>
        <Layout>
          {header()}
          <Layout>
            <Row>
              <Col>
                <Text className="subtitle typeOfView">
                  PRODUCTIVITY METRICS
                </Text>
              </Col>
            </Row>
            <StyledMetricsState>
              <Row className="tab-headers">
                <h5 className="metrics-title">Productivity Metrics</h5>
                <Col span={24}>
                  <Row>
                    <Col span={12} offset={4} className="metrics-stats-headers">
                      <Row>
                        <Col span={8}>
                          <Title level={5} className="column-header">
                            Current
                          </Title>
                          <div className="sub-title">scenario</div>
                        </Col>
                        <Col span={8} className="n8 n8Col">
                          <Title level={5}>With number8</Title>
                          <div className="sub-title">scenario</div>
                        </Col>
                        <Col span={8}>
                          <Title level={5} className="column-header">
                            Variances
                          </Title>
                          <div className="sub-title">scenario</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={7} offset={1} className="new-teams-header">
                      <Title level={5} className="column-header">
                        New Teams
                      </Title>
                      <div className="sub-title">
                        (created from available resources)
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row>
                    <Col span={24} className="left-title first">
                      <Title level={5} className="column-header">
                        Velocity
                      </Title>
                      <div className="sub-title">(Story points)</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="left-title">
                      <Title level={5} className="column-header">
                        Head count
                      </Title>
                      <div className="sub-title">(Number of people)</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="left-title last-title">
                      <Title level={5} className="column-header">
                        Cost
                      </Title>
                      <div className="sub-title">(Salary * HC)</div>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row className="first-row">
                    <Col span={8} className="metrics-values">
                      <Title level={5}>{currentStateTotal} pts</Title>
                    </Col>
                    <Col span={8} className="n8Col metrics-values">
                      <Title level={5}>{futureStateTotal} pts</Title>
                    </Col>
                    <Col span={8} className="metrics-values">
                      <Title level={5}>
                        {currentStateTotal - futureStateTotal} pts
                      </Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8} className="metrics-values">
                      <Title level={5}>{currentHeadCount}</Title>
                    </Col>
                    <Col span={8} className="n8Col metrics-values">
                      <Title level={5}>{futureHeadCount}</Title>
                    </Col>
                    <Col span={8} className="metrics-values">
                      <Title level={5}>
                        {currentHeadCount - futureHeadCount}
                      </Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8} className="metrics-values">
                      <Title level={5}>${currentCost / 1000}K</Title>
                    </Col>
                    <Col
                      span={8}
                      className="n8Col metrics-values"
                      data-cy="futureStateVariance"
                    >
                      <Title level={5}>
                        <VarianceCost
                          variance={futureCost}
                          varianceColor={getCPSPColorCode(
                            currentCost,
                            futureCost
                          )}
                        />
                      </Title>
                    </Col>
                    <Col span={8} className="metrics-values">
                      <Title level={5}>
                        <VarianceCost
                          variance={currentCost - futureCost}
                          varianceColor={getCPSPColorCode(
                            currentCost,
                            futureCost
                          )}
                        />
                      </Title>
                    </Col>
                  </Row>
                </Col>
                <Col span={7} offset={1}>
                  <Row className="first-row">
                    <Col span={24} className="metrics-values">
                      <Title level={5}>{newVelocity} pts</Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="metrics-values">
                      <Title level={5}>{newHeadCount}</Title>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </StyledMetricsState>
          </Layout>
          {footer()}
        </Layout>
      </StyledSummaryView>
      {displayTeamDetails()}
      {displayDetailedView()}
    </>
  );
};

export default withRouter(SummaryView);
