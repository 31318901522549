import React, { FC } from "react";
import PropTypes from "prop-types";
import CPSPBubbleType from "../../types/cpsp-bubble-props";
import StyledCPSPBubble from "./StyledCPSPBubble";
import CPSPBubbleSizeEnum from "../../assets/constants/CPSPSizeEnum";

const CPSPBubble: FC<CPSPBubbleType> = ({
  value,
  color,
  backgroundColor,
  size = CPSPBubbleSizeEnum.LARGE,
}) => {
  return (
    <StyledCPSPBubble
      color={color}
      backgroundColor={backgroundColor}
      size={size}
    >
      ${value}
    </StyledCPSPBubble>
  );
};

CPSPBubble.defaultProps = {
  backgroundColor: "#e6e6e6",
  size: CPSPBubbleSizeEnum.LARGE,
};
CPSPBubble.propTypes = {
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  size: PropTypes.oneOf([96, 80]),
};

export default CPSPBubble;
