import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button, Modal, Typography } from "antd";
import PropTypes from "prop-types";
import EditTeamModalProps from "../../types/edit-team-modal-props";
import { useAppContext } from "../../Context/context";
import EditTeamForm from "../EditTeamForm/EditTeamForm";
import { editTeamAction } from "../../Context/actions";

const { Title } = Typography;

const EditTeamModal: FC<EditTeamModalProps> = ({ close }) => {
  const {
    dispatch,
    state: {
      selectedTeam,
      teams,
      roles,
      resources,
      teamScenario,
      teamScenarioResource,
    },
  } = useAppContext();
  const [name, setName] = useState<string>("");
  const [scenarioId, setScenarioId] = useState<string>("");
  const [velocity, setVelocity] = useState<number>(100);
  const [weeksPerSprint, setWeeksPerSprint] = useState<number>(2);
  const [scenarioCount, setScenarioCount] = useState<number>(0);

  const getTeam = useCallback(() => {
    return teams.find((team) => team.id === selectedTeam);
  }, [selectedTeam, teams]);

  useEffect(() => {
    const currentTeam = getTeam();
    const teamScenarios = teamScenario.filter(
      (scenario) => scenario.teamId === selectedTeam
    );
    const selectedScenario = teamScenarios.find(
      (scenario) => scenario.teamOrder === 0
    );

    if (selectedScenario) {
      setScenarioId(selectedScenario.id);
    }
    if (currentTeam) {
      setName(currentTeam?.name || "");
      setVelocity(currentTeam.velocity);
      setWeeksPerSprint(currentTeam.weeksPerSprint);
    }
    setScenarioCount(teamScenarios.length);
  }, [
    getTeam,
    resources,
    roles,
    scenarioId,
    selectedTeam,
    teamScenario,
    teamScenarioResource,
  ]);

  const onChangeTeamName = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setName(value);
  };

  const onChangeVelocity = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setVelocity(Number(value));
  };

  const onChangeWeeksPerSprint = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setWeeksPerSprint(Number(value));
  };

  const saveEditTeam = () => {
    const currentTeam = getTeam();
    if (currentTeam === undefined) {
      close();
      return;
    }
    const editedTeam = {
      ...currentTeam,
      name,
      velocity,
      weeksPerSprint,
    };
    dispatch(editTeamAction(selectedTeam, editedTeam));
    close();
  };

  return (
    <Modal
      visible
      onCancel={close}
      title={
        <Title level={3} style={{ marginBottom: 0 }}>
          Edit Team
        </Title>
      }
      centered
      data-cy="editTeamModal"
      footer={[
        <Button key="back" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={saveEditTeam}
          data-cy="editTeamSubmitBtn"
        >
          Save
        </Button>,
      ]}
    >
      <EditTeamForm
        name={name || ""}
        onChangeTeamName={onChangeTeamName}
        onChangeTeamVelocity={onChangeVelocity}
        onChangeWeeksPerSprint={onChangeWeeksPerSprint}
        velocity={velocity || 100}
        weeksPerSprint={weeksPerSprint || 2}
        scenarios={scenarioCount}
      />
    </Modal>
  );
};

EditTeamModal.propTypes = {
  close: PropTypes.func.isRequired,
};

export default EditTeamModal;
