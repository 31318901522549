import React, { FC, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Col, Modal, Row, Input, Divider, Button } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import AssignToTeamModalProps from "../../types/assign-to-team-modal-props";
import RoleAmountProps from "../../types/role-amount-props";
import UserIcon from "../UserIcon/UserIcon";
import UserIconSizeEnum from "../../assets/constants/UserIconSizeEnum";
import {
  addTeamWithAvailableResourcesAction,
  assignResourcesToTeamAction,
} from "../../Context/actions";
import { useAppContext } from "../../Context/context";
import EditTeamForm from "../EditTeamForm/EditTeamForm";
import TeamProps from "../../types/team-props";
import TeamValuesEnum from "../../assets/constants/TeamValuesEnum";
import StyledAssignToTeamModal from "./StyledAssignToTeamModal";
import {
  RoleSeniority,
  RoleSeniorityList,
} from "../../assets/constants/RoleSeniority";
import { convertRoleNameToPascal } from "../../helpers/roles";

const { Text, Title } = Typography;

const reducer = (prev: number, curr: number) => curr + prev;

const AssignToTeamModal: FC<AssignToTeamModalProps> = ({
  team,
  resources,
  toggleVisibility,
  mode,
}) => {
  const { dispatch } = useAppContext();
  const [assignedRoleToTeam, setAssignedRoleToTeam] = useState<
    RoleAmountProps[]
  >([]);
  const [teamFields, setTeamFields] = useState<TeamProps>({
    name: "Bench Team",
    weeksPerSprint: Number(TeamValuesEnum.DEFAULT_WEEKS_PER_SPRINT),
    velocity: Number(TeamValuesEnum.DEFAULT_VELOCITY),
    id: "",
  });

  useEffect(() => {
    setAssignedRoleToTeam(
      resources.map((resource) => ({
        ...resource,
        amount: { junior: 0, mid: 0, senior: 0 }, // TODO: to be fixed in CPSP-311
      }))
    );
  }, [resources, mode, teamFields]);

  const assignRoleToTeam = (
    roleId: string,
    seniority: RoleSeniority,
    amount: string,
    limit: number
  ) => {
    const newAmount = Number(amount);
    if (newAmount <= limit && newAmount >= 0) {
      const newAssignedRole = assignedRoleToTeam.map((assignedRole) => {
        if (assignedRole.role.id === roleId) {
          return {
            ...assignedRole,
            amount: {
              ...assignedRole.amount,
              [seniority]: Number(amount),
            },
          };
        }
        return assignedRole;
      });
      setAssignedRoleToTeam(newAssignedRole);
    }
  };

  const save = () => {
    if (team && mode === "sendToTeam") {
      dispatch(assignResourcesToTeamAction(team.id, assignedRoleToTeam));
      toggleVisibility();
    } else {
      dispatch(
        addTeamWithAvailableResourcesAction(teamFields, assignedRoleToTeam)
      );
      toggleVisibility();
    }
  };

  const onChangeField = (fieldName: string, fieldValue: string) => {
    if (fieldName !== "name") {
      setTeamFields({ ...teamFields, [fieldName]: Number(fieldValue) });
    } else {
      setTeamFields({ ...teamFields, [fieldName]: fieldValue });
    }
  };

  const assignRemainingResource = (
    roleId: string,
    seniority: RoleSeniority,
    totalAvaialable: number
  ) => {
    const newAssignedRoleToTeam = assignedRoleToTeam.map((assignedRole) => {
      if (assignedRole.role.id !== roleId) {
        return assignedRole;
      }
      return {
        ...assignedRole,
        amount: {
          ...assignedRole.amount,
          [seniority]: totalAvaialable,
        },
      };
    });
    setAssignedRoleToTeam(newAssignedRoleToTeam);
  };

  const assignAllResource = () => {
    setAssignedRoleToTeam(resources);
  };

  const calculateAsssignedResource = (role: RoleAmountProps) => {
    const roleAmount = assignedRoleToTeam.find(
      (r) => r.role.id === role.role.id
    );
    if (!roleAmount) return "0.00";
    const total = RoleSeniorityList.map(
      (seniority) => roleAmount.amount[seniority]
    ).reduce(reducer, 0);
    return total.toFixed(2);
  };

  const calculateAssignedResources = (resources1: RoleAmountProps[]) => {
    const total = resources1
      .map((resource) => {
        const subTotal = RoleSeniorityList.map(
          (seniority) => resource.amount[seniority]
        ).reduce(reducer, 0);
        return subTotal;
      })
      .reduce(reducer, 0);
    return total;
  };

  const calculateSeniorityResources = (
    resources1: RoleAmountProps[],
    seniority: RoleSeniority
  ) => {
    const total = resources1
      .map((resource) => {
        return resource.amount[seniority];
      })
      .reduce(reducer, 0);
    return total;
  };

  const enableSubmitBtn = () => {
    const assignedResources = calculateAssignedResources(assignedRoleToTeam);
    if (mode === "sendToTeam") return assignedResources > 0;
    return (
      assignedResources > 0 &&
      teamFields.name &&
      teamFields.velocity &&
      teamFields.weeksPerSprint
    );
  };

  return (
    <Modal
      visible
      title={
        <Title level={3}>
          {mode === "createTeam" ? "Create Team" : "Send to team"}
        </Title>
      }
      onCancel={toggleVisibility}
      onOk={save}
      width={900}
      okButtonProps={{ disabled: !enableSubmitBtn() }}
    >
      {team && mode === "sendToTeam" && (
        <Text className="teamNameLbl">{team.name.toUpperCase()}</Text>
      )}
      {mode === "createTeam" && (
        <EditTeamForm
          name={teamFields.name}
          velocity={teamFields.velocity}
          weeksPerSprint={teamFields.weeksPerSprint}
          onChangeTeamName={(event) =>
            onChangeField("name", event.target.value)
          }
          onChangeTeamVelocity={(event) =>
            onChangeField("velocity", event.target.value)
          }
          onChangeWeeksPerSprint={(event) =>
            onChangeField("weeksPerSprint", event.target.value)
          }
          scenarios={0}
        />
      )}
      <StyledAssignToTeamModal>
        <Row align="middle">
          <Col>
            You can click on each <DoubleRightOutlined /> button to assign all
            resources per role
          </Col>
        </Row>
        <Row justify="space-between" align="middle" gutter={[16, 40]}>
          <Col>
            <Title level={5}>Available Resources</Title>
          </Col>
          <Col>
            <Button onClick={assignAllResource} className="assignAllBtn">
              ASSIGN ALL
            </Button>
          </Col>
        </Row>
        <Row justify="center" className="resources-titles">
          <Col span={6} />
          {RoleSeniorityList.map((roleSeniority) => (
            <Col span={5} className="resources-titles-seniority">
              {convertRoleNameToPascal(roleSeniority)}
            </Col>
          ))}
          <Col span={2} className="resources-titles-seniority">
            Assigning
          </Col>
        </Row>
        {resources
          .filter((resource) => !resource.role.showNumber8UserIcon)
          .map((resource) => {
            const assignedResource = assignedRoleToTeam.find(
              (assignedRole) => assignedRole.role.id === resource.role.id
            );
            if (!assignedResource) return null;
            return (
              <Row
                key={resource.role.id}
                align="middle"
                justify="space-between"
                className="role"
              >
                <Col span={1}>
                  <UserIcon
                    color={resource.role.color}
                    showNumber8UserIcon={resource.role.showNumber8UserIcon}
                    size={UserIconSizeEnum.SMALL}
                  />
                </Col>
                <Col span={4}>{resource.role.name}</Col>
                <Col span={1}>x</Col>
                {RoleSeniorityList.map((resourceAmountKey) => (
                  <>
                    <Col span={1}>
                      {resource.amount[resourceAmountKey].toFixed(2)}
                    </Col>
                    <Col span={1}>
                      <Button
                        icon={<DoubleRightOutlined />}
                        disabled={
                          resource.amount[resourceAmountKey] -
                            assignedResource.amount[resourceAmountKey] <=
                          0
                        }
                        onClick={() =>
                          assignRemainingResource(
                            resource.role.id,
                            resourceAmountKey,
                            resource.amount[resourceAmountKey]
                          )
                        }
                      />
                    </Col>
                    <Col span={2}>
                      <Input
                        value={assignedResource.amount[resourceAmountKey]}
                        max={resource.amount[resourceAmountKey]}
                        min={0}
                        disabled={resource.amount[resourceAmountKey] <= 0}
                        onChange={(event) =>
                          assignRoleToTeam(
                            resource.role.id,
                            resourceAmountKey,
                            event.target.value,
                            resource.amount[resourceAmountKey]
                          )
                        }
                        type="number"
                      />
                    </Col>
                  </>
                ))}
                <Col span={2}>{calculateAsssignedResource(resource)}</Col>
              </Row>
            );
          })}
        <Divider />
        <Row>
          <Col>Team members</Col>
          {RoleSeniorityList.map((seniority) => (
            <Col span={5} push={4}>
              {calculateSeniorityResources(resources, seniority)}
            </Col>
          ))}
          <Col span={5} push={4}>
            {calculateAssignedResources(assignedRoleToTeam)}
          </Col>
        </Row>
      </StyledAssignToTeamModal>
    </Modal>
  );
};

AssignToTeamModal.defaultProps = {
  team: {
    id: "",
    name: "",
    weeksPerSprint: 2,
    velocity: 100,
  },
};

AssignToTeamModal.propTypes = {
  team: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    weeksPerSprint: PropTypes.number.isRequired,
    velocity: PropTypes.number.isRequired,
  }),
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        showNumber8UserIcon: PropTypes.bool.isRequired,
        annualSalary: PropTypes.shape({
          junior: PropTypes.number.isRequired,
          mid: PropTypes.number.isRequired,
          senior: PropTypes.number.isRequired,
        }).isRequired,
        isReadOnly: PropTypes.bool.isRequired,
      }).isRequired,
      amount: PropTypes.shape({
        junior: PropTypes.number.isRequired,
        mid: PropTypes.number.isRequired,
        senior: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  toggleVisibility: PropTypes.func.isRequired,
};

export default AssignToTeamModal;
