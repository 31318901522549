import React, { FC, useState } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import StyledNumber8User, { StyledUserIcon } from "./StyledUserIcon";
import { ReactComponent as StandingUserIcon } from "../../assets/icons/standing-user.svg";
import { ReactComponent as Number8Icon } from "../../assets/icons/8.svg";
import UserIconProps from "../../types/user-icon-props";
import UserIconSizeEnum from "../../assets/constants/UserIconSizeEnum";
import { RoleSeniorityList } from "../../assets/constants/RoleSeniority";
import { ChevronIcon } from "./ChevronIcon";

const UserIcon: FC<UserIconProps> = ({
  showNumber8UserIcon,
  color,
  size = UserIconSizeEnum.LARGE,
  name,
  seniority,
  resourceAllocation = 1,
  withResourceAllocationShadow = false,
  isMobile,
}) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTimer, setTooltipTimer] = useState<NodeJS.Timeout>();

  function getSize(): string {
    return `${size}px`;
  }

  function getWidth(): string {
    if (size === UserIconSizeEnum.EXTRA_SMALL) {
      return `${UserIconSizeEnum.EXTRA_SMALL}px`;
    }
    return "50px";
  }

  function getN8Height(): string {
    switch (size) {
      case UserIconSizeEnum.EXTRA_SMALL:
        return "7px";
      case UserIconSizeEnum.SMALL:
        return "10px";
      default:
        return "13px";
    }
  }

  function getN8Width(): string {
    switch (size) {
      case UserIconSizeEnum.EXTRA_SMALL:
        return "7px";
      case UserIconSizeEnum.SMALL:
        return "10px";
      default:
        return "16px";
    }
  }

  const getTooltipTitle = () => {
    if (resourceAllocation === 1) {
      return name;
    }
    return `${name}, ${resourceAllocation * 100}%`;
  };

  const onTooltipVisibilityChange = (visible: boolean) => {
    if (visible) {
      const timer = setTimeout(() => setTooltipVisible(false), 1000);
      setTooltipVisible(true);
      setTooltipTimer(timer);
    } else {
      setTooltipVisible(false);
      if (tooltipTimer) clearTimeout(tooltipTimer);
    }
  };

  const renderIcon = () => {
    if (showNumber8UserIcon) {
      if (withResourceAllocationShadow) {
        return (
          <StyledNumber8User
            resourceAllocation={resourceAllocation}
            height={getSize()}
            width={getWidth()}
          >
            <StandingUserIcon
              style={{ height: getSize(), width: getWidth() }}
              fill={color}
              className="standing-user-icon-n8"
            />
            {seniority && <ChevronIcon seniority={seniority} size={size} />}
            <Number8Icon
              className="n8-icon"
              style={{ height: "15px", width: getN8Width() }}
              fill="#FFF"
            />
            <svg viewBox="0 0 32 32" className="shadow">
              <circle r="16" cx="16" cy="16" />
            </svg>
          </StyledNumber8User>
        );
      }
      return (
        <StyledNumber8User
          resourceAllocation={resourceAllocation}
          height={getSize()}
          width={getWidth()}
        >
          <StandingUserIcon
            style={{ height: getSize(), width: getWidth() }}
            fill={color}
            className="standing-user-icon-n8"
          />
          {seniority && <ChevronIcon seniority={seniority} size={size} />}
          <Number8Icon
            className="n8-icon"
            height={getN8Height()}
            width={getN8Width()}
            fill="#FFF"
          />
        </StyledNumber8User>
      );
    }
    if (withResourceAllocationShadow) {
      return (
        <StyledUserIcon
          resourceAllocation={resourceAllocation}
          height={getSize()}
          width={getWidth()}
        >
          <StandingUserIcon
            style={{ height: getSize(), width: getWidth() }}
            fill={color}
          />
          {seniority && <ChevronIcon seniority={seniority} size={size} />}
          <svg viewBox="0 0 32 32" className="shadow completeAvatar">
            <circle r="16" cx="16" cy="16" />
          </svg>
          <svg viewBox="0 0 32 32" className="shadow incompleteAvatar">
            <circle r="16" cx="16" cy="16" />
          </svg>
        </StyledUserIcon>
      );
    }
    return (
      <StyledUserIcon
        resourceAllocation={1}
        height={getSize()}
        width={getWidth()}
      >
        <StandingUserIcon
          style={{ height: getSize(), width: getWidth() }}
          fill={color}
        />
        {seniority && <ChevronIcon seniority={seniority} size={size} />}
      </StyledUserIcon>
    );
  };
  if (isMobile) return renderIcon();
  return (
    <Tooltip
      title={getTooltipTitle}
      visible={isTooltipVisible}
      onVisibleChange={onTooltipVisibilityChange}
    >
      {renderIcon()}
    </Tooltip>
  );
};

UserIcon.defaultProps = {
  size: UserIconSizeEnum.LARGE,
  name: "",
  resourceAllocation: 1,
  withResourceAllocationShadow: false,
  isMobile: false,
  seniority: undefined,
};

UserIcon.propTypes = {
  color: PropTypes.string.isRequired,
  showNumber8UserIcon: PropTypes.bool.isRequired,
  seniority: PropTypes.oneOf(RoleSeniorityList),
  size: PropTypes.oneOf([50, 30]),
  name: PropTypes.string,
  resourceAllocation: PropTypes.number,
  withResourceAllocationShadow: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default UserIcon;
