import { Col, Row, Typography } from "antd";
import PropTypes from "prop-types";
import React, { FC } from "react";
import CPSPBubbleSizeEnum from "../../assets/constants/CPSPSizeEnum";
import UserIconSizeEnum from "../../assets/constants/UserIconSizeEnum";
import TeamStateSummaryViewProps from "../../types/team-state-summary-view-props";
import CPSPBubble from "../CPSPBubble/CPSPBubble";
import UserIcon from "../UserIcon/UserIcon";
import StyledTeamStateSummaryView from "./StyledTeamStateSummaryView";

const { Text } = Typography;

const MAX_MEMBERS = 12;

const TeamStateSummaryView: FC<TeamStateSummaryViewProps> = ({
  members,
  velocity,
  weeksPerSprint,
  teamName = "",
  cpsp,
  cpspColor = "#666666",
}) => {
  return (
    <StyledTeamStateSummaryView>
      <Row className="title">
        <Col>
          <Text strong>{teamName}</Text>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>Average Velocity</Col>
        <Col>{velocity}</Col>
      </Row>
      <Row justify="space-between">
        <Col>Weeks Per Sprint</Col>
        <Col>{weeksPerSprint}</Col>
      </Row>
      <Row justify="space-between">
        <Col>Team Members</Col>
        <Col>{members.length}</Col>
      </Row>
      <Row align="middle" justify="space-around" className="cpsp">
        <Col>CPSP</Col>
        <Col>
          <CPSPBubble
            color={cpspColor}
            value={cpsp}
            backgroundColor="#F7F4F4"
            size={CPSPBubbleSizeEnum.EXTRA_SMALL}
          />
        </Col>
      </Row>
      <div className="members">
        {members &&
          members.length > 0 &&
          members
            .slice(0, MAX_MEMBERS)
            .map((member) => (
              <UserIcon
                key={member.id}
                name={member.role?.name}
                color={member.role?.color || "#FFF"}
                size={UserIconSizeEnum.EXTRA_SMALL}
                showNumber8UserIcon={member.role?.showNumber8UserIcon || false}
                withResourceAllocationShadow={member.resourceAllocation < 1}
                resourceAllocation={member.resourceAllocation}
                seniority={member.seniority}
              />
            ))}
      </div>
    </StyledTeamStateSummaryView>
  );
};

TeamStateSummaryView.defaultProps = {
  teamName: "",
  cpspColor: "#666666",
};

TeamStateSummaryView.propTypes = {
  velocity: PropTypes.number.isRequired,
  weeksPerSprint: PropTypes.number.isRequired,
  teamName: PropTypes.string,
  cpsp: PropTypes.number.isRequired,
  cpspColor: PropTypes.string,
};
export default TeamStateSummaryView;
