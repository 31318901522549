import styled from "styled-components";

const StyledProTip = styled.div`
  width: fit-content;
  font-size: 12px;
  div {
    color: #666666;
    background: #f7f4f4;
    border-color: transparent;
    b {
      color: #f26522;
    }
  }
`;

export default StyledProTip;
