import React, { FC } from "react";
import { Input, Typography } from "antd";
import PropTypes from "prop-types";
import TeamScenarioNameProps from "../../types/team-scenario-name-props";

const { Paragraph } = Typography;
const { TextArea } = Input;

const TeamScenarioName: FC<TeamScenarioNameProps> = ({
  focused,
  onFocus,
  onBlurName,
  onChangeStateName,
  teamScenarioOrder,
  name,
  scenarioName,
  teamScenariosAmount,
}) => {
  return focused && teamScenarioOrder >= teamScenariosAmount - 1 ? (
    <TextArea
      onFocus={onFocus}
      onBlur={onBlurName}
      value={scenarioName}
      autoFocus
      onChange={onChangeStateName}
      className="teamStateName"
      data-cy={`state${teamScenarioOrder}NameInput`}
      autoSize={{ minRows: 2, maxRows: 2 }}
    />
  ) : (
    <Paragraph
      ellipsis={{ rows: 2 }}
      data-cy={`state${teamScenarioOrder}NameTitle`}
      onClick={onFocus}
      className="teamStateName"
      title={name}
    >
      {name}
    </Paragraph>
  );
};

TeamScenarioName.propTypes = {
  name: PropTypes.string.isRequired,
  teamScenarioOrder: PropTypes.number.isRequired,
  focused: PropTypes.bool.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlurName: PropTypes.func.isRequired,
  onChangeStateName: PropTypes.func.isRequired,
  scenarioName: PropTypes.string.isRequired,
  teamScenariosAmount: PropTypes.number.isRequired,
};

export default TeamScenarioName;
