import styled from "styled-components";

const StyledTeamStateSummaryView = styled.div`
  border: 1px solid #f7f4f4;
  margin-right: 4px;
  height: 260px;
  width: 130px;
  font-size: 10px;
  padding: 10px;
  > .title {
    font-size: 10px;
    margin-bottom: 10px;
    > .ant-col {
      overflow: hidden;
      height: 35px;
    }
  }
  > .members {
    margin: 10px 0;
    display: grid;
    grid-template-columns: repeat(4, 28px);
  }
  > .cpsp {
    margin-top: 10px;
    font-size: 10px;
  }
  .n8-icon {
    position: absolute;
    top: 40%;
    left: 37%;
  }
`;

export default StyledTeamStateSummaryView;
