import React, { FC, useState, useEffect, useCallback } from "react";
import { Modal, List, Button, Row, Col, Typography, Tooltip } from "antd";
import PropTypes from "prop-types";
import { PlusOutlined } from "@ant-design/icons";
import { useAppContext } from "../../Context/context";
import EditRoleRow from "./EditRoleRow";
import StyledEditRoleList from "./StyledEditRoleList";
import RoleProps, { RoleSalary } from "../../types/role-props";
import EditRolesModalProps from "../../types/edit-roles-modal-props";
import { deleteRoleAction, updateRoleAction } from "../../Context/actions";
import StyledTitle from "./StyledTitle";
import RoleFieldsEnum from "../../assets/constants/RoleFieldsEnum";
import {
  convertRoleNameToPascal,
  deleteRoles,
  modifyRole,
} from "../../helpers/roles";
import RoleChangeType from "../../types/role-change-type";
import RoleChangeEnum from "../../assets/constants/RoleChangeEnum";
import { teamsRoleIsBeingUsed } from "../../helpers/teams";
import { RoleSeniorityList } from "../../assets/constants/RoleSeniority";

const { Title } = Typography;

const EditRolesModal: FC<EditRolesModalProps> = ({
  closeModal,
  openAddRoleModal,
}) => {
  const {
    state: { roles, teamScenario, teamScenarioResource, resources },
    dispatch,
  } = useAppContext();

  const [isDeleteRoleConfirmationOpen, setIsDeleteRoleConfirmationOpen] =
    useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [selectedRoleColor, setSelectedRoleColor] = useState<string>("");
  const [changes, setChanges] = useState<RoleChangeType[]>([]);
  const [localRoles, setLocalRoles] = useState<RoleProps[]>(roles);
  const [amountOfTeamsUsingRole, setAmountOfTeamsUsingRole] =
    useState<number>(0);

  useEffect(() => {
    setLocalRoles(roles);
  }, [roles]);

  const commitChanges = useCallback(() => {
    changes.forEach((change) => {
      if (change.change === "update" && change.field && change.value) {
        dispatch(updateRoleAction(change.roleId, change.field, change.value));
      } else if (change.change === "delete") {
        dispatch(deleteRoleAction(change.roleId, selectedRoleColor));
      }
    });
    closeModal();
  }, [changes, closeModal, dispatch, selectedRoleColor]);

  useEffect(() => {
    const listener = (event: { code: string; preventDefault: () => void }) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        commitChanges();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [commitChanges]);

  const switchDeleteRoleConfirmation = () => {
    setIsDeleteRoleConfirmationOpen(!isDeleteRoleConfirmationOpen);
  };
  const handleDeleteRoleClick = (roleId: string, color: string) => {
    setSelectedRole(roleId);
    switchDeleteRoleConfirmation();
    setSelectedRoleColor(color);
    setAmountOfTeamsUsingRole(
      teamsRoleIsBeingUsed(
        teamScenario,
        teamScenarioResource,
        resources,
        roleId
      )
    );
  };

  const deleteRole = () => {
    const newChange: RoleChangeType = {
      roleId: selectedRole,
      change: RoleChangeEnum.DELETE,
    };
    setChanges(changes.concat(newChange));
    setLocalRoles(deleteRoles(localRoles, selectedRole));
    switchDeleteRoleConfirmation();
  };

  const updateRole = (
    roleId: string,
    field: RoleFieldsEnum,
    value: string | RoleSalary
  ) => {
    const newChange: RoleChangeType = {
      roleId,
      change: RoleChangeEnum.UPDATE,
      field,
      value,
    };
    setChanges(changes.concat(newChange));
    const newRoles = modifyRole(localRoles, roleId, field, value);
    setLocalRoles(newRoles);
  };

  const getDeleteRoleConfirmationText = () => {
    if (amountOfTeamsUsingRole > 0) {
      return `The Role you're trying to delete is in use within ${amountOfTeamsUsingRole} team(s)`;
    }
    return "You’re going to delete this role, are you sure?";
  };

  return (
    <Modal
      visible
      centered
      title={
        <Title level={3} style={{ marginBottom: 0 }}>
          Edit Roles
        </Title>
      }
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={commitChanges}
          data-cy="editRolesSubmitBtn"
        >
          Save
        </Button>,
      ]}
      data-cy="editRolesModal"
      width={900}
    >
      <div
        role="button"
        tabIndex={0}
        onKeyDown={({ code }) => {
          if (code === "Enter") {
            if (isDeleteRoleConfirmationOpen) {
              deleteRole();
            } else {
              commitChanges();
            }
          }
        }}
      >
        <Row style={{ height: 30 }} align="middle">
          <Col span={4} offset={2}>
            <StyledTitle>Role Name</StyledTitle>
          </Col>
          <Col span={4}>
            <StyledTitle>Role Abbreviation</StyledTitle>
          </Col>
          {RoleSeniorityList.map((roleSeniority) => (
            <Col span={4}>
              <StyledTitle>
                {convertRoleNameToPascal(roleSeniority)}
              </StyledTitle>
            </Col>
          ))}
          <Col span={1}>
            <Tooltip title="Create Role">
              <Button
                icon={<PlusOutlined />}
                onClick={openAddRoleModal}
                data-cy="createRoleInEditRolesModalBtn"
              />
            </Tooltip>
          </Col>
        </Row>
        <StyledEditRoleList data-cy="editRolesList">
          <List
            size="small"
            dataSource={localRoles}
            renderItem={({
              annualSalary,
              color,
              showNumber8UserIcon,
              name,
              abbreviation,
              id,
              isReadOnly,
            }: RoleProps) => (
              <List.Item
                key={id}
                style={{ borderBottom: 0, paddingBottom: 0, paddingTop: 5 }}
              >
                <EditRoleRow
                  annualSalary={annualSalary}
                  color={color}
                  showNumber8UserIcon={showNumber8UserIcon}
                  name={name}
                  abbreviation={abbreviation}
                  id={id}
                  deleteRole={handleDeleteRoleClick}
                  updateRole={updateRole}
                  isReadOnly={isReadOnly}
                />
              </List.Item>
            )}
          />
        </StyledEditRoleList>
        {isDeleteRoleConfirmationOpen && (
          <Modal
            visible
            onCancel={switchDeleteRoleConfirmation}
            footer={[
              <Button key="back" onClick={switchDeleteRoleConfirmation}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={deleteRole}
                data-cy="deleteRoleConfirmationBtn"
                autoFocus
              >
                Delete
              </Button>,
            ]}
          >
            {getDeleteRoleConfirmationText()}
          </Modal>
        )}
      </div>
    </Modal>
  );
};

EditRolesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openAddRoleModal: PropTypes.func.isRequired,
};
export default EditRolesModal;
