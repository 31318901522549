import React, { FC } from "react";
import PropTypes from "prop-types";
import VarianceCostType from "../../types/variance-cost";
import StyledVarianceCost from "./StyledVarianceCost";

const VarianceCost: FC<VarianceCostType> = ({
  variance,
  varianceColor,
}): JSX.Element => {
  return (
    <StyledVarianceCost color={varianceColor}>
      ${variance / 1000}K
    </StyledVarianceCost>
  );
};

VarianceCost.propTypes = {
  variance: PropTypes.number.isRequired,
  varianceColor: PropTypes.string.isRequired,
};
export default VarianceCost;
