import styled from "styled-components";

const StyledFutureStateBanner = styled.div`
  background-color: #e6e6e6;
  border-radius: 15px;
  height: 95px;
  margin: 0 5px;
  padding: 8px;
  margin-bottom: 10px;
  .currentStateTotal {
    display: flex;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 80px;
    > .ant-row > .ant-col {
      > h5 {
        font-size: 14px;
      }
      > div {
        height: 60px;
        width: 60px;
        line-height: 60px;
      }
    }
  }
`;

export default StyledFutureStateBanner;
