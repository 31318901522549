import React, { FC } from "react";
import PropTypes from "prop-types";
import { Col, Input, Row, Typography } from "antd";
import EditTeamFormProps from "../../types/edit-team-form-props";
import StyledEditTeamForm from "./StyledEditTeamForm";

const { Text } = Typography;

const EditTeamForm: FC<EditTeamFormProps> = ({
  name,
  velocity,
  weeksPerSprint,
  onChangeTeamName,
  onChangeTeamVelocity,
  onChangeWeeksPerSprint,
  scenarios,
}) => {
  return (
    <StyledEditTeamForm>
      <Row>
        <Col span={4}>
          <Text className="editTeamLabel">
            Team Name<span className="required">*</span>
          </Text>
        </Col>
        <Col span={20}>
          <Input
            value={name}
            onChange={onChangeTeamName}
            placeholder="Team Name"
            data-cy="teamNameInput"
            autoFocus
          />
        </Col>
      </Row>
      <Row style={{ lineHeight: "30px", marginBottom: "10px" }}>
        <Col span={6}>
          <Text className="editTeamLabel">Average Velocity</Text>
        </Col>
        <Col span={4}>
          <Input
            value={velocity}
            onChange={onChangeTeamVelocity}
            size="middle"
            data-cy="teamVelocityInput"
            type="number"
          />
        </Col>
        <Col span={6} offset={1}>
          <Text className="editTeamLabel">Weeks Per Sprint</Text>
        </Col>
        <Col span={3} offset={1}>
          <Input
            value={weeksPerSprint}
            onChange={onChangeWeeksPerSprint}
            size="middle"
            data-cy="teamWeeksPerSprintInput"
            type="number"
            disabled={scenarios > 1}
          />
        </Col>
      </Row>
    </StyledEditTeamForm>
  );
};

EditTeamForm.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeTeamName: PropTypes.func.isRequired,
  velocity: PropTypes.number.isRequired,
  onChangeTeamVelocity: PropTypes.func.isRequired,
  weeksPerSprint: PropTypes.number.isRequired,
  onChangeWeeksPerSprint: PropTypes.func.isRequired,
  scenarios: PropTypes.number.isRequired,
};

export default EditTeamForm;
