import React, { FC, useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import OktaSignInWidgetProps from "../../types/okta-sign-in-widget-props";

const OktaSignInWidget: FC<OktaSignInWidgetProps> = ({
  config,
  onSuccess,
  onError,
}) => {
  const widgetRef = useRef(null);

  useEffect(() => {
    if (widgetRef.current) {
      const widget = new OktaSignIn(config);

      widget
        .showSignInToGetTokens({
          el: widgetRef.current,
        })
        .then(onSuccess)
        .catch(onError);

      return () => widget.remove();
    }
    return () => {};
  }, [config, onSuccess, onError]);

  return <div ref={widgetRef} />;
};

export default OktaSignInWidget;
