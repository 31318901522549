import { ReactElement, ReactNode } from "react";

type ContextMenuProps = {
  menu: () => ReactElement;
  children: ReactNode;
  trigger: TriggerOptions[];
  isOpen?: boolean;
  setMenuOpen?: (isOpen: boolean) => void;
};

export enum TriggerOptions {
  CLICK = "click",
  HOVER = "hover",
  CONTEXT_MENU = "contextMenu",
}

export default ContextMenuProps;
