import React, { FC } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import StyledRoleName from "./StyledRoleName";
import UserIcon from "../UserIcon/UserIcon";
import RoleProps from "../../types/role-props";

const Role: FC<RoleProps> = ({
  annualSalary,
  name,
  color,
  showNumber8UserIcon,
  id,
}) => {
  return (
    <Row key={id} data-cy={`roleListItem-${id}`} style={{ width: "100%" }}>
      <Col span={3}>
        <UserIcon
          color={color}
          showNumber8UserIcon={showNumber8UserIcon}
          name={name}
        />
      </Col>
      <Col span={8}>
        <StyledRoleName className="role_name">
          <div>{name}</div>
        </StyledRoleName>
      </Col>
      <Col span={3} offset={1}>
        <StyledRoleName className="role_salary">
          <div>${annualSalary.junior / 1000}K</div>
        </StyledRoleName>
      </Col>
      <Col span={3} offset={1}>
        <StyledRoleName className="role_salary">
          <div>${annualSalary.mid / 1000}K</div>
        </StyledRoleName>
      </Col>
      <Col span={3} offset={1}>
        <StyledRoleName className="role_salary">
          <div>${annualSalary.senior / 1000}K</div>
        </StyledRoleName>
      </Col>
    </Row>
  );
};

Role.propTypes = {
  annualSalary: PropTypes.shape({
    junior: PropTypes.number.isRequired,
    mid: PropTypes.number.isRequired,
    senior: PropTypes.number.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  showNumber8UserIcon: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

export default Role;
