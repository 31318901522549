import styled from "styled-components";
import StyledCanvasProps from "../../types/styled-canvas-props";

const StyledCanvas = styled.div<StyledCanvasProps>`
  // width switched from "70vw" to "" to allow growth unconstrained. pp.
  width: ${(props) => (props.isFullContent ? "90vw" : "")};
  .currentStateNameTab {
    display: flex;
  }
  > .actions {
    margin-bottom: 10px;
    > .ant-divider {
      height: 30px;
      margin-right: 10px;
    }
    > * {
      margin-right: 5px;
    }
  }

  .anticon-hover:hover {
    border-radius: 40%;
    color: #666;
    background: #e6e6e6;
  }

  @media screen and (min-width: 1400px) {
    height: 90.5vh;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    width: 370px;
    > .tabs {
      height: 100%;
      width: ${(props) => (props.isFullContent ? "700px" : "370px")};
    }
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    width: 500px;
    height: 665px;
    > .tabs {
      height: 100%;
      width: ${(props) => (props.isFullContent ? "920px" : "615px")};
    }
  }
  margin: 20px;
  .ant-tabs
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab
    > .ant-tabs-tab-btn
    > .createTeamBtn {
    margin-right: 0;
  }
  > .ant-tabs > .ant-tabs-nav > .ant-tabs-extra-content > .canvasAddTeamBtn {
    background-color: #fafafa;
    border: 1px solid #f0f0f0;
    margin-right: 2px;
  }
`;

export default StyledCanvas;
