import React, { ChangeEvent, FC, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Input, Modal, Row, Typography } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import StyledCreateSessionModal from "./StyledCreateSessionModal";
import { setClientAction, updateClientInfoAction } from "../../Context/actions";
import { useAppContext } from "../../Context/context";
import CreateSessionModalProps from "../../types/create-session-modal-props";

const { Text } = Typography;

const CreateSessionModal: FC<CreateSessionModalProps> = ({
  onSubmit,
  isUpdatingClient,
}) => {
  const {
    dispatch,
    state: { client },
  } = useAppContext();

  const [companyName, setCompanyName] = useState<string>("");
  const [companyContact, setCompanyContact] = useState<string>("");
  const [imageList, setImageList] = useState<UploadFile[]>();

  useEffect(() => {
    if (client && isUpdatingClient) {
      setCompanyName(client.name);
      setCompanyContact(client.contact);
    }
  }, [client, isUpdatingClient]);

  const onChangeCompanyName = (event: ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event.target.value);
  };

  const onChangeCompanyContact = (event: ChangeEvent<HTMLInputElement>) => {
    setCompanyContact(event.target.value);
  };

  const onUploadCompanyLogo = () => {
    return false;
  };

  const onChangeCompanyLogo = (info: UploadChangeParam) => {
    setImageList(info.fileList);
  };

  const submit = async () => {
    const didntUploadImage = !imageList || !imageList[0];
    if (!isUpdatingClient) {
      if (didntUploadImage) {
        dispatch(setClientAction(companyName, companyContact, ""));
        onSubmit();
        return;
      }
      const file = imageList[0].originFileObj as Blob;
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader && reader.result) {
          dispatch(
            setClientAction(
              companyName,
              companyContact,
              reader.result as string
            )
          );
          onSubmit();
        }
      };
      reader.readAsDataURL(file);
      return;
    }
    if (didntUploadImage && client) {
      dispatch(
        updateClientInfoAction(companyName, companyContact, client.image)
      );
      onSubmit();
      return;
    }
    if (!didntUploadImage) {
      const file = imageList[0].originFileObj as Blob;
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader && reader.result) {
          dispatch(
            updateClientInfoAction(
              companyName,
              companyContact,
              reader.result as string
            )
          );
          onSubmit();
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const disableSubmit = (): boolean => {
    if (isUpdatingClient) {
      return !(companyName && companyContact);
    }
    return !(companyName && companyContact);
  };

  return (
    <Modal
      visible
      title="Add client's info"
      onOk={submit}
      onCancel={onSubmit}
      footer={[
        <Button key="back" onClick={onSubmit}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={submit}
          disabled={disableSubmit()}
        >
          Save
        </Button>,
      ]}
    >
      <StyledCreateSessionModal>
        <Row justify="start" align="middle">
          <Col span="4">
            <Text>Company</Text>
          </Col>
          <Col span="20">
            <Input
              value={companyName}
              onChange={onChangeCompanyName}
              placeholder="Company Name"
            />
          </Col>
        </Row>
        <Row justify="start" align="middle">
          <Col span="4">
            <Text>Contact</Text>
          </Col>
          <Col span="20">
            <Input
              value={companyContact}
              onChange={onChangeCompanyContact}
              placeholder="Contact Name"
            />
          </Col>
        </Row>
        <Row justify="start">
          <Col>Company Logo</Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <Dragger
              name="file"
              multiple={false}
              maxCount={1}
              accept="image/*"
              beforeUpload={onUploadCompanyLogo}
              onChange={onChangeCompanyLogo}
            >
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </Col>
        </Row>
        <Row justify="center" gutter={[8, 48]}>
          <Col>
            <p className="ant-upload-text">
              Make sure to upload a logo of at least width: 330px, height: 220px
            </p>
          </Col>
        </Row>
      </StyledCreateSessionModal>
    </Modal>
  );
};

CreateSessionModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUpdatingClient: PropTypes.bool.isRequired,
};

export default CreateSessionModal;
