enum CPSPBubbleSizeEnum {
  LARGE = 96,
  SMALL = 80,
  EXTRA_SMALL = 36,
}

enum CPSPBubbleFontSizeEnum {
  LARGE = 28,
  SMALL = 20,
  EXTRA_SMALL = 14,
}

export { CPSPBubbleFontSizeEnum };

export default CPSPBubbleSizeEnum;
