import styled from "styled-components";

const StyledResourceCounter = styled.div`
  width: 500px;
  > .ant-row > .ant-col > div {
    height: 35px;
  }
  > .titles {
    margin-bottom: 20px;
  }
`;

export default StyledResourceCounter;
