import { State } from "../types/context-props";
import { ProTipsType } from "../types/pro-tips";

class HistoryTracker {
  history: State[] = [];

  future: State[] = [];

  ITEM_LIMIT = 10;

  constructor(history: State[] = [], future: State[] = []) {
    this.history = history;
    this.future = future;
  }

  isHistoryFull(): boolean {
    return this.history.length === this.ITEM_LIMIT;
  }

  isHistoryEmpty(): boolean {
    return this.history.length === 0;
  }

  isFutureFull(): boolean {
    return this.future.length === this.ITEM_LIMIT;
  }

  isFutureEmpty(): boolean {
    return this.future.length === 0;
  }

  resetFuture(): void {
    this.future = [];
  }

  add(item: State, redone = false): void {
    if (!this.isHistoryEmpty() && this.isHistoryFull()) {
      this.history.splice(0, 1);
    }
    this.history.push(item);
    if (!redone) {
      this.resetFuture();
    }
  }

  addToFuture(item: State): void {
    if (!this.isFutureEmpty() && this.isFutureFull()) {
      this.future.pop();
    }
    this.future.push(item);
  }

  peek(): State {
    return this.history[this.history.length - 1];
  }

  undo(): State {
    if (this.isHistoryEmpty())
      return {
        roles: [],
        colors: [],
        teams: [],
        selectedTeam: "",
        resources: [],
        teamScenario: [],
        teamScenarioResource: [],
        isSaved: true,
        showProTips: {
          [ProTipsType.multiSelect]: true,
          [ProTipsType.changeLogo]: true,
        },
      };
    const lastHistory = this.history.pop();
    if (lastHistory === undefined) {
      return {
        roles: [],
        colors: [],
        teams: [],
        selectedTeam: "",
        resources: [],
        teamScenario: [],
        teamScenarioResource: [],
        isSaved: true,
        showProTips: {
          [ProTipsType.multiSelect]: true,
          [ProTipsType.changeLogo]: true,
        },
      };
    }
    this.addToFuture(lastHistory);
    return lastHistory;
  }

  redo(): void {
    if (!this.isFutureEmpty()) {
      const lastItemUndone = this.future.pop();
      if (lastItemUndone) {
        this.add(lastItemUndone, true);
      }
    }
  }
}

function handleStateHistory(
  history: State[] = [],
  future: State[] = [],
  action: "add" | "redo" | "undo" = "add",
  newItem: State = {
    colors: [],
    roles: [],
    selectedTeam: "",
    teams: [],
    resources: [],
    teamScenario: [],
    teamScenarioResource: [],
    isSaved: true,
    showProTips: {
      [ProTipsType.multiSelect]: true,
      [ProTipsType.changeLogo]: true,
    },
  }
): HistoryTracker {
  const historyTracker = new HistoryTracker(history, future);
  if (newItem && newItem.teams && newItem.teams.length > 0) {
    if (action === "add") {
      historyTracker.add(newItem);
    }
  } else if (action === "redo") {
    historyTracker.redo();
  } else if (action === "undo") {
    historyTracker.undo();
  }
  return historyTracker;
}

export { handleStateHistory };

export default HistoryTracker;
