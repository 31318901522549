import React from "react";
import { RoleSeniority } from "../../assets/constants/RoleSeniority";
import UserIconSizeEnum from "../../assets/constants/UserIconSizeEnum";
import StyledChevronIcon from "./StyledChevronIcon";

const seniorityToNumber: { [key in RoleSeniority]: number } = {
  [RoleSeniority.JUNIOR]: 1,
  [RoleSeniority.MID]: 2,
  [RoleSeniority.SENIOR]: 3,
};

const ChevronIcon = ({
  seniority,
  size = UserIconSizeEnum.LARGE,
}: {
  seniority: RoleSeniority;
  size?: UserIconSizeEnum;
}): JSX.Element => {
  const insigniaAmount = seniorityToNumber[seniority];
  const renderInsignia = (): JSX.Element[] => {
    const insignia = [];
    for (let i = 0; i < insigniaAmount; i += 1) {
      insignia.push(<span>{">"}</span>);
    }
    return insignia;
  };
  return <StyledChevronIcon size={size}>{renderInsignia()}</StyledChevronIcon>;
};

ChevronIcon.defaultProps = {
  size: UserIconSizeEnum.LARGE,
};

export { ChevronIcon };
