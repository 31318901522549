import { nanoid } from "nanoid";
import RoleFieldsEnum from "../assets/constants/RoleFieldsEnum";
import RoleProps, { RoleSalary } from "../types/role-props";
import TeamStateRepeatingRoleProps from "../types/team-state-repeating-role-props";
import abbreviations from "../assets/constants/RoleAbbreviation";

function convertRoleNameToPascal(roleName: string): string {
  return roleName.replace(/(\w)(\w*)/g, (g0, g1, g2) => {
    return g1.toUpperCase() + g2.toLowerCase();
  });
}

function compareSalaryThenName(roleA: RoleProps, roleB: RoleProps): number {
  const salaryComparisonDesc =
    roleB.annualSalary.senior - roleA.annualSalary.senior;
  if (salaryComparisonDesc !== 0) {
    return salaryComparisonDesc;
  }
  const nameComparisonAsc = roleA.name > roleB.name;
  const nameComparisonAsc2 = roleB.name > roleA.name ? -1 : 0;
  return nameComparisonAsc ? 1 : nameComparisonAsc2;
}

function sortRoles(roles: RoleProps[]): RoleProps[] {
  return roles.sort(compareSalaryThenName);
}

function deleteRoles(roles: RoleProps[], roleId: string): RoleProps[] {
  return sortRoles(
    roles.filter((role) => role.isReadOnly || role.id !== roleId)
  );
}

function modifyRole(
  roles: RoleProps[],
  roleId: string,
  field: string,
  value: string | RoleSalary
): RoleProps[] {
  if (typeof value !== "string") {
    return sortRoles(
      roles.map((role) => {
        if (role.id === roleId) {
          if (field === RoleFieldsEnum.ANNUAL_SALARY)
            return {
              ...role,
              annualSalary: value,
            };
        }
        return role;
      })
    );
  }
  return sortRoles(
    roles.map((role) => {
      if (role.id === roleId) {
        return { ...role, [field]: value };
      }
      return role;
    })
  );
}

function createRole(
  roles: RoleProps[],
  name: string,
  abbreviation: string | undefined,
  annualSalary: RoleSalary,
  color: string,
  showNumber8UserIcon: boolean
): RoleProps[] {
  const colorAlreadyExists =
    roles.filter((role) => role.color === color).length > 0;
  if (colorAlreadyExists) {
    return roles;
  }
  const newRole = {
    name,
    abbreviation,
    annualSalary,
    color,
    isReadOnly: false,
    showNumber8UserIcon,
    id: nanoid(),
  };
  const newRoles = roles.concat(newRole);
  return sortRoles(newRoles);
}
/**
 * Create an array of repeating roles to display in the team state members sections,
 * it is intended to return {TeamStateRepeatingRoleProps[]} from a {RoleProps} and an amount.
 * @param role {RoleProps} Original role
 * @param amount {number} Amount of roles a team state has
 * @param color {string} This can change from the original n8 role color, since you can replace another with n8,
 * and the color must remain as the previous role.
 * @returns {TeamStateRepeatingRoleProps[]}
 */
function createTeamMembersDisplayListFromTeamStateRoles(
  role: RoleProps,
  amount: number,
  color: string
): TeamStateRepeatingRoleProps[] {
  const arr: TeamStateRepeatingRoleProps[] = [];
  for (let index = 0; index < amount; index += 1) {
    arr.push({
      ...role,
      color,
      roleId: role.id,
      id: nanoid(),
    });
  }
  return arr;
}

function replaceRoleNameWithAbbreviation(role: RoleProps): RoleProps {
  let newName = role.name;
  if (role.abbreviation) {
    return { ...role, name: role.abbreviation };
  }
  for (let x = 0; x < abbreviations.length; x += 1) {
    newName = newName.replace(
      abbreviations[x].text,
      abbreviations[x].abbreviation
    );
  }
  return { ...role, name: newName };
}

export {
  deleteRoles,
  modifyRole,
  createRole,
  createTeamMembersDisplayListFromTeamStateRoles,
  sortRoles,
  replaceRoleNameWithAbbreviation,
  convertRoleNameToPascal,
};
