import React, { FC } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "antd";
import ContextMenuProps from "../../types/context-menu-props";

const ContextMenu: FC<ContextMenuProps> = ({
  children,
  menu,
  trigger,
  isOpen,
  setMenuOpen,
}) => {
  if (isOpen !== undefined && setMenuOpen) {
    return (
      <Dropdown
        overlay={menu}
        trigger={trigger}
        visible={isOpen}
        onVisibleChange={setMenuOpen}
      >
        {children}
      </Dropdown>
    );
  }
  return (
    <Dropdown overlay={menu} trigger={trigger}>
      {children}
    </Dropdown>
  );
};

ContextMenu.defaultProps = {
  isOpen: undefined,
  setMenuOpen: undefined,
};

ContextMenu.propTypes = {
  children: PropTypes.element.isRequired,
  menu: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  trigger: PropTypes.array.isRequired,
  isOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func,
};
export default ContextMenu;
