import styled from "styled-components";

const StyledCreateSessionModal = styled.div`
  margin-bottom: 20px;
  > .ant-row {
    margin: 10px;
  }
  .ant-upload-drag-container {
    padding: 0 30px;
    width: 100%;
  }
  .ant-upload-text {
    margin-top: 20px;
  }
`;

export default StyledCreateSessionModal;
