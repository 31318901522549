import styled from "styled-components";

const StyledEditTeamForm = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
  > .ant-row > .ant-col > div > .ant-row > .styled-col-flex {
    display: flex;
  }
  > .ant-row > .ant-col > .ant-row > .ant-col > span > input[type="number"] {
    text-align: right;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  > .ant-row > .ant-col > .ant-row > .ant-col > input[type="number"] {
    text-align: right;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  > .ant-row > .ant-col > div > .ant-row > .ant-col > input[type="number"] {
    text-align: right;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .required {
    color: #f10;
  }
`;

const StyledDropDown = styled.div`
  .ant-select > .ant-select-arrow {
    top: 37%;
  }
`;

export { StyledDropDown };

export default StyledEditTeamForm;
