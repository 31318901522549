import { handleStateHistory } from "../helpers/historyTracker";
import {
  createRole,
  deleteRoles,
  modifyRole,
  sortRoles,
} from "../helpers/roles";
import {
  addTeam,
  addScenario,
  createTeam,
  cloneScenario,
  editTeamScenarioField,
  editTeamField,
  getLastCreatedTeam,
  replaceResourceWithNumber8,
  addRoleToTeamScenario,
  replaceRoleWithAnotherRole,
  removeResourceFromScenario,
  removeScenarioFromTeam,
  updateTeamsBasedOnRoles,
  duplicateTeam,
  editTeam,
  sendResourcesToTeam,
  deleteTeam,
  changeTeamOrder,
  propagateState,
} from "../helpers/teams";
import { Action, State } from "../types/context-props";
import initialConfig from "../assets/data/initialConfig.json";
import TeamValuesEnum from "../assets/constants/TeamValuesEnum";
import { RoleSeniority } from "../assets/constants/RoleSeniority";
import TeamScenarioResourceProps from "../types/team-scenariou-resource-props";
import ResourceProp from "../types/resource-prop";

function handleReducerActions(state: State, action: Action): State {
  switch (action.type) {
    case "addRole":
      return {
        ...state,
        roles: createRole(
          state.roles,
          action.value.name,
          action.value.abbreviation,
          action.value.annualSalary,
          action.value.color,
          action.value.showNumber8UserIcon
        ),
      };
    case "deleteRole": {
      const roles = deleteRoles(state.roles, action.value.id);
      const [scenarioResources, resources] = updateTeamsBasedOnRoles(
        roles,
        state.teamScenarioResource,
        state.resources
      );
      return {
        ...state,
        roles,
        resources,
        teamScenarioResource: scenarioResources,
      };
    }
    case "updateRole":
      return {
        ...state,
        roles: modifyRole(
          state.roles,
          action.value.roleId,
          action.value.field,
          action.value.value
        ),
      };
    case "addTeam": {
      const [newTeams, teamScenario, teamScenarioResource] = addTeam(
        state.teams,
        state.teamScenario,
        state.teamScenarioResource,
        state.resources,
        action.value
      );
      const lastTeamAdded = getLastCreatedTeam(newTeams);
      return {
        ...state,
        teams: newTeams,
        teamScenario,
        teamScenarioResource,
        selectedTeam: lastTeamAdded.id,
      };
    }
    case "updateTeamStateField": {
      const teamScenario = editTeamScenarioField(
        state.teamScenario,
        action.value.stateId,
        action.value.field,
        action.value.value
      );
      return {
        ...state,
        teamScenario,
      };
    }
    case "propagateState": {
      const teamScenario = propagateState(
        state.teamScenario,
        state.selectedTeam,
        action.value.field,
        action.value.value
      );
      return {
        ...state,
        teamScenario,
      };
    }
    case "cloneStateOfTeam": {
      const [teamScenario, teamScenarioResource, resources] = cloneScenario(
        state.teamScenario,
        state.teamScenarioResource,
        state.resources,
        action.value.stateId,
        state.selectedTeam,
        action.value.newName
      );
      return {
        ...state,
        teamScenario,
        teamScenarioResource,
        resources,
      };
    }

    case "addStateToTeam": {
      const [teamScenario, teamScenarioResource, resources] = addScenario(
        state.teamScenario,
        state.teamScenarioResource,
        state.resources,
        state.selectedTeam,
        action.value.name,
        action.value.newResources
      );
      return {
        ...state,
        teamScenario,
        resources,
        teamScenarioResource,
      };
    }

    case "selectTeam":
      return {
        ...state,
        selectedTeam: state.justDiplicatedTeam
          ? state.selectedTeam
          : action.value,
        justDiplicatedTeam: false,
      };

    case "updateTeam":
      return {
        ...state,
        teams: editTeamField(
          state.teams,
          action.value.teamId,
          action.value.field,
          action.value.value
        ),
      };

    case "replaceRoleWithNumber8":
      return {
        ...state,
        resources: replaceResourceWithNumber8(
          state.resources,
          state.roles,
          action.value
        ),
      };

    case "addRoleToState": {
      const [resources, teamScenarioResource] = addRoleToTeamScenario(
        state.resources,
        state.teamScenarioResource,
        state.teamScenario,
        state.roles,
        action.value.stateId,
        state.selectedTeam,
        action.value.roleId,
        action.value.seniority,
        action.value.resourceAllocation,
        action.value.replacingRoleId
      );
      return {
        ...state,
        resources,
        teamScenarioResource,
      };
    }
    case "deleteTeam": {
      const [newTeams, lastTeamId, scenarios, scenarioResource] = deleteTeam(
        state.teams,
        state.teamScenario,
        state.teamScenarioResource,
        action.value
      );
      return {
        ...state,
        teams: newTeams,
        teamScenario: scenarios,
        teamScenarioResource: scenarioResource,
        selectedTeam:
          state.selectedTeam === action.value ? lastTeamId : state.selectedTeam,
      };
    }

    case "replaceRoleWithRole":
      return {
        ...state,
        resources: replaceRoleWithAnotherRole(
          state.resources,
          action.value.resourceId,
          action.value.newRoleId
        ),
      };

    case "removeTeamMember": {
      const teamScenarioResource = removeResourceFromScenario(
        state.teamScenarioResource,
        state.teamScenario,
        state.resources,
        action.value.stateId,
        action.value.resourceId,
        state.selectedTeam,
        state.roles
      );
      return {
        ...state,
        teamScenarioResource,
      };
    }

    case "deleteState": {
      const scenarios = removeScenarioFromTeam(
        state.teamScenario,
        action.value.stateId,
        state.selectedTeam
      );
      return {
        ...state,
        teamScenario: scenarios,
      };
    }

    case "setState":
      return action.value;

    case "duplicateTeam": {
      const [
        newTeams,
        teamScenario,
        teamScenarioResource,
        resources,
        selectedTeam,
      ] = duplicateTeam(
        state.teams,
        state.teamScenario,
        state.teamScenarioResource,
        state.resources,
        action.value
      );
      return {
        ...state,
        teams: newTeams,
        teamScenario,
        teamScenarioResource,
        resources,
        selectedTeam:
          selectedTeam === action.value ? state.selectedTeam : selectedTeam,
        justDiplicatedTeam: true,
      };
    }

    case "setClient": {
      if (!state.client) {
        return { ...state, client: { ...action.value } };
      }
      const teamScenarioResource: TeamScenarioResourceProps[] =
        initialConfig.teamScenarioResource.map((scenarioResource) => {
          return {
            ...scenarioResource,
            seniority: scenarioResource.seniority as RoleSeniority,
          };
        });
      const resources: ResourceProp[] = initialConfig.resources.map(
        (resource) => {
          return {
            ...resource,
            seniority: resource.seniority as RoleSeniority,
          };
        }
      );
      return {
        ...state,
        ...initialConfig,
        teamScenarioResource,
        resources,
        client: { ...action.value },
        roles: sortRoles(initialConfig.roles),
        colors: initialConfig.availableColors,
        selectedTeam: TeamValuesEnum.DEFAULT_TEAM_ID,
        teams: initialConfig.teams,
      };
    }

    case "editTeam": {
      const [teams, teamScenario] = editTeam(
        state.teams,
        state.teamScenario,
        action.value.teamId,
        action.value.team
      );
      return {
        ...state,
        teams,
        teamScenario,
      };
    }

    case "setUserInfo":
      return {
        ...state,
        userInfo: {
          ...action.value,
        },
      };

    case "assignResourcesToTeam": {
      const [newScenarioResources, scenarios, resources] = sendResourcesToTeam(
        state.teams,
        state.teamScenario,
        state.teamScenarioResource,
        state.resources,
        action.value.resources,
        action.value.teamId
      );
      return {
        ...state,
        teamScenarioResource: newScenarioResources,
        teamScenario: scenarios,
        resources,
      };
    }

    case "addTeamWithAvailableResourcesAction": {
      const [newTeams, teamScenario, teamScenarioResource, resources] = addTeam(
        state.teams,
        state.teamScenario,
        state.teamScenarioResource,
        state.resources,
        action.value.team,
        action.value.roleAmount
      );
      const lastTeamAdded = getLastCreatedTeam(newTeams);
      return {
        ...state,
        teams: newTeams,
        teamScenario,
        teamScenarioResource,
        resources,
        selectedTeam: lastTeamAdded.id,
      };
    }

    case "createTeamWithAvailableResourcesAction": {
      const [newTeams, teamScenario, teamScenarioResource, resources] =
        createTeam(
          state.teams,
          state.teamScenario,
          state.teamScenarioResource,
          state.resources,
          action.value.team,
          action.value.roleAmount
        );
      const lastTeamAdded = getLastCreatedTeam(newTeams);
      return {
        ...state,
        teams: newTeams,
        teamScenario,
        teamScenarioResource,
        resources,
        selectedTeam: lastTeamAdded.id,
      };
    }

    case "deleteClientInfo":
      return { ...state, client: undefined };

    case "updateClientInfoAction":
      return { ...state, client: action.value };

    case "setShowProTips":
      return { ...state, showProTips: action.value.showProTips };

    case "changeTeamOrder": {
      const newTeams = changeTeamOrder(
        state.teams,
        action.value.currentOrder,
        action.value.newOrder
      );
      return { ...state, teams: newTeams };
    }

    default:
      return state;
  }
}

function reducer(state: State, action: Action): State {
  if (
    action.type !== "undo" &&
    action.type !== "redo" &&
    action.type !== "save"
  ) {
    // This will add every action to the apps history
    const newState = handleReducerActions(state, action);
    const historyTracker = handleStateHistory(
      state.history || [],
      state.future || [],
      "add",
      newState
    );
    localStorage.setItem(
      "cpsp-state",
      JSON.stringify({ ...newState, history: [], future: [] })
    );
    if (+localStorage.tabCount > 0) {
      localStorage.removeItem("cpsp-state");
    } else localStorage.tabCount = 0;

    localStorage.tabCount = +localStorage.tabCount + 1;
    window.onunload = function newTabCheck() {
      localStorage.tabCount = +localStorage.tabCount - 1;
    };
    return {
      ...newState,
      history: historyTracker.history,
      future: historyTracker.future,
      isSaved: false,
    };
  }
  if (action.type === "save") {
    return { ...state, isSaved: true };
  }
  const historyTracker = handleStateHistory(
    state.history || [],
    state.future || [],
    action.type
  );
  return {
    ...historyTracker.peek(),
    history: historyTracker.history,
    future: historyTracker.future,
    isSaved: false,
  };
}

export default reducer;
