import RoleAbbreviationProps from "../../types/role-abbreviations-props";

const abbreviations: RoleAbbreviationProps[] = [
  {
    text: /Architect/i,
    abbreviation: "Arch",
  },
  {
    text: /Security/i,
    abbreviation: "Sec",
  },
  {
    text: /Senior/i,
    abbreviation: "Sr",
  },
  {
    text: /Junior/i,
    abbreviation: "Jr",
  },
  {
    text: /Developer/i,
    abbreviation: "Dev",
  },
  {
    text: /Development Operations/i,
    abbreviation: "DevOps",
  },
  {
    text: /Business Analyst/i,
    abbreviation: "BA",
  },
  {
    text: /Quality Assurance/i,
    abbreviation: "QA",
  },
  {
    text: /Quality Assurance/i,
    abbreviation: "QA",
  },
  {
    text: /User Experience/i,
    abbreviation: "UX",
  },
  {
    text: /Product Owner/i,
    abbreviation: "PO",
  },
  {
    text: /Project Manager/i,
    abbreviation: "PM",
  },
  {
    text: /Scrum Master/i,
    abbreviation: "SM",
  },
];

export default abbreviations;
