import styled from "styled-components";

const StyledAddRoleModal = styled.div`
  display: grid;
  grid-row-gap: 10px;
  > .ant-row {
    line-height: 25px;
    > span {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }
  }
  > .addRoleRow {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    > .availableColors {
      display: flex;
      flex-direction: row;
      justify-content: start;
      padding: 1px;
      > .selectedColor {
        border: solid #f26522 1px;
      }
      > .unselectedColor {
        border: solid #ffffff 1px;
      }
    }
  }
`;

export default StyledAddRoleModal;
